import {useState} from "react";

import { ReactComponent as SendIcon }  from '../../icons/SendIcon.svg'
import { ReactComponent as AttachIcon }  from '../../icons/AttachIcon.svg'
import { ReactComponent as Avatar } from '../../icons/ChatbotIcon.svg'

import { MessageSchema } from "../../data/types";
import { useMemberState } from "../../contexts/membercontext";
import { useCommunity } from "../../contexts/communitycontext";
import { useSendMessage } from "../../hooks/memberhooks";


const capitalizeString = (string: string) => string?.replace(/\w/, c => c?.toUpperCase())


// Messages view for current user
const ChatWindow = () => {
    const [currentText, updateCurrentText] = useState('')
    var {currentCommunity} = useCommunity()
    var communityId = currentCommunity?.id ? currentCommunity.id : ""
    var { currentChatCommunity, messageThread, updateMessageThread } = useMemberState()
    const { sendMessage  } = useSendMessage()

    const handleSubmit = async () => {
        
        if (currentChatCommunity){
            // sendMessage(currentChatCommunity, currentText)
            // if (isLoadingMessage){
                // let messages: MessageSchema[] = [...messageThread, sent]
                let messages: MessageSchema[] = [...messageThread, {content: currentText, type: 'sent', read_status: false, username: currentChatCommunity, community_id: communityId, created: ''}]
                updateMessageThread(messages)
                sendMessage(currentChatCommunity, currentText)
            // }
            updateCurrentText('')
        }
    }

    return (
                <div className="flex flex-col justify-start align-top w-full sm:w-[700px] bg-[#fff] p-2 rounded-md font-open-sans ">
                    <div className="h-[80px] w-[100%]">
                        <div className="flex justify-start">     
                            <Avatar className={`h-8 w-8 m-1 ${(currentChatCommunity === 'chatbot') ? 'text-[#51a0b5]/90' : 'text-[#013847]/90'}`} />
                            <div className="flex flex-col">
                                <p className="text-[12px] text-[#2D3748] font-bold" >{(currentChatCommunity === 'chatbot') ? capitalizeString(currentChatCommunity) : currentChatCommunity}</p>
                                <p className="text-[10px] text-[#C4C4C4] font-normal">online</p>
                            </div>
                            
                        </div>
                    </div>

                
                    <div className="bg-white rounded-sm h-[350px] overflow-y-scroll">
                        {
                            (messageThread) ? (messageThread.map((m: MessageSchema, i: number) => {
                                return (
                                    <div key={i} className={(m?.type === "received") ? "bg-[#555555] text-[#fff] w-[40%] text-[13px] mr-[55%] p-[2%] m-[1%] rounded-lg font-thin" : "bg-[#013847] text-[#fff] w-[40%] text-[13px] ml-[55%] p-[2%] m-[1%] rounded-lg font-thin"}> {m?.content} </div>
                                )
                            })): ''
                        }
                    </div>
                    {/* <Divider /> */}
                    <div className="flex flex-row h-auto items-center w-full">
                        
                            <div className="flex w-10/12">
                                <textarea onChange={e => updateCurrentText(e.target.value)} value={currentText} className="text-[14px] h-[60px] w-full bg-[#D9D9D9] text-gray-700 shadow appearance-none border rounded-lg  py-2 px-3 leading-tight focus:ring-blue-200" placeholder="Enter Message..." />
                            </div>

                            <div className="flex flex-row w-4/12 sm:w-2/12 justify-start">   
                                    <AttachIcon className=" w-[35px] h-[35px] cursor-pointer m-2" />
                                    <SendIcon className=" w-[35px] h-[35px] cursor-pointer m-2 hover:fill-[#5DB6CE]" onClick={handleSubmit} /> 
                            </div>

                    </div>
                </div>
    )
}



export default ChatWindow
