import React from 'react';
import { startOfWeek, endOfWeek, eachDayOfInterval, format } from 'date-fns';

import { BookingSchema } from "../data/types";
import { getBookingStatusColor } from "../utils/helpers";

type WeekViewProps = {
  currentDate: number | Date
  bookings: BookingSchema[]
}

const WeekView: React.FC<WeekViewProps> = ({bookings, currentDate}) => {

  const firstDayOfWeek = startOfWeek(currentDate);
  const lastDayOfWeek = endOfWeek(currentDate);

  const daysInWeek = eachDayOfInterval({ start: firstDayOfWeek, end: lastDayOfWeek });

  return (
    <div>
      <div className="grid grid-cols-8 gap-2">
        <div className="col-span-1">
          {/* 24-hour timeline */}
          {Array.from({ length: 24 }).map((_, index) => (
            <div
              key={index}
              className="text-left pr-2 border-b border-gray-400"
              style={{ paddingTop: '2.0rem' }}
            >
              {format(new Date('2023-01-01T00:00:00-04:00').setHours(index), 'HH:mm')}
            </div>
          ))}
        </div>

        
        {daysInWeek.map((day) => (
          <div key={day.toISOString()} className="border border-gray-400 p-2 relative col-span-1">
            {/* Day labels */}
            <div className="text-gray-500">{format(day, 'E')}</div>
            <div>{format(day, 'dd')}</div>
            <div className="mt-2">
              <ul>
                {bookings.map((booking: BookingSchema, index: number) => {
                  const eventStartDate = new Date(booking.start_time);
                  const eventEndDate = new Date(booking.end_time);
                  if (
                    eventStartDate.getDate() === day.getDate() &&
                    eventStartDate.getMonth() === day.getMonth() &&
                    eventStartDate.getFullYear() === day.getFullYear()
                  ) {
                    const topPercentage =
                      ((eventStartDate.getHours() + 1) * 60 + eventStartDate.getMinutes()) / (24 * 60) * 100;
                    const heightPx =
                      ((eventEndDate.getHours() - eventStartDate.getHours()) * 57) ;
                    return (
                      <li
                        key={index}
                        className={`font-bold text-[11px] text-[#fff]/80 rounded px-2 py-1 mb-1 h-full`}
                        style={{
                          position: 'absolute',
                          left: 0,
                          right: 0,
                          top: `${topPercentage}%`,
                          height: `${heightPx}px`,
                          // color: (booking?.status) ? lightenColor(getBookingStatusColor(booking.status), 100) : lightenColor('#38b2ac', 100),
                          backgroundColor: (booking?.status) ? getBookingStatusColor(booking.status) : '#38b2ac'
                        }}
                      >
                        {`${format(eventStartDate, 'h:mm a')} - ${format(eventEndDate, 'h:mm a')}`}<br /><span className={`font-normal text-[10px] text-[#fff]/60 mb-1`}>{booking.summary}</span>
                      </li>
                    );
                  }
                  return null;
                })}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WeekView;
