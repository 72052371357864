import { useState } from "react";
import CheckBox from '@mui/icons-material/CheckBox';
// src/components/PaymentForm.tsx
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";

import { useGetPaymentMethods, useAddPaySetupIntent } from '../../hooks/memberhooks'

// import config from '../../config';

// const stripePublishableKey = config?.stripePublishableKey

type PaymentFormProps = {
  isAddPayMethod?: boolean
}

const PaymentForm: React.FC<PaymentFormProps> = ({isAddPayMethod}) => {
  const { reloadPaymentMethods } = useGetPaymentMethods()
  const { addSetupIntent } = useAddPaySetupIntent()
  // const { addPaymentIntent } = useAddPaymentIntent()
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<string | null>(null);

//   const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
//     event.preventDefault();
//     if (!stripe || !elements) return;

//     const { error, paymentMethod } = await stripe.createPaymentMethod({
//       type: "card",
//       card: elements.getElement(CardElement)!,
//     });

//     if (error && error?.message) {
//       setError(error.message);
//     } else {
//       // Send paymentMethod.id to backend
//       console.log("paymentMethod", paymentMethod);
//     }
//   };


const handleSubmitPay = async () => {

  if (!stripe || !elements) {
    // Stripe.js hasn't yet loaded.
    // Make sure to disable form submission until Stripe.js has loaded.
    return;
  }
  // setLoading(true);
  // Trigger form validation and wallet collection
  const {error: submitError} = await elements.submit();
  if (submitError?.message) {
    setError(submitError.message);
    return;
  }
  
  const clientSecret = await addSetupIntent()
  console.log("clientSecret: ", clientSecret)

  if (clientSecret){
      // Use the clientSecret and Elements instance to confirm the setup
      const {error: confirmError} = await stripe.confirmSetup({
        elements,
        clientSecret,
        confirmParams: {
          return_url: 'http://localhost:3008/settings/payment',
        },
        // Uncomment below if you only want redirect for redirect-based payments
        // redirect: "if_required",
      });

      await reloadPaymentMethods()
      // console.log("paymentMethods: ", paymentMethods)

      if (confirmError?.message) {
        setError(confirmError.message);
      }
  }
};



const handleAddPaymentMethod = async () => {
  
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
  
    // setLoading(true);
  
    // Trigger form validation and wallet collection
    const {error: submitError} = await elements.submit();
    if (submitError?.message) {
      setError(submitError.message);
      return;
    }
    
    const clientSecret = addSetupIntent()

    console.log("clientSecret: ", clientSecret)
  
    // Use the clientSecret and Elements instance to confirm the setup
    const {error: confirmError} = await stripe.confirmSetup({
      elements,
      clientSecret,
      confirmParams: {
        return_url: 'http://localhost:3008/settings/payment',
      },
      // Uncomment below if you only want redirect for redirect-based payments
      redirect: "if_required",
    });
  
    if (confirmError?.message) {
      setError(confirmError.message);
    }
  };

//   console.log("")

  // return (
  //   <form onSubmit={handleSubmit} className="w-full">
  //     {/* <label className="w-full">
  //       Card details
        
  //     </label> */}
  //     <PaymentElement />
  //     <button type="submit">Add</button>
  //     {error && <div>{error}</div>}
  //   </form>
  // );

  return (
    <div className='flex flex-col w-full'>
        <PaymentElement />
        {isAddPayMethod ? <span className='w-full m-1 my-1 text-sm'><CheckBox /> Set as preferred</span> : <></>}
        {!isAddPayMethod ? <span className='w-full m-1 my-1 text-sm'><CheckBox /> Save Card</span> : <></>}
        {
          (isAddPayMethod) ? (
            <button onClick={handleAddPaymentMethod} className={`buttonsPrimary w-full border-0 mt-2 m-1 p-2 "order-1 !bg-primary-200 !text-back-400 text-sm`} disabled={!stripe}>Add</button>
          ) : (
            // <></>
            <button onClick={handleSubmitPay} className={`buttonsPrimary border-0 mt-2 m-1 p-2 "order-1 !bg-primary-200 !text-back-400 text-sm`} disabled={!stripe}>Add Card</button>
          )
        }
        {error && <div className="bg-red-100/90 text-red-800 text-xs font-open-sans rounded-sm p-1 m-1">{error}</div>}
        
    </div>
  )
};

export default PaymentForm;
