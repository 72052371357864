// import { Dialog } from "@mui/material";
import { MembershipSchema } from "../data/types";
import {  AddMemberForm, AddReservationForm } from "./forms";
// import KitchenDetails from "../molecules/KitchenDetails";
import CredentialDetails from "../molecules/views/CredentialDetails"

// import { deleteKitchen } from "../api/crud";
// import { useAuthState } from "../contexts/authcontext";
// import { useKitchen } from "../contexts/kitchencontext";
import { CredentialSchema } from "../data/types";
// import { usePreferences } from "../contexts/preferencecontext";


type ModalProps = {
  isOpen: boolean;
};




type CredentialDetailsModalProps = ModalProps & {
  closeAppModal: () => void;
  credential: CredentialSchema
  handleUpdateCredential: (credential: CredentialSchema) => void
}

const CredentialDetailsModal: React.FC<CredentialDetailsModalProps> = (props) => {
  var { isOpen, credential, handleUpdateCredential, closeAppModal } = props

  return (
    <div
      className={`inset-0 ${isOpen ? "block" : "hidden"}`}
    >
      <div className="fixed inset-0 bg-black opacity-60" onClick={() => closeAppModal()}></div>

      <dialog
        data-testid="view-kitchen-modal"
        className="z-50 top-20 bg-[#EEE] p-4 rounded-md shadow-md w-auto"
        open={isOpen}
        onClose={() => closeAppModal()}
      >
        <CredentialDetails credential={credential} handleUpdateCredential={handleUpdateCredential} />
      </dialog>
    </div>
  );
}




type AddReservationModalProps = ModalProps & {
  closeAppModal: () => void;
  membership: MembershipSchema
};

const AddReservationModal: React.FC<AddReservationModalProps> = (props) => {
  let { membership, isOpen, closeAppModal } = props
  // var { addKitchenSpace } =  useKitchen()
  


  return (
    <div
      className={`inset-0 ${isOpen ? "block" : "hidden"}`}
    >
      <div className="fixed inset-0 bg-black opacity-60" onClick={() => closeAppModal()}></div>

      <dialog
        data-testid="add-kitchen-modal"
        className="z-50 top-20 bg-[#EEE] p-4 rounded-md shadow-md w-fit"
        open={isOpen}
        onClose={() => closeAppModal()}
      >
        <AddReservationForm membership={membership} handleClose={closeAppModal} />
      </dialog>
    </div>
  );
};




type AddMemberFormModalProps = ModalProps & {
  communityId: string
  closeAppModal: () => void;
};

const AddMemberFormModal: React.FC<AddMemberFormModalProps> = (props) => {
  let { communityId, isOpen, closeAppModal } = props
  // var { addKitchenSpace } =  useKitchen()
  

  const handleAddMember = () => {
    // if(addKitchenSpace !== undefined){addKitchenSpace(kitchen)}
    closeAppModal();
  }

  return (
    <div
      className={` inset-0 ${isOpen ? "block" : "hidden"}`}
    >
      <div className="fixed inset-0 bg-black opacity-60" onClick={() => closeAppModal()}></div>

      <dialog
        data-testid="add-kitchen-modal"
        className="z-50 top-20 bg-gray-200 p-4 rounded-md shadow-md md:w-[1158px}"
        open={isOpen}
        onClose={() => closeAppModal()}
      >
        <AddMemberForm communityId={communityId} handleClose={closeAppModal} handleAddMember={handleAddMember} />
      </dialog>
    </div>
  );
};

type NewMessageModalProps = ModalProps & {
  closeAppModal: () => void;
};

const NewMessageModal: React.FC<NewMessageModalProps> = (props) => {
  let { isOpen, closeAppModal } = props
  


  return (
    <div
      className={` inset-0 ${isOpen ? "block" : "hidden"}`}
    >
      <div className="fixed inset-0 bg-black opacity-60 z-50" onClick={() => closeAppModal()}></div>

      <dialog
        className="z-50  bg-slate-50 p-4 rounded-md shadow-md md:w-[1158px}"
        open={isOpen}
        onClose={() => closeAppModal()}
      >
        {/* <NewMessageForm handleClose={closeAppModal} /> */}
      </dialog>
    </div>
  );
};



export { NewMessageModal, CredentialDetailsModal, AddReservationModal, AddMemberFormModal };
