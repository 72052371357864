import React, { useState } from "react";
import {
  startOfMonth,
  startOfWeek,
  endOfWeek,
  endOfMonth,
  eachDayOfInterval,
  format,
} from "date-fns";
import moment, { Moment } from "moment";
import { BookingSchema, KitchenSpaceSchema } from "../data/types";
// import { darkenColor, getBookingStatusColor } from "../utils/helpers";

type MonthViewProps = {
  currentDate: Date;
  bookings: BookingSchema[];
  nHours: number;
  updateStartTime: (startTime: Moment) => void;
  updateEndTime: (endTime: Moment) => void;
  handleConfirmBooking: () => void;
  currentKitchen: KitchenSpaceSchema;
};
interface TimeBlock {
  start: Moment;
  end: Moment;
}

const ReservationCalendar: React.FC<MonthViewProps> = ({
  bookings,
  currentDate,
  nHours,
  updateEndTime,
  updateStartTime,
  handleConfirmBooking,
  currentKitchen,
}) => {
  const firstDayOfMonth = startOfWeek(startOfMonth(currentDate));
  const lastDayOfMonth = endOfWeek(endOfMonth(currentDate));
  // const [bookingStart, setBookingStart] = useState(new Date());
  // var bookingStart = new Date()
  const [showTimes, setShowTimes] = useState(false);
  const [selectedDay, setSelectedDay] = useState(new Date());
  const [availableTimeBlocks, setBlocks] = useState<TimeBlock[]>([]);
  const [selectedBlock, setSelectedBlock] = useState<TimeBlock>();

  // const availableTimesRef = useRef(null);
  // const scrollToAvailableTimes = () => {
  //   // availableTimesRef.current.scrollTo({ behavior: 'smooth' })
  //   const section = document.querySelector( '#available-times' );
  //   if (section){section.scrollIntoView( { behavior: 'smooth', block: 'start' } );}
  // };

  const daysInMonth = eachDayOfInterval({
    start: firstDayOfMonth,
    end: lastDayOfMonth,
  });
  const findBookingsForDay = (day: Moment) => {
    const bookingsForDay = [];

    if (bookings && bookings.length > 0) {
      for (const booking of bookings) {
        const startTime = moment(booking.start_time);
        const endTime = moment(booking.end_time);

        // Check if the booking spans the specified day
        if (
          startTime.isSameOrBefore(day, "day") &&
          endTime.isSameOrAfter(day, "day")
        ) {
          // If yes, add it to the result array
          bookingsForDay.push(booking);
        }
      }
    }

    return bookingsForDay;
  };

  const areDatesInSameMonth = (date1: Date, date2: Date) => {
    // Extract year and month components from both dates
    const year1 = date1.getFullYear();
    const month1 = date1.getMonth();

    const year2 = date2.getFullYear();
    const month2 = date2.getMonth();

    // Compare the year and month components
    return year1 === year2 && month1 === month2;
  };

  const areBookingsLessThan12Hours = (day: Date) => {
    // const startOfDay = moment(day).startOf("day");
    // const endOfDay = moment(day).endOf("day");
    let totalTime = 0;
    const bookingsInDay = findBookingsForDay(moment(day));
    for (const booking of bookingsInDay) {
      totalTime += moment(booking.end_time).diff(booking.start_time);
    }
    const test = totalTime < 12 * 60 * 60 * 1000;

    return test;
  };

  const generateWorkingHours = (
    startTime: Moment,
    endTime: Moment,
    hours: number,
    day: Moment,
    bBuffer: number = 0,
    aBuffer: number = 0
  ) => {
    const availableBlocks: TimeBlock[] = [];
    const bookingsForDay = findBookingsForDay(day);
    // Clone the start time to avoid modifying the original moment object
    let currentTime = startTime.clone();

    // Loop until the current time is less than the end time
    while (currentTime.isBefore(endTime)) {
      // Calculate the end time for the current block
      const blockEndTime = currentTime.clone().add(hours, "hours");
      if (blockEndTime.isAfter(endTime)) {
        break;
      }
      let overlapFound = false;

      for (const booking of bookingsForDay) {
        const bookingStartTime = moment(booking.start_time);
        const bookingEndTime = moment(booking.end_time);

        // If the block overlaps with the booking, set the flag and break
        if (
          currentTime.isSameOrBefore(bookingEndTime) &&
          blockEndTime.isSameOrAfter(bookingStartTime)
        ) {
          overlapFound = true;
          break;
        }
      }
      if (!overlapFound) {
        availableBlocks.push({
          start: currentTime.clone(),
          end: blockEndTime.clone(),
        });
      }
      // Move the current time to the next block, considering buffer time
      currentTime.add(0.5, "hours");
    }

    setBlocks(availableBlocks);
  };

  const handleCalendarClick = (day: Date) => {
    if (
      areDatesInSameMonth(day, currentDate) &&
      areBookingsLessThan12Hours(day) &&
      nHours !== 0
    ) {
      const dayOfWeek = moment(day).format("ddd").toLowerCase();
      var kitchenOptions = currentKitchen.kitchen_options;
      var weekDayHours = kitchenOptions[dayOfWeek];
      var openTimeHour = weekDayHours.open_time.split(":")[0];
      var closeTimeHour = weekDayHours.close_time.split(":")[0];
      console.log(openTimeHour);
      console.log(closeTimeHour);

      if (weekDayHours.close_time.includes("PM")) {
        closeTimeHour = parseInt(closeTimeHour) + 12;
      }
      if (weekDayHours.open_time.includes("PM")) {
        openTimeHour = parseInt(openTimeHour) + 12;
      }
      const openTimeMinutes = weekDayHours.open_time.split(":")[1];
      const closeTimeMinutes = weekDayHours.close_time.split(":")[1];
      console.log(
        moment(selectedDay)
          .clone()
          .set("hour", closeTimeHour)
          .set("minute", closeTimeMinutes)
      );
      console.log(
        moment(selectedDay)
          .clone()
          .set("hour", openTimeHour)
          .set("minute", openTimeMinutes)
      );
      console.log();
      setShowTimes(true);
      setSelectedDay(day);
      generateWorkingHours(
        moment(day)
          .clone()
          .set("hour", openTimeHour)
          .set("minute", openTimeMinutes),
        moment(day)
          .clone()
          .set("hour", closeTimeHour)
          .set("minute", closeTimeMinutes),
        nHours,
        moment(day)
      );
      // areBookingsLessThan12Hours(day)
      // console.log(availableTimeBlocks);
    }
  };
  const handleTimeClick = (block: TimeBlock) => {
    setSelectedBlock(block);
    const startTime = moment(selectedDay)
      .clone()
      .hours(block.start.hours())
      .minutes(block.start.minutes());
    const endTime = moment(selectedDay)
      .clone()
      .hours(block.end.hours())
      .minutes(block.end.minutes());
    // console.log(endTime.format("HH:mm"));
    // console.log(startTime.format("HH:mm"));
    updateStartTime(startTime);
    updateEndTime(endTime);
    // console.log(moment(selectedDay));
    // console.log(block);
    // console.log(startTime);
    // console.log(endTime);
  };
  const dayAvailability = (day: Date) => {
    const startOfDay = moment(day).startOf("day");
    // const endOfDay = moment(day).endOf("day");
    // const startTime = startOfDay.toDate();
    // const endTime = endOfDay.toDate();
    const dayOfWeek = startOfDay.format("ddd").toLowerCase();

    const kitchenOptions = currentKitchen.kitchen_options;
    const dayAvailability = Object.keys(kitchenOptions).includes(dayOfWeek);
    if (dayAvailability) {
      return dayAvailability;
    }
  };

  return (
    <div className="flex flex-col w-full">
      <div className="grid grid-cols-7 gap-2 grid-flow-dense">
        {daysInMonth.slice(0, 7)?.map((day) => (
          <div
            key={day.toISOString()}
            className=" text-slate-400  p-2 relative col-span-1 text-left md:px-5 "
          >
            <div>{format(day, "E")}</div>
          </div>
        ))}

        {daysInMonth?.map((day) => {
          return (
            <div key={day.toISOString()} className={`p-2 relative col-span-1 `}>
              <div
                className={`${
                  !areBookingsLessThan12Hours(day) ||
                  moment().isSameOrAfter(moment(day)) ||
                  !dayAvailability(day)
                    ? " !bg-transparent hover:cursor-default"
                    : ""
                } ${
                  moment(day).isSame(moment(selectedDay))
                    ? "bg-primary-400 w-12 h-8 p-6  rounded-full hover:cursor-pointer"
                    : "bg-white w-12 h-8 p-6  rounded-full hover:cursor-pointer "
                }
                ${
                  areDatesInSameMonth(day, currentDate)
                    ? ""
                    : "!text-transparent !bg-transparent"
                }
                justify-center  md:m-2 text-center  md:w-1/4 rounded-full md:pt-1 `}
                onClick={() =>  !areBookingsLessThan12Hours(day) || (moment().isSameOrAfter(moment(day)))|| !dayAvailability(day) ?  null: handleCalendarClick(day)}
              >
                {format(day, "dd")}
              </div>
              <div className="mt-2">
                <ul>
                  {bookings &&
                    bookings?.map((booking: any, index: number) => {
                      const eventStartDate = new Date(booking.start_time);
                      // const eventEndDate = new Date(booking.end_time);
                      if (
                        areDatesInSameMonth(day, eventStartDate)
                        // eventStartDate.getDate() === day.getDate() &&
                        // eventStartDate.getMonth() === day.getMonth() &&
                        // eventStartDate.getFullYear() === day.getFullYear()
                      ) {
                        // const topPercentage =
                        //   (eventStartDate.getHours() * 60 + eventStartDate.getMinutes()) / (24 * 60) * 100;
                        // const heightPercentage =
                        //   ((eventEndDate.getHours() - eventStartDate.getHours()) / (24 * 60 * 60 * 1000)) * 100;
                        // return (
                        //   <li
                        //     key={index}
                        //     className="text-[10px] hidden  px-2 py-1 mb-1 w-[50px] hover:cursor-pointer self-center"
                        //     style={{
                        //       position: 'absolute',
                        //       left: 0,
                        //       right: 0,
                        //       top: 3,
                        //       borderRadius: "50px",
                        //       height: `50px`,
                        //       color:  "rgba(28, 89, 89,.2)",
                        //       backgroundColor:  "rgba(28, 89, 89,.2)"
                        //     }}
                        //   >
                        //   </li>
                        // );
                      }
                      return null;
                    })}
                </ul>
              </div>
            </div>
          );
        })}
      </div>

      {showTimes ? (
        <div
          className={`grid sm:grid-cols-2 grid-cols-1 space-y-1 space-x-2 grid-flow-row-dense items-center `}
        >
          {availableTimeBlocks?.map((block, index) => (
            <div
              key={index}
              className={`${
                block === selectedBlock
                  ? "bg-primary-400 text-primary-200/70"
                  : "text-primary-400/60"
              }  border-primary-400 border-2 p-2 rounded-lg hover:cursor-pointer hover:bg-primary-400/60 text-xs`}
              onClick={() => handleTimeClick(block)}
            >
              {block.start.format("LT")} - {block.end.format("LT")}
              {block === selectedBlock ? (
                <>
                  <button
                    className={`buttonsPrimary p-2 ml-3 sm:ml-auto m-1 text-primary-200 text-xs h-max`}
                    onClick={() => handleConfirmBooking()}
                  >
                    Continue
                  </button>
                </>
              ) : (
                <></>
              )}
            </div>
          ))}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ReservationCalendar;
