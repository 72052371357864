import { useState, useEffect } from "react";

// import { useMemberState } from '../../contexts/membercontext'
import {
  useLoadBookings,
  useAddBooking,
  useLoadMemberships,
} from "../../hooks/memberhooks";
import {
  useLoadCurrentCommunity,
  useLoadCommunities,
} from "../../hooks/communityhooks";
import { useAuthState } from "../../contexts/authcontext";
import { useMemberState } from "../../contexts/membercontext";

// import { useSearchParams } from "react-router-dom";
// import ArrowDropDown from "@mui/icons-material/ArrowDropDown";

import { KitchenSpaceSchema, MembershipSchema } from "../../data/types";

import BookingsGrid from "../../molecules/views/BookingsGrid";
import NewSearch from "../../components/NewSearch";
import LoadingScreen from "../../pages/shared/LoadingScreen";
import ToggleSwitch from "../../components/ToggleSwitch";
import Calendar from "../../molecules/views/Calendar";
import StartBooking from "../../components/StartBooking";
import BookingConfirmation from "../../molecules/views/BookingConfirmation";
// import { getCommunityByID } from "../../api/crud";

const Bookings = () => {
  const [searchTerm, changeSearchTerm] = useState<{
    search: string;
    filter: string;
  }>({ search: "", filter: "" });
  const [calendarView, toggleCalendarView] = useState(false);
  const [startBooking, toggleStartBooking] = useState(false);
  const [addBooking, toggleAddBooking] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  // const [communityLookup, setCommunityLookup] = useState<string[]>();
  const [selectedCommunity, setSelectedCommunity] = useState<any>();
  var { bookings, isLoadingBookings } = useLoadBookings();
  var { currentBooking, updateCurrentBooking } = useMemberState();
  const [updatePlan, toggleUpdatePlan] = useState(false)

  var { isLoadingBooking } = useAddBooking();

  bookings = bookings && Array.isArray(bookings) ? bookings : [];

  const findObjectByAttribute = (
    objects: MembershipSchema[],
    attribute: keyof MembershipSchema,
    value: any
  ) => {
    for (const obj of objects) {
      if (obj[attribute] === value) {
        return obj;
      }
    }
    return {};
  };

  const handleMemberChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    console.log("value", value);
    // setMemberUsername(value);
    if (value !== undefined) {
      // console.log("value", JSON.parse(value))
      setSelectedCommunity(value);
      console.log("currentCommunity", currentCommunity);
      toggleStartBooking(true);
    } else {
      // setMember({});
    }
  };

  const { allCommunities } = useLoadCommunities();
  const { memberships } = useLoadMemberships();
  console.log("memberships", memberships);
  const filteredCommunities =
    allCommunities?.filter((community) =>
      memberships?.some((membership:any) =>
        membership.community_id === community.id
      )
    ) || [];

  var { currentCommunity } = useLoadCurrentCommunity(
    selectedCommunity
  );
  var { authUser } = useAuthState();
  var kitchenSpace = currentCommunity?.spaces
    ? currentCommunity.spaces[0]
    : ({} as KitchenSpaceSchema);
  let allMemberships = currentCommunity?.memberships;
  let username = authUser?.username;
  var isMember = allMemberships
    ? findObjectByAttribute(allMemberships, "username", username)
    : {};

  useEffect(() => {
    document.title = "HomeCook | Reservations";
    // if(getMemberBookings !== undefined){getMemberBookings()}
    // eslint-disable-next-line
    if (allCommunities !== undefined && memberships !== undefined) {
      const lookup: string[] = [];
      for (const member of memberships) {
        lookup.push(member.community_id ? member.community_id : "");
      }
      // setCommunityLookup(lookup);
    }
  }, [allCommunities, memberships, selectedCommunity]);

  const closeAppModal = () => {
    toggleStartBooking(false);
    setIsSubmitting(false);
    toggleAddBooking(false)
    updateCurrentBooking(null);
  };

  if (!bookings || isLoadingBookings || isLoadingBooking) {
    return <LoadingScreen height="h-auto" bgColor="inherit" />;
  } else if (isSubmitting && currentBooking) {
    return (
      <BookingConfirmation
        // currentKitchen={null}
        currentBooking={currentBooking}
        member={isMember}
        // beforeBuffer={beforeBuffer}
        // afterBuffer={afterBuffer}
        // setStep={setStep}
        equipment={[]}
        isOpen={isSubmitting}
        handleEdit={() => setIsSubmitting(false)}
        handleClose={closeAppModal}
        toggleUpdatePlan={toggleUpdatePlan}
      />
    );
  } else {
    return (
      <div>
        {startBooking ? (
          <div className="flex flex-row !justify-center mx-auto w-full h-max bg-primary-200  p-1 my-2 rounded-md sm:w-[600px]">
            <StartBooking
              kitchenSpace={kitchenSpace}
              isMember={isMember}
              setIsSubmitting={setIsSubmitting}
              handleClose={closeAppModal}
            />
          </div>
        ) : (
          <div className="flex flex-col items-center h-[900px] sm:h-screen justify-middle p-2 bg-[#D9D9D9] dark:bg-[#013847] dark:text-[#D9D9D9]/80 text-[#013847]/80 overflow-y-scroll">
            <div className="flex flex-row">
              <NewSearch
                searchType="reservations"
                searchTerm={searchTerm}
                changeSearchTerm={changeSearchTerm}
              />
              <button
                className="bg-[#013847] text-[#D9D9D9] rounded-xl h-[30px] w-full place-self-center pb-2 flex flex-row gap-5"
                onClick={() => toggleAddBooking(true)}
              >
                Add Reservation
              </button>
              {addBooking ? (
                <div className="flex flex-row w-full m-1 justify-between p-1">
                  <select
                    className="bg-[#013847] text-[#D9D9D9] text-sm rounded-xl h-[35px] w-auto place-self-center pb-2 flex flex-row gap-2"
                    onChange={handleMemberChange}
                  >
                    <option value="">Select Membership</option>
                    {filteredCommunities
                      ? filteredCommunities.map((c: any, i: any) => (
                          <option key={i} value={c.id}>
                            {c.name}
                          </option>
                        ))
                      : null}
                  </select>
                </div>
              ) : null}
            </div>
            <div className="flex flex-row justify-end">
              <ToggleSwitch
                onChange={() => toggleCalendarView(!calendarView)}
                isChecked={calendarView}
              />
            </div>
            <div className="my-2">
              {bookings && bookings?.length ? bookings.length : 0}{" "}
              {bookings && bookings.length === 1
                ? "Reservation"
                : "Reservations"}
            </div>

            {calendarView ? (
              <div className="w-full p-2">
                <Calendar
                  bookings={bookings}
                  toggleCalendarView={toggleCalendarView}
                />
              </div>
            ) : (
              <BookingsGrid bookings={bookings} />
            )}
          </div>
        )}
      </div>
    );
  }
};

export default Bookings;
