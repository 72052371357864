import { useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import { ReactComponent as LocationIcon } from "../../icons/locationIcon.svg";
// import { ReactComponent as VisaCardIcon } from '../../icons/visaCardIcon.svg'
// // import { ReactComponent as DiscoverCardIcon } from '../../icons/discoverCardIcon.svg'
// import { ReactComponent as MasterCardIcon } from '../../icons/masterCardIcon.svg'
// import { ReactComponent as AmexCardIcon } from '../../icons/amexCardIcon.svg'
// // import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

// import { useMember } from "../contexts/membercontext";
// import { useAuth } from "../contexts/authcontext";
import NotFound from "../../pages/shared/notfound";
import LoadingScreen from "../../pages/shared/LoadingScreen"
import StripePaymentCheckOut from "./PaymentCheckOut"

import { getBookingStatusColor, styleThousandCommas, maxHours, getDayName, getDayOfMonthWithSuffix, addDaysToToday, calculateDateDifference
  // calculateDateDifference,
  //  getCardIcon
   } from "../../utils/helpers";
import { BookingSchema, KitchenSpaceSchema, MembershipSchema } from "../../data/types";
// import { useCommunity } from "../../contexts/communitycontext";
import { useGetPaymentMethods, useAddBooking } from '../../hooks/memberhooks'
import { useMemberState } from '../../contexts/membercontext'

type BookingConfirmationProps = {
  currentKitchen?: KitchenSpaceSchema
  currentBooking: BookingSchema,
  member: MembershipSchema | undefined;
  beforeBuffer?: string;
  afterBuffer?: string;
  setStep?: (step:number) => void;
  equipment: string[]
  isOpen: boolean | undefined
  handleClose: () => void
  handleEdit: () => void
  toggleUpdatePlan: React.Dispatch<React.SetStateAction<boolean>>
};
const BookingConfirmation: React.FC<BookingConfirmationProps> = ({ currentKitchen, currentBooking,member,beforeBuffer,afterBuffer, isOpen, equipment, handleEdit, handleClose, toggleUpdatePlan }) => {

  const maxBookingAdvanceDays = 90
  const navigate = useNavigate()
  const { paymentMethods } = useGetPaymentMethods()
  var {isLoadingBooking, isAddBookingError, isAddBookingSuccess, addNewBooking } = useAddBooking()

  const { updateCurrentBooking } = useMemberState()

  const [isRepeat, setIsRepeat] = useState<boolean>(false);
  const [bookingRecurrence, setBookingRecurrence] = useState<'daily' | 'weekly' | 'monthly'>('daily');
  const [endRecurrence, setEndRecurrence] = useState<string>(moment(currentBooking.end_time).format('YYYY-MM-DD'));

  const [bookingNotes, updateBookingNotes] = useState<string>("")
  const [selectedPayMethod, updateSelectedPayMethod] = useState<string>(paymentMethods && paymentMethods.length > 0 && paymentMethods.length[0]?.id ? paymentMethods.length[0]?.id : "")
  const [viewPayMethods, toggleViewPayMethods] = useState<boolean>(paymentMethods && paymentMethods.length > 0 ? true : false)
  // const [viewPayMethods, toggleViewPayMethods] = useState<boolean>(['hourly', 'daily'].includes(member?.plan_recurrence ? member?.plan_recurrence : "")  ? false : true)
  // var { currentKitchen } = useCommunity()



  //   const loadCurrentBooking = async () => {
  //     var communityId =
  //       authUser && "primary_community" in authUser && authUser.primary_community
  //         ? authUser.primary_community
  //         : "";
  //     if (getBookingByID !== undefined && communityId && bookingId) {
  //         getBookingByID(communityId, bookingId);
  //     }
  //   };

  //   const approveBooking = () => {
  //     if (bookingId && currentBooking && updateBooking !== undefined ){
  //       let bookingUpdate: any = {status: "approved"}
  //       // delete bookingUpdate.start_time
  //       updateBooking(bookingId, bookingUpdate)
  //     }
  //   }

  //   const rejectBooking = () => {
  //     if (bookingId && currentBooking && updateBooking !== undefined ){
  //       let bookingUpdate: any = {status: "canceled"}
  //       updateBooking(bookingId, bookingUpdate)
  //     }
  //   }

  function getFrequencyOption(dateString: string, booking_recurrence: string): string {
    switch(booking_recurrence){
      case 'daily':
        return 'Daily';
      case 'weekly':
        return `Every ${getDayName(dateString)}`;
      case 'monthly':
        return `${getDayOfMonthWithSuffix(dateString)} Of Each Month`
      default:
        return ''
    }
  }

  const capitalizeWords = (str: string) => {
    if (str) {
      // Split the string into an array of words
      const words = str.split(" ");
      // Capitalize the first letter of each word
      const capitalizedWords = words.map(
        (word) => word.charAt(0).toUpperCase() + word.slice(1)
      );
      // Join the capitalized words back into a string
      const capitalizedString = capitalizedWords.join(" ");
      return capitalizedString;
    }
  };

  //   useEffect(() => {
  //     loadCurrentBooking();
  //     document.title = `HomeCook | Reservation Details`;
  //   }, []); // eslint-disable-line react-hooks/exhaustive-deps

  var duration = currentBooking
    ? moment.duration(
        moment(currentBooking?.end_time)?.diff(
          moment(currentBooking?.start_time)
        )
      )
    : null;
  var hoursDiff = duration ? duration.asHours() : 0;


  // var hourlyCost =
  //   currentBooking?.plan_rate && currentBooking?.plan_recurrence === "hourly"
  //     ? currentBooking.plan_rate * hoursDiff
  //     : currentBooking?.plan_rate;
  // hourlyCost = hourlyCost ? hourlyCost : 0;

  // console.log("member?.plan_rate", member?.plan_rate)
  // console.log("member?.plan_recurrence", member?.plan_recurrence)
  var hourlyCost = (member?.plan_rate && member?.plan_recurrence  === 'hourly') ? member.plan_rate * hoursDiff : member?.plan_rate;
  // console.log("hoursDiff", hoursDiff)
  // console.log("member.plan_rate", member?.plan_rate)
  // console.log("hourlyCost", hourlyCost)

  // Add hours available per (daily), weekly, monthly rates (community plans)
  // divide max hours by kitchen capacity
  // update member balance based on hours available
  // subtract booking hours from member balance

  // let hoursBalance = (member?.member_balance && parseFloat(member?.member_balance) > hoursDiff) ? parseFloat(member.member_balance) - hoursDiff : 0
  let hoursBalance = (member?.plan_recurrence && member?.member_balance && currentKitchen?.capacity) ? maxHours(parseInt(currentKitchen.capacity), member.plan_recurrence, member?.member_balance) - hoursDiff : 0
  
  hourlyCost = (hourlyCost && member?.plan_recurrence  === 'hourly') ? hourlyCost : 0
  // console.log("hourlyCost 2", hourlyCost)

  const cleaningCost = 5
  const serviceCost = 3
  const equipmentCost = 0
  const totalCost = hourlyCost + equipmentCost + cleaningCost + serviceCost


  const handleSubmitBooking = () => {
    currentBooking.notes = bookingNotes
    currentBooking.total_hours = hoursDiff
    currentBooking.payment_method = selectedPayMethod
    currentBooking.fees = [{name: 'services', rate: serviceCost}, {name: 'cleaning', rate: cleaningCost}, {name: 'tax', rate: 0}]

    if (isRepeat){
      currentBooking.booking_recurrence = bookingRecurrence
      currentBooking.end_recurrence = endRecurrence
    }

    if (!totalCost || (totalCost && currentBooking?.payment_method)){
      addNewBooking(currentBooking)
      handleClose()
      updateCurrentBooking(null)
      navigate("/bookings")
    }
  }

  if (isLoadingBooking || isAddBookingError){
    return <LoadingScreen height='h-auto' bgColor='inherit' />
  } else {
    if (handleClose && (isOpen && isAddBookingSuccess)){handleClose()}
    return currentBooking ? (
      <div className="flex w-full p-10  overflow-y-scroll bg-[#D9D9D9] font-open-sans">
        <div className="flex flex-col w-full relative items-start p-4">
         {/* <div className="flex flex-row gap-2">
           <div className="font-raleway text-[25px] text-[#000]  ">Details</div>
           
         </div> */}
         
          {/* TODO: BOOKING REQUEST TITLE */}
          <div className="flex flex-col sm:flex-row sm:grid sm:grid-cols-4 w-full sm:rid-flow-row-dense">
            <div className="col-span-2 w-full">
              
            <div className="flex flex-col mt-4">
                <div className="flex flex-row gap-2">
                  <p className="font-raleway text-lg text-[#000]">
                    Reservation Details
                  </p>
                  <div className="font-raleway text-[25px] text-gray-400 text-xs underline my-auto hover:cursor-pointer" onClick={() => handleEdit()}>edit</div>
                </div>
                <p className="font-open-sans font-normal text-gray-500 text-sm">
                  {member?.first_name} {member?.last_name} ({member?.username})
                  {/* &nbsp; - &nbsp; 
                  <span className="font-open-sans font-normal text-[11px] italic">{member?.plan_name} Plan</span> */}
                  
                </p>
                {/* <p className="font-open-sans font-normal text-primary-200 text-[11px] italic">
                  
                </p> */}
              </div>
  
              <div className="flex flex-col mt-4">
                <p className="font-open-sans font-semibold text-[#000] text-md">
                  Location
                </p>
                <p className="flex flex-row font-open-sans font-normal text-gray-500 text-sm">
                  {currentKitchen?.address ? (<LocationIcon className="w-3 h-3 m-1 text-primary-300" />): <></>}&nbsp;
                  {currentKitchen?.address ? `${currentKitchen.address}, `: ``} 
                  {currentKitchen?.city ? `${currentKitchen.city}, `: ``} 
                  {/* {kitchen.county ? `${kitchen.county}, `: ``}   */}
                  {currentKitchen?.state ? `${currentKitchen.state} `: ``}  
                  {currentKitchen?.zipcode} 
                </p>
              </div>
  
             <div className="flex flex-row gap-10">
               <div className="flex flex-col mt-4">
                 <p className=" font-open-sans font-semibold text-[#000] text-md">
                   Reservation Date
                 </p>
                 <p className="font-open-sans font-light text-gray-500 text-sm">
                   {currentBooking && currentBooking?.start_time
                     ? moment(new Date(currentBooking.start_time)).format(
                         "MMMM Do YYYY"
                       )
                     : ""}{" "}
                   
                 </p>
               </div>
               <div className="flex flex-col mt-4">
                 <p className=" font-open-sans font-semibold text-[#000] text-md">
                   Reservation Time
                 </p>
                 <p className="font-open-sans font-light text-gray-500 text-sm">
                   {currentBooking && currentBooking?.start_time
                     ? moment(new Date(currentBooking.start_time)).set('minutes', moment(new Date(currentBooking.start_time)).minutes() + Number(beforeBuffer)).format(
                         "h:mm a"
                       )
                     : ""}{" "}
                   &nbsp; - &nbsp;{" "}
                   {currentBooking && currentBooking?.end_time
                     ? moment(new Date(currentBooking.end_time)).set('minutes', moment(new Date(currentBooking.end_time)).minutes() - Number(afterBuffer)).format(
                         "h:mm a"
                       )
                     : ""}
                 </p>
                 {/* <div className="flex flex-row text-[8px] gap-5">
                  <p>-{beforeBuffer} min buffer before</p>
                  <p>+{afterBuffer} min buffer after</p>
                 </div> */}
               </div>

               <div className="flex flex-col mt-4">
                {
                  (isRepeat) ? (<span className="text-gray-500 font-open-sans text-sm">({calculateDateDifference(currentBooking.start_time, endRecurrence, bookingRecurrence)} Occurences)</span>) : (<></>)
                }
               </div>
             </div>
             
             <span 
                className={`${!isRepeat ? 'text-primary-300 hover:text-primary-200' : 'text-primary-200 hover:text-primary-300'}  cursor-pointer text-xs ml-auto my-2 mb-4`} 
                onClick={() => setIsRepeat(prev => !prev)}>
                  {!isRepeat ? '+\tAdd Repeat' : '-\tDo Not Repeat'}
             </span>

                {(isRepeat) && (<>
                <div className="flex flex-col w-full mx-auto justify-between p-2">
                  <div className="flex flex-row">
                    <p className="font-open-sans font-semibold text-[#000]/70 text-sm my-auto">Frequency</p>
                    <select className="bg-inherit my-auto mb-1 text-center text-xs cursor-pointer text-primary-300 rounded-lg w-1/6 mx-2"  
                            onChange={e => setBookingRecurrence(e.target.value as "daily" | "weekly" | "monthly") } 
                            value={bookingRecurrence}
                    >
                        {
                          ['daily', 'weekly', 'monthly'].map((rec, i) => {
                            return (
                              <option className='w-auto mx-auto' key={i} value={rec}>
                                {getFrequencyOption(currentBooking.start_time, rec)}
                              </option>
                            )
                          })
                        }
                    </select>
                  </div>

                  <div className="flex flex-row">
                    <p className="font-open-sans font-semibold text-[#000]/70 text-sm my-auto">Repeat Until</p>
                    <input value={endRecurrence} 
                          onChange={e => {
                              setEndRecurrence(e.target.value)
                            }} 
                          className="text-xs bg-inherit my-1 text-center cursor-pointer text-primary-300 rounded-lg w-3/7 mx-2" 
                          type="date" 
                          min={moment(currentBooking.end_time).format('YYYY-MM-DD')}
                          max={addDaysToToday(maxBookingAdvanceDays)}
                          placeholder="End Repeat Date"
                    />
                  </div>
                </div>
                </>
                ) }
  
              {/* <div className='flex flex-col mt-4'>
                        <p className=' font-open-sans font-bold text-[#000] dark:text-white/60 text-[16px]'>Storage & Equipments</p>
                        <p className='font-open-sans font-light text-[#000000] dark:text-white text-[13px]'>No storage or equipments available</p>
                     </div> */}
              {/* <h1 className="pt-3 text-lg font-semibold">Reservation Summary</h1> */}
              <div className="flex flex-col mt-4">
                <p className="font-open-sans font-semibold text-[#000]  text-md">
                  Additional Equipments
                </p>
                <p className="font-open-sans font-light text-[#000000]  text-sm">
                  {equipment}
                </p>
              </div>
  
              <div className="flex flex-col sm:w-3/4 lg:w-3/5 mt-4">
                <p className="font-open-sans font-semibold text-[#000]  text-md">
                  Notes
                </p>
                <p className="font-open-sans font-light text-gray-500 text-sm">
                  Add Reservation Notes
                  {/* {currentBooking?.notes ? currentBooking.notes : "No notes currently"} */}
                    <textarea name="notes" value={bookingNotes} onChange={e => updateBookingNotes(e.target.value)} className="border-0 rounded-md w-full h-16 text-sm text-gray-400" />
                </p>
              </div>

              <div className="flex flex-col sm:w-3/4 lg:w-3/5 mt-4">
                <p className="font-open-sans font-semibold text-[#000] text-md">
                    Payment
                </p>

              
                <span className="text-primary-300 hover:text-primary-200 cursor-pointer text-xs ml-auto my-2 mb-4" onClick={() => toggleViewPayMethods(prev => !prev)}>{viewPayMethods ? '+ Add New Card' : 'Select Existing Card'}</span>
              
                  {
                    (viewPayMethods) ? (
                    (paymentMethods && paymentMethods.length > 0) ? 
                    (
                      <select className='formInput text-gray-500  h-10  w-max mx-1 text-sm ' value={selectedPayMethod} onChange={e => updateSelectedPayMethod(e.target.value)}>
                        <option value="" disabled>
                          -- Select Existing Card --
                        </option>
                        {
                          paymentMethods.map((method: any, i: number) => {
                              return (
                                <>
                                  <option key={i} value={method.id}>
                                      {method.card.brand} {method.type} ending in {method.card.last4 || '---'} &nbsp;
                                      (Exp: {method.card.exp_month}/{method.card.exp_year})
                                  </option>
                                  {/* {getCardIcon(method.card.brand)} &nbsp; */}
                                </>
                              )
                          })
                        }
                      </select>
                    ) : (<p className="font-open-sans font-light text-gray-500 text-sm">No Existing Payment Method</p>)) : (
                      <StripePaymentCheckOut mode="setup" currency="usd" setup_future_usage='off_session' isAddPayMethod={true} hasExistingPayMethod={paymentMethods && paymentMethods.length > 0} />
                    )
                  }
                
                
              </div>
  
              {/* <div className='flex flex-col mt-4'>
                      <button className='border-[#5DB6CE] bg-white text-[#5DB6CE] w-80'>Contact Community</button>
                     </div> */}
            </div>
  
            <div className="col-span-1 w-full"></div>
  
            <div className="flex flex-col items-center">
              {currentBooking?.status ? (
                <div
                  style={{
                    backgroundColor: getBookingStatusColor(
                      currentBooking?.status
                    ),
                  }}
                  className={`rounded-md p-2 m-1 text-[11px] ml-[50%] text-[#fff]/80`}
                >
                  {capitalizeWords(currentBooking?.status)}
                </div>
              ) : null}
  
              <div className="flex flex-col font-work-sans text-[#D9D9D9] p-4 col-span-1 bg-[#555555] rounded-md w-full sm:w-9/12">
                <div className="flex flex-row w-full justify-between p-2 m-1">
                  <p className=" font-normal text-[#FFF] text-xl">
                    Price Details
                  </p>
                </div>
  
                {member?.plan_rate && member?.plan_name ? (
                  <div className="flex flex-row w-full justify-between p-2 m-1">
                    <div className="flex flex-col font-light text-[#FFF] text-left w-full">
                      <span className="text-[11px] text-white/60 ">
                        <b>{member?.plan_name?.toUpperCase()} PLAN:</b>{" "}
                        &nbsp;
                      </span>
                    </div>
                    <p className=" font-light text-[#FFF]/80 text-[13px] text-right w-full">
                      ${styleThousandCommas(member?.plan_rate)} ({member?.plan_recurrence})
                      <p 
                        className="text-primary-400 text-xs text-right cursor-pointer"
                        onClick={ () => {
                            handleClose()
                            toggleUpdatePlan(true)
                          }
                        }
                      >
                        Change
                      </p>
                    </p>
                  </div>
                ) : null}
  
                <div className="flex flex-row w-full justify-between p-2 m-1">
                  <div className="flex flex-col font-light text-[#FFF] ">
                    Kitchen Rental{" "}
                    <span className="text-[11px] text-white/60 ">
                      {hoursDiff
                        ? "(" +
                          hoursDiff.toString() +
                          ` hour${hoursDiff > 1 ? "s" : ""})`
                        : ""}
                    </span>
                  </div>
                  {/* <p className=" font-light text-[#FFF]/80 ">${styleThousandCommas(hourlyCost)}</p> */}
                  <p className=" font-light text-[#FFF]/80 text-sm">{!hourlyCost && hoursBalance ? `${hoursBalance} hours left` : `$${styleThousandCommas(hourlyCost)}`}</p>
                  
                </div>
  
                <div className="flex flex-row w-full justify-between p-2 m-1">
                  <p className=" font-light text-[#FFF] ">Equipment</p>
                  <p className=" font-light text-[#FFF]/80 ">{equipmentCost}</p>
                </div>
  
                <div className="flex flex-row w-full justify-between p-2 m-1">
                  <p className=" font-light text-[#FFF] ">Cleaning Fee</p>
                  <p className=" font-light text-[#FFF]/80 ">{cleaningCost}</p>
                </div>
  
                <div className="flex flex-row w-full justify-between p-2 m-1">
                  <p className=" font-light text-[#FFF] ">Service Fee</p>
                  <p className=" font-light text-[#FFF]/80 ">{serviceCost}</p>
                </div>
  
                <div className="flex flex-row w-full justify-between p-2 m-1">
                  <p className=" font-light text-[#5DB6CE] ">Member Discount</p>
                  <p className=" font-light text-[#5DB6CE]/80 ">- 0</p>
                </div>
  
                <div className="flex flex-row w-full justify-between p-2 m-1 border-t-[1px] border-white/60">
                  <p className=" font-light text-[#fff] ">Total (USD)</p>
                  <p className=" font-light text-[#fff]/80 ">
                    $ {styleThousandCommas(totalCost)}
                  </p>
                </div>
              </div>

              <div className="flex flex-row mt-2 justify-evenly">
                <button
                  className="buttonsPrimary border-0 bg-[#722121] text-[#eee] hover:bg-[#eee] hover:text-[#722121] m-1 p-2 rounded-md text-sm"
                  onClick={handleClose}
                >
                  Cancel Request
                </button>
                
                <button  
                  disabled={(!selectedPayMethod || !viewPayMethods) ? true : false}
                  className={`buttonsPrimary border-0 m-1 p-2 order-1 bg-gray-500 text-white ${(selectedPayMethod === "" || !viewPayMethods) ? 'cursor-not-allowed ' : '!bg-primary-200/90 !text-back-400 cursor-pointer'} text-sm`}
                  onClick={handleSubmitBooking}
                >
                  Submit Request
                </button>
              </div>

              
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className="bg-back-400 min-h-screen w-screen">
        <NotFound dataName="Reservation" previousPath="/kitchens/reservations" />
      </div>
    );
  }


};

export default BookingConfirmation;
