import React, {useEffect} from 'react'
import DropdownGrid from './DropdownGrid'
import { useCommunity } from '../../contexts/communitycontext'
import { KitchenSpaceSchema, SchoolGridProps } from "../../data/types";
import KitchenRecord from "../../components/KitchenRecord";

const CommunitiesGrid: React.FC<SchoolGridProps>  = ({sliced, searchTerm, changeSearchTerm}) => {

    var { allKitchens } = useCommunity()
    // console.log("allKitchens", allKitchens)

    useEffect(() => {
      // if (loadAllCommunities !== undefined) {
      //   loadAllCommunities()
      // }
    // eslint-disable-next-line 
    }, [])

    var nSlicedCols = '4'
    // var sch_list =  allKitchens ? (sliced ? allKitchens.slice(0,parseInt(nSlicedCols)) : allKitchens) : null
    var nSchs = allKitchens ? allKitchens.length : 0

    return (
      <div className='flex flex-col font-open-sans w-full'>
          {
            (sliced) ? null :(
            <div className='px-4 flex-row justify-between w-full hidden sm:flex'>
              <div className='text-[#696969] text-[16px] dark:text-[#FFF]/40'>Showing <span className='font-bold text-[#202124] dark:text-[#FFF]/60'>{(nSchs > 0) ? '1' : '0'}</span> - <span className='font-bold text-[#202124] dark:text-[#FFF]/60'>{nSchs ? nSchs.toString() : '0'}</span> of <span className='font-bold text-[#202124] dark:text-[#FFF]/60'>{nSchs ? nSchs.toString() : '0'}</span> kitchens</div>
              <div><DropdownGrid searchTerm={searchTerm} changeSearchTerm={changeSearchTerm} /></div>
            </div>)
          }
          <div className={`p-4 grid md:grid-cols-${sliced ? nSlicedCols : '3'} gap-3 w-full`}> 
              {/* Card */}
              {
                  allKitchens?.map((kitchen: KitchenSpaceSchema, i: number) => {
                      // if (sliced) {
                      //     return <SlicedCommunityCard key={i} school={school} />
                      // }
                      return <KitchenRecord key={i} kitchen={kitchen} />
                  } )
              }
          </div>
      </div>
    )
}

export default CommunitiesGrid