import React from 'react'

import { useNavigate, useLocation } from 'react-router-dom';

import { useAuthState } from '../contexts/authcontext'
import { useLoadAuthUser } from '../hooks/authhooks'
// import { useMemberState } from '../contexts/membercontext'

import { ReactComponent as HomeIcon } from '../icons/Home.svg'
// import { ReactComponent as CommunityIcon } from '../icons/Community.svg'
import { ReactComponent as MessageIcon } from '../icons/Message.svg'
import { ReactComponent as BookingsIcon } from '../icons/Bookings.svg'
import { ReactComponent as SettingsIcon } from '../icons/Settings.svg'
// import { ReactComponent as LoginIcon } from '../icons/Login.svg'
// import { ReactComponent as OrderIcon } from '../icons/OrderIcon.svg'

// import { FaUserFriends } from 'react-icons/fa'
import {
    //  AiFillHome, AiOutlineClose, 
     AiOutlineUser } from 'react-icons/ai'
import { BiExit } from "react-icons/bi";


import homecook_logo from '../assets/img/homecook_logo.png'
// import DarkModeSwitcher from '../components/switcher'
const account_avatar: string = `https://upload.wikimedia.org/wikipedia/commons/1/1e/Default-avatar.jpg`



type SideBarOptions = {
    name: string,
    path: string,
    icon: React.FC<React.SVGProps<SVGSVGElement>>
    image?: string
}


var unauthDrawerItems: SideBarOptions[] = [
    {
        name: 'Home',
        path: '/',
        icon: HomeIcon,
        image: ''
    },
    {
        name: 'Logout',
        path: '/login',
        icon: BiExit
    }
]

var authDrawerItems: SideBarOptions[] = [
    {
        name: 'Home',
        path: '/',
        icon: HomeIcon
    },
    {
        name: 'Reservations',
        path: '/bookings',
        icon: BookingsIcon
    },
    {
        name: 'Messages',
        path: '/messages',
        icon: MessageIcon
    },
    {
        name: 'Settings',
        path: '/settings',
        icon: SettingsIcon
    },
    {
        name: 'Account',
        path: '/account',
        icon: AiOutlineUser,
        image: account_avatar
    },
    {
        name: 'Logout',
        path: '/login',
        icon: BiExit
    }
]


const Navbar = () => {

  var navigate = useNavigate();

  var { authUser, isloggedin, isverified } = useAuthState()
  const { signOutUser } =  useLoadAuthUser()
 //  var { membershipRequests } = useMemberState()
 
 
  var view_postonboard = isloggedin && isverified
 //  var nMembershipRequests: number = membershipRequests ? membershipRequests.length : 0
 //  var view_otp_form = isloggedin && !isverified
 
 
  const clickNav = (route: string, itemName: string) => {
     navigate(route)
     if (itemName === "Logout") {
         // Handle logout logic here
         // For example, reset the logged-in state to false
         if (signOutUser !== undefined){signOutUser()}
       }
 }
 
    const location = useLocation()
    var currentLocation = location.pathname
    
 
    var drawerItems = view_postonboard ? authDrawerItems : unauthDrawerItems

  return (
    <div className="bg-primary-200 fixed w-full z-10 h-12 font-open-sans">
      <div className="flex flex-row items-center w-full h-12">
        <span className='flex flex-row items-center cursor-pointer' onClick={() => navigate('/')}>
            <img src={homecook_logo} alt="Kitchen Logo" className="w-16 " />
            <h1 className='text-white invisible sm:visible text-xl'>HomeCook</h1>
        </span>
        <div className='flex flex-row ml-auto justify-end'>
            { 
              drawerItems.map((item, i) => {
              return (
                          <span key={i} className={`text-xl py-3.5 flex flex-row cursor-pointer font-open-sans mx-3`} onClick={() => clickNav(item.path, item.name)}>          
                              {
                                (item.name === 'Account') ? (
                                    <img className='relative m-auto w-6 rounded-full' alt={item.name} src={authUser?.avatar || account_avatar} />  
                                    ) : ( <>
                                            {
                                                (item.name === 'Logout') ? (
                                                    <item.icon className={`rotate-180 relative m-auto w-5 hover:text-[#bce9f5] ${currentLocation === item.path ? 'text-[#5DB6CE]' : 'text-[#D9D9D9]'}`} />
                                                ) : (<>
                                                    <item.icon className={`relative m-auto w-5 hover:text-[#bce9f5] ${currentLocation === item.path ? 'text-[#5DB6CE]' : 'text-[#D9D9D9]'}`} />
                                                </>)
                                            }
                                    </> )
                              }
                                  
                          </span>
                      )
              })
            }
         </div>
         {/* <DarkModeSwitcher/> */}
      </div>
      

        {/* <nav className='flex flex-row w-9/12'>
          <div className='flex flex-row text-gray-800 w-full'>
              
          </div> 
        </nav> */}
      
    </div>
  );
}

export default Navbar;
