import React from 'react'
import { ReactComponent as StarIcon } from "../icons/star.svg";

type StarRatingProp = {
    review_rating: number
}

const StarRating: React.FC<StarRatingProp> = ({review_rating}) => {
  return (
    <div className="grid grid-flow-col gap-x-2">
        {review_rating && review_rating > 0
        ? Array.from({ length: 5 }, (_, index) =>
            index < review_rating ? 1 : 0
            ).map((s, i) => (
            <StarIcon
                key={i}
                className={`w-4 h-4 ${
                s === 1
                    ? "text-[#ED8A19]"
                    : "text-gray-400"
                } `}
            />
            ))
        : Array.from({ length: 5 }).map((s, i) => (
            <StarIcon
                key={i}
                className="w-4 h-4 text-gray-400"
            />
            ))}
    </div>
  )
}

export default StarRating