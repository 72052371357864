import { useQuery} from '@tanstack/react-query'
import { 
    getAllKitchens, getKitchenID, 
    getAllCommunities,
    getCommunityByID,
    getCommunityBookings,
    // addKitchen, updateKitchen, updateMembershipPlans, 
    // getMembershipPlan, 
    // deleteMembershipRequirement, addMembershipRequirement, 
    // addMembershipPlans, 
    getMembershipPlans, 
    // getMembershipRequirements, 
} from '../api/crud'
import { CommunitySchema, KitchenSpaceSchema} from "../data/types";
import { useCommunity } from '../contexts/communitycontext'


const staleTime = 1000
const isEnabled = true

type KitchenRespType = {
    primaryKitchen: KitchenSpaceSchema
    kitchens: KitchenSpaceSchema[]
}



export const useLoadCommunities = () => {
    var { updateCommunities } = useCommunity()
    // let communityId = currentCommunity?.id
    const {data: allCommunities, refetch: reloadCommunities,  isLoading: isLoadingCommunities} = useQuery({ 
        queryKey: ['communities'], 
        queryFn: async () => {
            console.log("fetching communities")
            var res = await getAllCommunities()
            if (res && updateCommunities!== undefined){
                updateCommunities(res)
                return res
            } 
            return []
            
        },
        staleTime,
        enabled: isEnabled
    })

    useLoadPlans()
    return {allCommunities, isLoadingCommunities, reloadCommunities}
}


export const useSearchCommunities = () => {
    var { updateCommunities } = useCommunity()
    // let communityId = currentCommunity?.id
    const {data: allCommunities, refetch: reloadCommunities,  isLoading: isLoadingCommunities} = useQuery({ 
        queryKey: ['communities'], 
        queryFn: async () => {
            console.log("fetching communities")
            var res = await getAllCommunities()
            if (res && updateCommunities!== undefined){
                updateCommunities(res)
                return res
            } 
            return []
        },
        staleTime,
        enabled: isEnabled
    })

    const handleSearch = (searchTerm: {search: string, filter: string}) => {
        if (allCommunities && searchTerm?.search){
            let searchedCommunities =  allCommunities.filter((community: CommunitySchema) => community.name?.toLowerCase().includes(searchTerm.search.toLowerCase()))
            if (updateCommunities) {updateCommunities(searchedCommunities)}
        }
    };

    useLoadPlans()
    return {allCommunities, isLoadingCommunities, handleSearch, reloadCommunities}
}


export const useLoadCurrentCommunity = (communityId: string) => {
    let { updateCurrentCommunity  } = useCommunity()
    const {data: currentCommunity, refetch: reloadCurrentKitchen, isLoading: isLoadingCurrentCommunity} = useQuery({ 
        queryKey: ['community', communityId], 
        queryFn: async () => {
            console.log("fetching community by id")
            var res = await getCommunityByID(communityId ? communityId : "")
            if (res && updateCurrentCommunity!== undefined){
                updateCurrentCommunity(res)
            }
            return res
        },
        staleTime,
        enabled: isEnabled
    })
    
    return { isLoadingCurrentCommunity, currentCommunity, reloadCurrentKitchen}
}


export const useLoadCommunityKitchen = () => {
    var { currentCommunity, searchQuery, nameSort, idSort, setKitchen, updateKitchens } = useCommunity()
    let communityId = currentCommunity?.id
    const {data: kitchenResponse, refetch: reloadKitchens,  isLoading: isLoadingKitchens} = useQuery({ 
        queryKey: ['kitchens', communityId], 
        queryFn: async () => {
            if (communityId){
                console.log("fetching kitchens")
                var res = await getAllKitchens(communityId)
                let primaryKitchen = (res && res.length > 0) ? res[0] : null
                if (primaryKitchen && updateKitchens !== undefined && setKitchen!== undefined){
                    if (searchQuery) {
                        res = res.filter((kitchen: KitchenSpaceSchema) => {
                            // filter the kitchens based on the provided id in the search.
                            return kitchen.id === searchQuery;
                        })
                    }
                    if (nameSort) {
                        res = res.sort((first: KitchenSpaceSchema, second: KitchenSpaceSchema) => {
                            if (first.name && second.name && first.name < second.name) return -1;
                            if (first.name && second.name && first.name > second.name) return 1;
                            return 0;
                        });
                    }
                    if (idSort) {
                        res = res.sort((first: KitchenSpaceSchema, second: KitchenSpaceSchema) => {
                            if (first.id && second.id && first.id < second.id) return -1;
                            if (first.id && second.id && first.id > second.id) return 1;
                            return 0;
                        });
                    }
                    updateKitchens(res ? res : []);
                    setKitchen(primaryKitchen)
                }   

    
                const kitchenResponse: KitchenRespType = {
                    primaryKitchen,
                    kitchens: res
                }
                return kitchenResponse
            }
        },
        staleTime,
        enabled: isEnabled
    })

    useLoadPlans()
    // useLoadRequirements()
   
    if (kitchenResponse){
        let {primaryKitchen, kitchens} = kitchenResponse
        return {primaryKitchen, kitchens, reloadKitchens}
    }
    return { isLoadingKitchens, primaryKitchen: null, kitchens: [], reloadKitchens}
}



export const useLoadCurrentKitchen = (communityId: string, kitchenId: string) => {
    let { setKitchen } = useCommunity()
    const {data: currentKitchen, refetch: reloadCurrentKitchen, isLoading: isLoadingKitchen} = useQuery({ 
        queryKey: ['kitchen', communityId, kitchenId], 
        queryFn: async () => {
            console.log("fetching kitchen by id")
            var res = await getKitchenID({communityId: communityId ? communityId : "", id: kitchenId})
            if (res && setKitchen!== undefined){
                setKitchen(res)
            }
            return res
        },
        staleTime,
        enabled: isEnabled
    })
    var { isLoadingPlans } = useLoadPlans()
    // var { isLoadingRequirements } = useLoadRequirements()
    var isLoadingCurrentKitchen = isLoadingKitchen || isLoadingPlans 
    // || isLoadingRequirements
    return { isLoadingCurrentKitchen, currentKitchen, reloadCurrentKitchen}
}



export const useLoadPlans = () => {
    let { currentCommunity, setPlans } = useCommunity()
    let communityId = currentCommunity?.id
    const {data: plans, refetch: reloadPlans, isLoading: isLoadingPlans} = useQuery({ 
        queryKey: ["plans", communityId], 
        queryFn: async () => {
            if (communityId){
                console.log("fetching kitchen plans")
                var res = await getMembershipPlans(communityId)
                if (res && setPlans!== undefined){
                    setPlans(res)
                }
                return res
            }
            return []
        },
        staleTime,
        enabled: isEnabled
    })
    return { isLoadingPlans, plans, reloadPlans}
}


export const useLoadCommunityBookings = () => {
    let { currentCommunity } = useCommunity()
    let communityId = currentCommunity?.id
    const {data: bookings, refetch: reloadBookings, isLoading: isLoadingBookings} = useQuery({ 
        queryKey: ["communityBookings", communityId], 
        queryFn: async () => {
            if (communityId){
                console.log("fetching community bookings")
                var res = await getCommunityBookings(communityId)
                return res
            }
            return []
        },
        staleTime,
        enabled: isEnabled
    })
    return { isLoadingBookings, bookings, reloadBookings}
}

// export const useLoadRequirements = () => {
//     let { communityId, setRequirements } = useCommunity()
//     const {data: requirements, refetch: reloadRequirements, isLoading: isLoadingRequirements} = useQuery({ 
//         queryKey: ['requirements', communityId], 
//         queryFn: async () => {
//             if (communityId){
//                 console.log("fetching kitchen requirements")
//                 var res = await getMembershipRequirements(communityId)
//                 if (res && setRequirements!== undefined){
//                     setRequirements(res)
//                 }
//                 return res                
//             }
//             return []
//         },
//         staleTime,
//         enabled: isEnabled
//     })
//     return { isLoadingRequirements, requirements, reloadRequirements}
// }


// export const useKitchenHook = () => {
//     var { communityId, plans, requirements, setKitchen, setPlans, setRequirements } = useCommunity()
//     const { reloadPlans } = useLoadPlans()
//     const { reloadRequirements } = useLoadRequirements()
//     const { reloadKitchens } = useLoadCommunityKitchen()

//     const addKitchenMutation = useMutation({ 
//         mutationFn: (kitchen: KitchenSpaceSchema | {}) => {
//             console.log("add kitchen")
//             const res = addKitchen(communityId ? communityId : "", kitchen)
//             reloadKitchens()
//             return res
//     }})
//     const addKitchenSpace = (kitchen: KitchenSpaceSchema | {}) => {
//         addKitchenMutation.mutate(kitchen)
//         return addKitchenMutation?.data ? true : false
//     }

//     const updateKitchenMutation = useMutation({ 
//         mutationFn: async ({planId, planUpdate}: {planId: string, planUpdate: PlanSchema}) => {
//             console.log("update plan")
//             const res = await updateMembershipPlans(communityId ? communityId : "", planUpdate, planId)
//             reloadPlans()
//             return res
//     }})
//     const updateKitchenPlan = (planId: string, planUpdate: PlanSchema | {}) => {
//         updateKitchenMutation.mutate({planId, planUpdate})
//         return updateKitchenMutation?.data ? true : false
//     }

//     const addPlanMutation = useMutation({ 
//         mutationFn: async (plan: PlanSchema | {}) => {
//             console.log("add plan")
//             const res = await addMembershipPlans(communityId ? communityId : "", plan)
//             if (res && setPlans !== undefined){
//                 let updated_plans: any = (plan && plans) ? [...plans] : []
//                 updated_plans.push(res)
//                 setPlans(updated_plans)
//             }
//             return res
//     }})
//     const addKitchenPlan = (plan: PlanSchema | {}) => {
//         addPlanMutation.mutate(plan)
//         return addPlanMutation?.data ? true : false
//     }

//     const updatePlanMutation = useMutation({ 
//         mutationFn: async ({spaceId, kitchenUpdates}: {spaceId: string, kitchenUpdates: KitchenSpaceSchema}) => {
//             console.log("update plan")
//             const res = await updateKitchen({communityId: communityId ? communityId : "", spaceId, kitchenUpdates})
//             if (res && setKitchen !== undefined){
//                 setKitchen(res)
//             }
//             return res
//     }})
//     const updateKitchenSpace = (spaceId: string, kitchenUpdates: KitchenSpaceSchema) => {
//         updatePlanMutation.mutate({spaceId, kitchenUpdates})
//         return updatePlanMutation?.data ? true : false
//     }

//     const addRequirementMutation = useMutation({ 
//         mutationFn: async (requirement: RequirementSchema | {}) => {
//             console.log("add requirement")
//             const res = await addMembershipRequirement(communityId ? communityId : "", requirement)
//             if (res && setRequirements !== undefined){
//                 let updated_reqs: RequirementSchema[] = (requirement && requirements) ? [...requirements] : []
//                 updated_reqs.push(res)
//                 setRequirements(updated_reqs)
//             }
//             return res
//     }})
//     const addKitchenRequirement = (requirement: RequirementSchema | {}) => {
//         addRequirementMutation.mutate(requirement)
//         return addRequirementMutation?.data ? true : false
//     }

//     const deleteRequirementMutation = useMutation({ 
//         mutationFn: async (req_id: string) => {
//             console.log("delete requirement")
//             const res = await deleteMembershipRequirement(communityId ? communityId : "", req_id)
//             reloadRequirements()
//             return res
//     }})
//     const deleteKitchenRequirement = (req_id: string) => {
//         deleteRequirementMutation.mutate(req_id)
//         return deleteRequirementMutation?.data ? true : false
//     }

//     const isLoading = (
//         addKitchenMutation.isPending ||
//         updateKitchenMutation.isPending ||
//         addPlanMutation.isPending || 
//         updatePlanMutation.isPending || 
//         addRequirementMutation.isPending || 
//         deleteRequirementMutation.isPending 
//         // || isLoadingCommunity
//     );

//     return { isLoading, addKitchenSpace, addKitchenPlan, addKitchenRequirement, updateKitchenSpace, updateKitchenPlan, deleteKitchenRequirement }
// }

// export const useGetKitchenPlan = (communityId: string, planId: string) => {
//     // let { communityId } = useCommunity()
//     const {data: plans, refetch: reloadPlans, isLoading: isLoadingPlan} = useQuery({ 
//         queryKey: ["plan", communityId, planId], 
//         queryFn: async () => {
//             if (communityId){
//                 console.log("fetching plan by id")
//                 var res = await getMembershipPlan(communityId, planId)
//                 return res
//             }
//             return []
//         },
//         staleTime,
//         enabled: isEnabled
//     })
//     return {isLoadingPlan, plans, reloadPlans}
// }