import {useEffect} from 'react'

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import { ReactComponent as VisaCardIcon } from '../../icons/visaCardIcon.svg'
// import { ReactComponent as DiscoverCardIcon } from '../../icons/discoverCardIcon.svg'
import { ReactComponent as MasterCardIcon } from '../../icons/masterCardIcon.svg'
import { ReactComponent as AmexCardIcon } from '../../icons/amexCardIcon.svg'


// import { useMemberState } from '../../contexts/membercontext'
import { useGetPaymentMethods } from '../../hooks/memberhooks'

import LoadingScreen from "../../pages/shared/LoadingScreen"
import StripePaymentCheckOut from "../../molecules/views/PaymentCheckOut"




// enum PaymentMethods {
//   VISA = 'visa',
//   MASTERCARD = 'mastercard',
//   AMERICAN_EXPRESS = 'amex',
//   DISCOVER = 'discover',
//   DINERS_CLUB = 'dinersclub',
//   JCB = 'jcb',
//   UNIONPAY = 'unionpay',
//   UNKNOWN = 'unknown', // For any unknown card type
// }


const AccountPaymentMethods = () => {

  var {paymentMethods, isLoadingPaymentMethods} = useGetPaymentMethods()

  const getCardIcon = (paymentMethod: string) => {
    switch (paymentMethod) {
      case 'visa':
        return <VisaCardIcon className='w-8 my-auto' />;
      case 'mastercard':
        return <MasterCardIcon className='w-8 my-auto' />;
      case 'amex':
        return <AmexCardIcon className='w-8 my-auto' />;
      // Add cases for other card types
      default:
        return <img src="/icons/unknown.svg" alt="Unknown" />;
    }
  };


  // var { bookings: paymentMethods, isLoadingBookings: isLoadingPaymentMethods } = useLoadBookings()

  paymentMethods = (paymentMethods && Array.isArray(paymentMethods)) ? paymentMethods : []
  
  useEffect(() => {
    document.title = 'Account | Payment'
  }, [])

  if (!paymentMethods || isLoadingPaymentMethods){
    return (<LoadingScreen height='h-auto' bgColor='inherit' /> )
  } else {
    return (
      <div className='flex flex-row'> 
        <div className='flex flex-col w-1/2 my-10 text-primary-200 font-open-sans ml-auto'>
          <p className='text-md font-bold'>Current Payment Methods ({paymentMethods.length})</p>
          {
            paymentMethods && paymentMethods.map((paymentMethod: any, i: number) => {
              return (
                <div className='flex flex-row text-sm text-primary-300' key={i}>
                  {i+1}) &nbsp;
                  {getCardIcon(paymentMethod.card.brand)} &nbsp;
                  <span className='text-primary-200 font-medium'>
                    {paymentMethod.type} ending in {paymentMethod.card.last4 || '---'} &nbsp;
                    (Exp: {paymentMethod.card.exp_month}/{paymentMethod.card.exp_year})
                  </span>

                  <span className='ml-4 my-auto'>
                    <EditIcon style={{fontSize: 18}} className="cursor-pointer text-primary-400 m-1" />
                    <DeleteIcon style={{fontSize: 18}} className="cursor-pointer text-red-900 m-1" />
                  </span>
                  
                </div>
              )
            })
          }
        </div>
        <div className='flex flex-col text-primary-200 font-open-sans w-auto items-center mr-auto'>
          <div className='my-10 text-sm font-bold'>Add New Payment Method</div> 
          <StripePaymentCheckOut mode="setup" currency="usd" setup_future_usage='off_session' isAddPayMethod={true} />
        </div>
      </div>
    )
  }
}


export default AccountPaymentMethods