import {useEffect, useState } from "react";
// import PhoneLogin from './PhoneLogin'
import EmailLogin from './EmailLogin'
import EmailSignUp from './EmailSignUp'
// import { useAuthState } from '../../../contexts/authcontext'
import { useLoadAuthUser } from '../../../hooks/authhooks'


const Login = () => {


    // var { loginType  } = useAuthState()
    const { registerUser } = useLoadAuthUser()
    const [isSignUp, setIsSignUp] =  useState(false)


    useEffect(()=> {
        document.title = 'Signup | HomeCook'
     }, [])  
     
    //  console.log("loginType", loginType)

   //   if (registerUser !== undefined ){
   //      return (loginType === "phone") ? <PhoneLogin registerUser={registerUser} toggleEmail={() => handleUpdateLoginType ? handleUpdateLoginType("email") : null} /> : <EmailLogin registerUser={registerUser} togglePhone={()=> handleUpdateLoginType ? handleUpdateLoginType("phone"): null} />
   //   }
     if (registerUser !== undefined ){
         return (isSignUp) ? <EmailSignUp registerUser={registerUser} toggleSignIn={() => setIsSignUp(false)} /> : <EmailLogin registerUser={registerUser} toggleSignUp={() => setIsSignUp(true)} />
      }
     return <></>
}




export default Login
