import React, {ReactNode, useState, useContext, createContext} from 'react'
// import { getAllCommunities,  getCommunityByID, searchCommunities, generateAIResponse } from '../api/crud'
// import { useMemberState } from './membercontext'
// import { useAuthState } from './authcontext'

import { AuthSchema, CommunitySchema, MessageSchema, KitchenSpaceSchema, CommunityStateType } from "../data/types";

const CommunityContext = createContext({});

const CommunityProvider: React.FC<{children: ReactNode, authUser: AuthSchema}> = props => {
    const [allCommunities, updateCommunities] = useState<CommunitySchema[]>([])
    const [allKitchens, updateAllKitchens] = useState<CommunitySchema[] | KitchenSpaceSchema[]>([])
    const [currentCommunity, updateCurrentCommunity] = useState<CommunitySchema>({})
    // const [currentPlans, updateCurrentPlans] = useState<PlanSchema[]>([])
    const [messageList, updateMessageList] = useState<MessageSchema[]>([])

    // const [kitchens, updateKitchens] = useState(props.initialKitchens || undefined);
    const [searchQuery, setSearchQuery] = useState("");
    const [nameSort, setNameSort] = useState(false);
    const [idSort, setIDSort] = useState(false);
    const [currentKitchen, setKitchen] = useState<KitchenSpaceSchema | null>(null);
    // const [plans, setPlans] = useState<PlanSchema[]>([])
    // const [requirements, setRequirements] = useState<RequirementSchema[]>([])
  
    // var { communityId } = props
    // var communityId = (authUser && 'primary_community' in authUser && authUser.primary_community) ? authUser.primary_community : ""
  
    const handleSearch = (value: string) => {
      setSearchQuery(value);
    };
    const toggleNameSort = () => {
      setNameSort((prevSort) => !prevSort);
    };
  
    const toggleIDSort = () => {
      setIDSort((prevSort) => !prevSort);
    };
  
  
    const clearCurrentKitchen = () => {
      setKitchen(null)
    }
  
  
    let data = { 
    //   communityId, 
      idSort,
      nameSort,
      allCommunities,
      currentCommunity,
      allKitchens, 
      currentKitchen,
      messageList,
      // currentPlans, 
    //   requirements, 
      searchQuery, 
      setKitchen, 
    //   setRequirements,
      clearCurrentKitchen, 
      updateCommunities,
      updateCurrentCommunity,
      updateMessageList,
      updateAllKitchens,
      // updateCurrentPlans, 
      handleSearch,
      toggleNameSort, 
      toggleIDSort
    }
    return <CommunityContext.Provider value={data} {...props} />
}

const useCommunity = () => {
    const context: CommunityStateType = useContext(CommunityContext)
    if (context === undefined ){
        throw new Error(`useCommunity must be used within CommunityProvider`)
    }
    return context
}

export { CommunityProvider, useCommunity }
