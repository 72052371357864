import {useState, useEffect} from 'react'

import CommunitiesGrid from '../../molecules/views/CommunitiesGrid'
import KitchensGrid from '../../molecules/views/KitchensGrid'
import NewSearch from '../../components/NewSearch'


type CommunitiesProps = {
  forKitchen?:boolean
}


const Communities: React.FC<CommunitiesProps> = ({forKitchen}) => {

  useEffect(() => {
    document.title = `HomeCook | Communities`
  }, [])

  const [searchTerm, changeSearchTerm] = useState<{search:string, filter: string}>({search:"", filter: ""})

  return (
    <div className='mx-auto w-full flex font-open-sans h-[900px] sm:h-screen overflow-y-scroll bg-[#D9D9D9] dark:bg-[#013847]'>
         <div className='flex flex-col w-full h-full relative items-start'>
            <NewSearch searchTerm={searchTerm} changeSearchTerm={changeSearchTerm} searchType={forKitchen ? "kitchens" : "communities"} />
            <div className='grid grid-cols-1 my-5 w-full p-4'>
                <div className="flex flex-col text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 justify-center">
                    {forKitchen ? <KitchensGrid searchTerm={searchTerm} changeSearchTerm={changeSearchTerm} /> : <CommunitiesGrid searchTerm={searchTerm} changeSearchTerm={changeSearchTerm} />}
                </div>
            </div>
        </div>
    </div>
  )
}


export default Communities