import React, { useState, useRef } from "react";
import { useNavigate} from "react-router-dom";

// import LoadingScreen from "../../shared/LoadingScreen"
import { useAuthState } from '../../../contexts/authcontext'
import { useLoadAuthUser } from "../../../hooks/authhooks";

import homecook_logo from '../../../assets/img/homecook_logo.png'
// const homecook_logo = "https://homecookapp.com/static/media/homecook_logo.1ca10013af71605db8e8.png"




const OTPForm = () => {
   
    const navigate = useNavigate()
    var { authUser, loginType } = useAuthState()
    var {isLoading, verifyUser, registerUser, signOutUser} = useLoadAuthUser()
    let phone = authUser ? authUser.phone : null
    // let { phone, registerUser, verifyUser } = props
    const [v1, updateCode1] = useState('')
    const [v2, updateCode2] = useState('')
    const [v3, updateCode3] = useState('')
    const [v4, updateCode4] = useState('')
    const [v5, updateCode5] = useState('')
    const [resent, updateResent] = useState(false)
    const [wrongCode, updateWrongCode] = useState(false)

    const resendOTP = async () => {
        if (phone && !isNaN(parseInt(phone))){
            let newuser = (registerUser !== undefined) ? await registerUser({phone}) : null
            if (newuser){
                updateResent(true)
            }
        }

    }

    const goBackToEmailLogin = () => {
      signOutUser()
      navigate('/'); // Navigate back to the Email Login screen
  }

    // const [current_code, updateCode] = useState({prev_val: null, next_val: 1})

    const submitVerification = async () => {
        let full_otp = `${v1}${v2}${v3}${v4}${v5}`
        let verified = (verifyUser !== undefined) ? await verifyUser(full_otp) : null
        // console.log('verified', verified)
        if (!verified){
            updateWrongCode(true)
        }
    }

    const isValid = (value: string): boolean => ((parseInt(value) < 10 && value.length < 2) || value === '') ? true : false
    const input1 = useRef<HTMLInputElement | null>(null);
    const input2 = useRef<HTMLInputElement | null>(null);
    const input3 = useRef<HTMLInputElement | null>(null);
    const input4 = useRef<HTMLInputElement | null>(null);
    const input5 = useRef<HTMLInputElement | null>(null);

    var otp_input_style = 'h-[50px] w-[70%] m-[1%] bg-transparent p-2 rounded-md border-2 border-[#013847] text-[#000000] text-center'

    var new_forms = (
    <div className="flex-row flex">
      <input name="v1" ref={input1} type="text" onChange={e => updateField(e, "v1")} value={v1}  className={otp_input_style} />
      <input name="v2" ref={input2} type="text" onChange={e => updateField(e, "v2")} value={v2}  className={otp_input_style} />
      <input name="v3" ref={input3} type="text" onChange={e => updateField(e, "v3")} value={v3}  className={otp_input_style} />
      <input name="v4" ref={input4} type="text" onChange={e => updateField(e, "v4")} value={v4}  className={otp_input_style} />
      <input name="v5" ref={input5} type="text" onChange={e => updateField(e, "v5")} value={v5}  className={otp_input_style} />
    </div>
    )

    const updateField = (e: React.ChangeEvent<HTMLInputElement>, pos: string) => {
        if (pos === 'v1'){
          updateCode1(isValid(e.target.value) ? e.target.value : v1)
          if (e.target.value.length === 1) {
            input2?.current?.focus();
          }
        } else if (pos === 'v2'){
          updateCode2(isValid(e.target.value) ? e.target.value : v2)
          if (e.target.value.length === 1) {
            input3?.current?.focus();
          }
        } else if (pos === 'v3'){
          updateCode3(isValid(e.target.value) ? e.target.value : v3)
          if (e.target.value.length === 1) {
            input4?.current?.focus();
          }
        } else if (pos === 'v4'){
          updateCode4(isValid(e.target.value) ? e.target.value : v4)
          if (e.target.value.length === 1) {
            input5?.current?.focus();
          }
        } else if (pos === 'v5'){
          updateCode5(isValid(e.target.value) ? e.target.value : v5)
        }

    }

    let full_otp = `${v1}${v2}${v3}${v4}${v5}`
    // "+1(XXX)XXX-XXXX"

    // console.log("authUser", authUser)

    return (
        <div className="w-full mx-auto p-auto overflow-y-scroll bg-[#D9D9D9] md:relative flex-col justify-evenly items-start pt-56 md:pt-0 md:top-11 h-screen" >

                  <img src={ homecook_logo} alt="HomeCook" className='w-[100px] mx-auto cursor-pointer' onClick={()=> navigate(`/`)} />
                  <div>
                      {/* <p className="text-[20px] text-center m-[1%] font-medium">{authUser?.login_community?.name ? `${authUser.login_community.name} Community` : 'Welcome Back!'}</p> */}
                      {(authUser && authUser?.login_community?.name) ? (
                        <p className="text-[20px] text-center m-[1%] font-medium">Login to <span className="text-[18px] font-normal">{authUser.login_community.name}</span></p>
                        ) : (
                          <p className=" text-[20px] text-center m-[1%] font-medium">Welcome Back!</p>
                          )
                        }
                      <p className="text-[#013847] text-[12px] font-normal text-center m-[1%]">Enter the one-time passcode sent to {phone && loginType === 'phone' ? `${phone.slice(0, 2)} (${phone.slice(2, 5)}) ${phone.slice(5, 8)}-${phone.slice(8, 12)}` : authUser?.username?.toLowerCase()}</p>
                  </div>

                  <div className="mx-auto w-[320px]">
                      {(wrongCode && !isLoading) ? ( <p style={{fontFamily: 'Inter', fontSize: 10, fontWeight: 500, color: '#a40000', fontStyle: 'normal', textAlign: 'center', margin: '2%', width: '100%', cursor: 'pointer'}}>PLEASE ENTER CORRECT CODE</p> ) : null}
                      {new_forms}
                  </div>

                  <div>
                      {
                          (resent) ? ( <p className="text-[12px] font-normal text-center cursor-pointer m-2 text-green-500">VERIFICATION CODE RESENT</p> ) : (
                          <p onClick={resendOTP} className="text-[#2389FC] hover:text-[#2388fcaa] text-[12px] font-normal text-center cursor-pointer m-2">DIDN’T RECIEVE THE CODE? RESEND</p>)
                      }   <div onClick={goBackToEmailLogin}>
                        <p  className="text-[#639bc6] hover:text-[#639bc6b4] text-[12px] font-normal text-center cursor-pointer m-2">Wrong Email?</p>
                      </div>
                  </div>

                  <div className="mx-auto w-[200px]">
                      <button className="bg-[#5DB6CE] text-[#013847]  border-0 font-open-sans text-center mx-auto w-[100%] buttonsPrimary h-10"  disabled={!(full_otp && full_otp.length === 5)}  onClick={submitVerification}>Continue </button>
                  </div>
          </div>
    )
}



export default OTPForm
