import React, { useState, useEffect } from "react";

import homecook_logo from '../../../assets/img/homecook_logo.png'


import { AccountSchema, LoginProps } from "../../../data/types";



const EmailLogin: React.FC<LoginProps> = props => {
    useEffect(()=> {
        document.title = 'Sign In | HomeCook'
     }, [])

  
    const [email, updateEmail] = useState('')
    let { registerUser, toggleSignUp }: LoginProps = props

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
        if (field === 'email' && (e.target.value || e.target.value === "")){
                e.preventDefault()
                updateEmail(e.target.value)
            }
    }

    const goToOTP = async () => {
        if (email && registerUser !== undefined){
            let phone = ""
            var user_to_register: AccountSchema = {phone , email}
            // console.log("email", email)
            registerUser(user_to_register)
        }
    }

    return (
        <div className='flex w-full mx-auto p-auto items-start justify-evenly h-[900px] sm:h-screen overflow-y-scroll bg-[#D9D9D9] dark:bg-[#013847]'>
            <div className="relative mx-auto">
                <div className="flex flex-col justify-evenly align-middle w-[320px] mx-auto">
                    <img src={homecook_logo} alt="HomeCook" className='w-[100px] mx-auto cursor-pointer' onClick={()=> null} />
                    <h4 className=" text-center m-[1%] text-[20px] text-[#2D3748] dark:text-[#E1E1E1]">Welcome Back!</h4>
                    <p className="text-[16px] text-[#a9b2c0] text-center">Sign in with your email</p>
                    <div className="flex-row w-[100%]">
                        <input placeholder=""  type="email" value={email} className='h-[50px] w-[98%] m-[1%] bg-transparent p-2 rounded-md border-2 border-[#013847] text-[#000000] dark:border-[#D9D9D9] dark:text-[#FFFFFF] text-start' onChange={e => handleChange(e, 'email')} onKeyDown={e => (e.key === 'Enter' && email) ? goToOTP() : null } />
                    </div>

                    <button className="bg-[#5DB6CE] text-[#013847] dark:bg-[#E1E1E1] m-3 border-0 font-open-sans" disabled={!email}  onClick={() => goToOTP()}>SIGN IN</button>

                    <p className="text-[12px] text-[#a9b2c0] dark:text-[#E1E1E1] text-center font-extralight cursor-pointer hover:text-[#5DB6CE]" onClick={toggleSignUp}><span className="text-[#013847]">Don't have an account? </span>Sign up</p>

                </div>
           </div>
        </div>
    )
}






export default EmailLogin
