import React, { useEffect} from 'react'
import { BrowserRouter as Router } from 'react-router-dom'

import AuthView from './Auth'
import UnAuthView from './UnAuth'

import { useAuthState } from '../contexts/authcontext'
import { useLoadAuthUser } from "../hooks/authhooks";
// import SideBar from '../components/SideBar'
import Navbar from '../components/Navbar'
import LoadingScreen from "../pages/shared/LoadingScreen"


const HomeCookRouter = () => {
  
  const { loadAuthUser, isLoading } = useLoadAuthUser()

  let { isloggedin, isverified } = useAuthState()
  

  var view_postonboard = isloggedin && isverified

  useEffect(() => {
    // if (loadAuthUser !== undefined) {loadAuthUser()}
    loadAuthUser()
     // eslint-disable-next-line
  }, [])

  if (isLoading ){
    return (
      <LoadingScreen />
    )
  }

  return (
    <div>
        <Router basename="/">
            <div className='flex h-screen w-full'>
                <Navbar />
                {view_postonboard ? <AuthView /> : <UnAuthView />}
            </div>
        </Router>
    </div>
    )
}


export default HomeCookRouter;
