import React, { useState } from 'react'

import moment from "moment";
import { format, addWeeks, addMonths, startOfWeek } from "date-fns";

// import { useAuth } from "../contexts/authcontext";
// import { useMember } from "../contexts/membercontext";
// import { useKitchen } from "../contexts/kitchencontext";
// import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { ReactComponent as UpArrow } from "../icons/VectorUp.svg";
import { ReactComponent as DownArrow } from "../icons/VectorDown.svg";

import { useAuthState } from '../contexts/authcontext'
import { useMemberState } from '../contexts/membercontext'
import { useAddBooking } from '../hooks/memberhooks'
import { useLoadCommunityBookings } from '../hooks/communityhooks'

// import  EquipmentsWidget from './EquipmentsWidget'
import LoadingScreen from "../pages/shared/LoadingScreen"

import { BookingSchema, KitchenSpaceSchema, MembershipSchema } from "../data/types";
import ReservationCalendar from "./ReservationCalendar";

type StartBookingProps = {
  isMember?: MembershipSchema
  handleClose: () => void
  isOpen?: boolean
  kitchenSpace: KitchenSpaceSchema
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>
};


const StartBooking: React.FC<StartBookingProps> = ({isOpen, kitchenSpace, isMember, setIsSubmitting, handleClose}) => {
  // var { schoolId } = useParams();
  // const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  var { isloggedin } = useAuthState()
  var { currentBooking, updateCurrentBooking } = useMemberState()
  var { bookings } = useLoadCommunityBookings()
  var {  isLoadingBooking, isAddBookingSuccess, isAddBookingError } = useAddBooking()
  // var { currentKitchen } = useCommunity()

  // useEffect(() => { 
  //   // if (loadData !== undefined){loadData()}
  //   // eslint-disable-next-line 
  // }, [])
  
  
  // const getNext30MinuteInterval = (dateTime?: any) => {
  //   dateTime = dateTime ? moment(dateTime) : moment() // 2023-06-07T08:54 vs 2023-06-07T08:10
  //   var minutes = dateTime.minutes()
  //   var remainder = 30 - minutes
  //   var next30MinMark = dateTime.minutes(0)
  //   if (remainder < 0){
  //     next30MinMark = dateTime.minutes(minutes + remainder)
  //     next30MinMark.add(30, 'minutes')
  //   } else if (remainder < 30){
  //     next30MinMark.minutes(minutes + remainder)
  //   }
  //   // eslint-disable-next-line
  // }, []);

  const getNext30MinuteInterval = (dateTime?: any) => {
    dateTime = dateTime ? moment(dateTime) : moment(); // 2023-06-07T08:54 vs 2023-06-07T08:10
    var minutes = dateTime.minutes();
    var remainder = 30 - minutes;
    var next30MinMark = dateTime.minutes(0);
    if (remainder < 0) {
      next30MinMark = dateTime.minutes(minutes + remainder);
      next30MinMark.add(30, "minutes");
    } else if (remainder < 30) {
      next30MinMark.minutes(minutes + remainder);
    }
    return next30MinMark.format("YYYY-MM-DDTHH:mm");
  };

  const handleNHoursChange = (currentStart: Date, currentEnd: Date) => {
    var duration = moment.duration(moment(currentEnd)?.diff(moment(currentStart)))
    // calculate hours diff
    var hoursDiff = duration.asHours()
    if (!isNaN(hoursDiff) && hoursDiff >= 0){
      return hoursDiff
    }
    return 0
  }

  const [startDate, updateStartDate] = useState((currentBooking && currentBooking?.start_time) ? moment(currentBooking.start_time) : getNext30MinuteInterval());
  const [endDate, updateEndDate] = useState((currentBooking && currentBooking?.end_time) ? moment(currentBooking.end_time) : startDate);
  const [nHours, updateNHours] = useState(currentBooking ? handleNHoursChange(startDate, endDate) : 0);

  
  // const [isBookingRequest, toggleBookingRequest] = useState<boolean>(false)
  // const [selectedEquipments, updateSelectedEquipments] = useState<string[]>([]);
  // const [member, setMember] = useState<MembershipSchema>(); // Change the type according to your data type
  // const [memberUsername, setMemberUsername] = useState<string>(""); // Change the type according to your data type
  // const [isExistingMember, setIsExistingMember] = useState<boolean>(true);
  // const isExistingMember = true
  // const [view, setView] = useState<"month" | "week">("week");
  var view =  "week"
  // const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  // const [beforeBuffer, setBeforeBuffer] = useState("");
  var beforeBuffer = "0"
  // const [afterBuffer, setAfterBuffer] = useState("");
  var afterBuffer = "0"
  // const buffers = [15, 30, 45, 60];
  // const [step, setStep] = useState(0);

  const next = () => {
    setCurrentDate(
      view === "month" ? addMonths(currentDate, 1) : addWeeks(currentDate, 4)
    );
  };

  const prev = () => {
    setCurrentDate(
      view === "month" ? addMonths(currentDate, -1) : addWeeks(currentDate, -4)
    );
  };
  // const handleMemberChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  //   const { value } = event.target;
  //   // setMemberUsername(value);
  //   if (value) {
  //     // console.log("value", JSON.parse(value))
  //     setMember(JSON.parse(value));
  //   } else {
  //     // setMember({});
  //   }
  // };

  const handleConfirmBooking = async () => {
    let memberUsername = isMember?.username;
    console.log(isMember)
    console.log(isloggedin + " " + kitchenSpace + " " + memberUsername + " " + startDate + " " + endDate + " " + beforeBuffer + " " + afterBuffer);
    if (
      isloggedin && kitchenSpace && memberUsername &&
      startDate && endDate &&
      endDate > startDate && startDate !== endDate) {
      var newBooking: BookingSchema = {
        has_reminder: false,
        community_id: kitchenSpace.community_id
          ? kitchenSpace.community_id
          : "",
        start_time: moment(startDate)
          .set("minutes", moment(startDate).minutes() - Number(beforeBuffer))
          .format('YYYY-MM-DDTHH:mm')
          .toString(),
        end_time: moment(endDate)
          .set("minutes", moment(endDate).minutes() + Number(afterBuffer))
          .format('YYYY-MM-DDTHH:mm').toString(),
        type: "kitchen",
        username: memberUsername,
        location: kitchenSpace.address
          ? kitchenSpace.address
          : kitchenSpace.country
          ? kitchenSpace.country
          : "",
      };
      console.log("booking: " + newBooking)
      console.log('hello')
      
      updateCurrentBooking(newBooking)
      setIsSubmitting(true)
      
    }
  };

  // const handleDateTimeChange = (
  //   e: React.ChangeEvent<HTMLInputElement>,
  //   dateType: string
  // ) => {
  //   e.preventDefault();
  //   const newDate = e.target.value;
  //   var duration = moment.duration(moment(endDate)?.diff(moment(startDate)));

  //   if (dateType === "startDate" && moment(newDate) >= moment(new Date())) {
  //     // update start time
  //     updateStartDate(getNext30MinuteInterval(newDate));
  //     if (moment(newDate) > moment(endDate)) {
  //       updateEndDate(getNext30MinuteInterval(newDate));
  //     }
  //     duration = moment.duration(
  //       moment(getNext30MinuteInterval(endDate))?.diff(
  //         moment(getNext30MinuteInterval(newDate))
  //       )
  //     );
  //   } else if (dateType === "endDate" && moment(newDate) >= moment(startDate)) {
  //     // update end time
  //     updateEndDate(getNext30MinuteInterval(newDate));
  //     duration = moment.duration(
  //       moment(getNext30MinuteInterval(newDate))?.diff(
  //         moment(getNext30MinuteInterval(startDate))
  //       )
  //     );
  //   }

  //   // calculate hours diff
  //   var hoursDiff = duration.asHours();
  //   if (!isNaN(hoursDiff) && hoursDiff >= 0) {
  //     updateNHours(hoursDiff);
  //   }
  // };



  // Function to handle radio button changes
  // const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   if (event.target.value === "existingMember"){
  //     setIsExistingMember(true);
  //   } else {
  //     setIsExistingMember(false);
  //   }
  // };

  // const handleAfterBufferChange = (
  //   event: React.ChangeEvent<HTMLSelectElement>
  // ) => {
  //   setAfterBuffer(event.target.value);
  // };

  // const handleBeforeBufferChange = (
  //   event: React.ChangeEvent<HTMLSelectElement>
  // ) => {
  //   setBeforeBuffer(event.target.value);
  // };



  if (isLoadingBooking || isAddBookingError){
    return <LoadingScreen height='h-auto' bgColor='inherit' />
  } 
  // else if (isSubmitting) {
  //   return (
  //     <BookingConfirmation
  //       currentBooking={newBookings}
  //       member={isMember}
  //       beforeBuffer={beforeBuffer}
  //       afterBuffer={afterBuffer}
  //       setStep={setStep}
  //       equipment={[]}
  //       isOpen={isOpen}
  //       handleClose={() => setIsSubmitting(false)}
  //     />
  //   )
  // } 
  else {
    if (handleClose && (isOpen && isAddBookingSuccess)){handleClose()}
    return (
      <div className="contain text-center">
        {/* <div className='flex flex-row justify-start mt-5 ml-5'>
          <ArrowBackIosIcon
            className="text-[#5DB6CE] hover:text-gray-500 cursor-pointer"
            onClick={() => handleClose()}
          />
        </div> */}
        
        
        <div className="flex flex-col items-center font-open-sans text-inherit p-6 col-span-1 rounded-md justify-evenly">
          <div className="w-full h-full">
            <div className='w-full mx-auto'>
              <div className="flex flex-col w-full m-1 justify-between p-2">
                <p className='text-md italic text-gray-200'>Select number of hours needed to proceed <span className='text-red-400 italic text-sm'>*</span></p>
                
                  
                <div className="flex flex-row gap-x-5 w-full justify-center mt-6 font-bold text-gray-300">
                  <DownArrow
                    onClick={() => {
                      if (nHours > 0 && moment(startDate) < moment(endDate)) {
                        updateNHours(nHours - 0.5);
                        let newEndDate = moment(endDate);
                        newEndDate.subtract(30, "minutes");
                        updateEndDate(getNext30MinuteInterval(newEndDate));
                      }
                    }}
                    className="cursor-pointer text-primary-300 hover:bg-gray-400  rounded-full hover:rounded-full my-auto"
                  />
                  {/* {nHours.toFixed(1)} */}
                  <input 
                    type="number" min="0" max="10" step="0.5"
                    className="bg-inherit my-auto text-center  cursor-pointer text-primary-300 rounded-lg w-2/7" 
                    value={nHours.toFixed(1)}
                    onChange={e => {
                      e.preventDefault()
                      let {value} = e.target
                      let intE = parseFloat(value)
                      if ((intE === 0 || intE)){
                        updateNHours(intE)
                      }
                    }}
                     />
                  <UpArrow
                    onClick={() => {
                      if (nHours < 24) {
                        updateNHours(nHours + 0.5);
                        let newEndDate = moment(endDate);
                        newEndDate.add(30, "minutes");
                        updateEndDate(getNext30MinuteInterval(newEndDate));
                      }
                    }}
                    className="cursor-pointer text-primary-300 hover:bg-gray-400  rounded-full hover:rounded-full my-auto"
                  />
                </div>
              </div>
            
              
            {(nHours && nHours > 0) ? (
              <div className="flex flex-col w-full mx-auto justify-between p-2">
                <p className='text-md italic text-gray-200'>Choose reservation date and time <span className='text-red-400 italic text-sm'>*</span></p>
  
                <h2 className="text-lg font-semibold text-center mt-10 mb-5 text-primary-400">
                  {view === "month"
                    ? format(currentDate, "MMMM yyyy")
                    : `${format(startOfWeek(currentDate), " MMMM yyyy")}`}
                </h2>
  
                <div className="mb-4 flex justify-between items-center text-slate-400">
                  <button onClick={prev}>Previous</button>
                  <button onClick={next}>Next</button>
                </div>

                <div className="flex justify-center items-center">
                  <ReservationCalendar
                    currentDate={currentDate}
                    bookings={bookings}
                    nHours={nHours}
                    updateStartTime={updateStartDate}
                    updateEndTime={updateEndDate}
                    handleConfirmBooking={handleConfirmBooking}
                    currentKitchen={kitchenSpace}
                  />
                </div> 
              </div>
            ) : (<></>)}
            </div>
          </div>

          <div className={`flex flex-row gap-10`}>
            <button
              className={`bg-red-900 p-2 text-gray-200/80 text-sm  hover:bg-back-300 rounded-lg shadow-xl mt-6`}
              onClick={() => handleClose()}
            >
              Cancel Request
            </button>
          </div>
        </div>
      </div>
    );
  }

};

export const useDate = () => {
  const locale = "en";
  const [today, setDate] = React.useState(new Date()); // Save the current date to be able to trigger an update

  React.useEffect(() => {
    const timer = setInterval(() => {
      // Creates an interval which will update the current data every minute
      // This will trigger a rerender every component that uses the useDate hook.
      setDate(new Date());
    }, 60 * 1000);
    return () => {
      clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
    };
  }, []);

  const day = today.toLocaleDateString(locale, { weekday: "long" });
  const date = `${day}, ${today.getDate()} ${today.toLocaleDateString(locale, {
    month: "long",
  })}\n\n`;

  const hour = today.getHours();
  const wish = `Good ${
    (hour < 12 && "Morning") || (hour < 17 && "Afternoon") || "Evening"
  }, `;

  const time = today.toLocaleTimeString(locale, {
    hour: "numeric",
    hour12: true,
    minute: "numeric",
  });

  return {
    date,
    time,
    wish,
  };
};
export default StartBooking;
