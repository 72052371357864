import React from "react";
import HomeCookRouter from "./routers";
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <HomeCookRouter />
      </div>
    </QueryClientProvider>
  );
}

export default App;