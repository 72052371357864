import moment from "moment";

import { MembershipSchema } from '../data/types'
import { ReactComponent as VisaCardIcon } from '../icons/visaCardIcon.svg'
import { ReactComponent as MasterCardIcon } from '../icons/masterCardIcon.svg'
import { ReactComponent as AmexCardIcon } from '../icons/amexCardIcon.svg'


const getDayName = (dateString: string): string => {
  // Parse the date string
  const date = moment(dateString, 'YYYY-MM-DD');
  // Get the day name
  return date.format('dddd');
}


const getDayOfMonth = (dateString: string): number  => {
  // Parse the date string
  const date = moment(dateString, 'YYYY-MM-DD');
  // Get the day of the month
  return date.date();
}


const getDayOfMonthWithSuffix = (dateString: string): string => {
  // Parse the date string
  const date = moment(dateString, 'YYYY-MM-DD');
  // Get the day of the month with ordinal suffix
  return date.format('Do');
}


const addDaysToToday = (days: number): string => {
  // Get today's date and add the specified number of days
  const newDate = moment().add(days, 'days');
  // Format the new date as YYYY-MM-DD
  return newDate.format('YYYY-MM-DD');
}

const calculateDateDifference = (fromDate: string, toDate: string, booking_recurrence: string) => {
  var nOccurrences = 0
  if (fromDate && toDate){
    // Parse the dates
    const start = moment(fromDate, 'YYYY-MM-DD');
    const end = moment(toDate, 'YYYY-MM-DD');
    switch(booking_recurrence){
      case 'daily':
        nOccurrences = end.diff(start, 'days') + 1;
        return nOccurrences
      case 'weekly':
        nOccurrences = end.diff(start, 'weeks');
        let startDay = getDayOfMonth(fromDate)
        let endDay = getDayOfMonth(toDate)
        if (endDay >= startDay){
          nOccurrences += 1
        }
        return nOccurrences
      case 'monthly':
        nOccurrences = end.diff(start, 'months') + 1;
        return nOccurrences
      default:
        return 1
    }
  }
  return nOccurrences
}


const maxHours = (capacity: number, recurrence: string | 'daily' | 'weekly' | 'monthly' | 'annual',  hours_left: number) => {
  if (recurrence && capacity){
    switch (recurrence){
      case 'daily':
        // 24 hrs
        hours_left = hours_left ? hours_left : Math.round(16 / capacity)
        return hours_left
      case 'weekly':
        // 168 hrs
        hours_left = hours_left ? hours_left : Math.round(115 / capacity)
        return hours_left
      case 'monthly':
        // 730 hrs
        hours_left = hours_left ? hours_left : Math.round(500 / capacity)
        return hours_left
      case 'yearly':
        // 8760 hrs
        hours_left = hours_left ? hours_left : Math.round(6000 / capacity)
        return hours_left
      default:
        return 0
    }
  }
  return 0
}


const objectToFormData = (obj: { [key: string]: any }): FormData =>  {
  const formData = new FormData();
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      formData.append(key, value);
    }
  }
  return formData;
}

 // Define a function to find an object by attribute value
 const findObjectByAttribute = (objects: MembershipSchema[], attribute: keyof MembershipSchema, value: any) => {
  for (const obj of objects) {
      if (obj[attribute] === value) {
          return obj;
      }
  }
  return {};
}

const getMembershipStatusColor = (status: string): {bgColor: string, primaryTextColor: string, secondaryTextColor: string} => {
  if (status) {
    status = status.toLowerCase();
    switch (status) {
      case "active":
        return {bgColor: "#b9dc8d", primaryTextColor: '#4a5838', secondaryTextColor: '#819a62'};
      case "approved":
        return {bgColor: "#399f48", primaryTextColor: '#163f1c', secondaryTextColor: '#afd8b5'};
      case "verified":
        return {bgColor: "#F69E35", primaryTextColor: '#623f15', secondaryTextColor: '#fce1c2'};
      case "pending":
        return {bgColor: "#51a0b5", primaryTextColor: '#204048', secondaryTextColor: '#a8cfda'};
      case "declined":
        return {bgColor: "#CE5D5D", primaryTextColor: '#522525', secondaryTextColor: '#e6aeae'};
      case "inactive":
        return {bgColor: "#9a9a9a", primaryTextColor: '#3d3d3d', secondaryTextColor: '#d6d6d6'};
      // case "removed":
      //   return {bgColor: "#bbb", primaryTextColor: '#202124', secondaryTextColor: '#696969'};
      default:
        return {bgColor: "#bbb", primaryTextColor: '#202124', secondaryTextColor: '#696969'};
    }
  }
  return {bgColor: "#bbb", primaryTextColor: '#202124', secondaryTextColor: '#696969'};
};

// pending, declined, approved, verified, active, inactive, removed


const getBookingStatusColor = (status: string) => {
  if (status) {
    status = status.toLowerCase();
    switch (status) {
      case "in progress":
        return "#F69E35";
      case "approved":
        return "#9CCE5D";
      case "pending":
        return "#51a0b5";
      case "canceled":
        return "#CE5D5D";
      case "completed":
        return "#013847";
      default:
        return "38b2ac";
    }
  }
  return "#38b2ac"
};


const lightenColor = (hex: string, percent: number): string => {
  const color = parseInt(hex.replace(/^#/, ''), 16);
  const R = ((color >> 16) + percent);
  const G = (((color >> 8) & 0x00FF) + percent);
  const B = ((color & 0x0000FF) + percent);
  const newColor = ((R << 16) | (G << 8) | B).toString(16);
  return `#${newColor.padStart(6, '0')}`;
}


const darkenColor = (hex: string, percent: number): string => {
  const color = parseInt(hex.replace(/^#/, ''), 16);
  const R = ((color >> 16) - percent);
  const G = (((color >> 8) & 0x00FF) - percent);
  const B = ((color & 0x0000FF) - percent);
  const newColor = ((R << 16) | (G << 8) | B).toString(16);
  return `#${newColor.padStart(6, '0')}`;
}


const servSafeLookUp: {
  requirementCode: string;
  requirementName: string;
}[] = [
  {
    requirementCode: "FMP",
    requirementName: "Foodservice Management Professional Certification",
  },
  { requirementCode: "MFRST", requirementName: "ManageFirst" },
  {
    requirementCode: "CLNRY",
    requirementName: "National Restaurant Association Culinary Exams",
  },
  { requirementCode: "PSTRT", requirementName: "ProStart" },
  { requirementCode: "SSA", requirementName: "ServSafe Alcohol" },
  {
    requirementCode: "SSALRGNS",
    requirementName: "ServSafe Allergens Online Course",
  },
  { requirementCode: "SSFHNDLR", requirementName: "ServSafe Food Handler" },
  {
    requirementCode: "SSPRC",
    requirementName: "ServSafe Instructor and Proctor Certificates",
  },
  {
    requirementCode: "SSI",
    requirementName: "ServSafe International Food Safety",
  },
  {
    requirementCode: "PIC Ohio",
    requirementName: "ServSafe Person in Charge – Ohio",
  },
  { requirementCode: "SSWP", requirementName: "ServSafe Workplace" },
  {
    requirementCode: "SSMGR",
    requirementName: "ServSafe: Food Protection Manager Certification",
  },
  { requirementCode: "SMMR", requirementName: "Summer Institutes" },
  { requirementCode: "TXFG", requirementName: "Texas FoodGuard" },
  { requirementCode: "ID", requirementName: "Government ID" },
  { requirementCode: "PID", requirementName: "Photo ID" },
  { requirementCode: "GLI", requirementName: "General Liability Insurance" },
  { requirementCode: "HACCP", requirementName: "HACCP Certificate" },
];


type AnyObject = Record<string, any>; // A type for any object with string keys

const getChangedAttributes = <T extends AnyObject>(
  originalObj: T,
  newObj: T
): T => {
  const changedAttributes: T = {} as T;

  for (const key in originalObj) {
    if (originalObj.hasOwnProperty(key)) {
      if (newObj.hasOwnProperty(key) && newObj[key] !== originalObj[key]) {
        changedAttributes[key] = newObj[key];
      }
    }
  }

  return changedAttributes;
}

const styleThousandCommas = (number: string | number) => {
  if (typeof number === 'number'){
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  } else if (typeof number === 'string'){
    return number.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }
  return number
}

const getCreditCardIcon = (paymentMethod: string) => {
  switch (paymentMethod) {
    case 'visa':
      return VisaCardIcon
    case 'mastercard':
      return MasterCardIcon
    case 'amex':
      return AmexCardIcon
    // Add cases for other card types
    default:
      return null
  }
};

const capitalizeString = (string: string) => string?.replace(/\w/, (c) => c?.toUpperCase());

export {
  servSafeLookUp,
  addDaysToToday,
  getDayName,
  getDayOfMonth,
  getDayOfMonthWithSuffix,
  calculateDateDifference,
  maxHours,
  capitalizeString,
  getCreditCardIcon,
  findObjectByAttribute,
  objectToFormData,
  lightenColor,
  darkenColor,
  styleThousandCommas,
  getBookingStatusColor,
  getMembershipStatusColor,
  getChangedAttributes
}