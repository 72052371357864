import React, { useState, useEffect } from "react";

import homecook_logo from '../../../assets/img/homecook_logo.png'


import { AccountSchema, LoginProps } from "../../../data/types";



const EmailSignUp: React.FC<LoginProps> = props => {
    useEffect(()=> {
        document.title = 'Signup | HomeCook'
     }, [])

  
    const [account, updateAccount] = useState<AccountSchema>({})
    // const [email, updateEmail] = useState('')


    let { registerUser, toggleSignIn }: LoginProps = props

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { name, value } = e.target
        e.preventDefault()
        updateAccount((prevAccount) => ({
            ...prevAccount,
            [name]: value,
        }))

        // if (name === 'email' && value){
        //     e.preventDefault()
        //     updateEmail(value)
        // }
    }

    const goToOTP = async () => {
        if (account && account.email && registerUser !== undefined){
            // let phone = ""
            var user_to_register: AccountSchema = account
            // console.log("email", account.email)
            registerUser(user_to_register)
        }
    }

    return (
        <div className='flex w-full mx-auto p-auto items-start justify-evenly h-[900px] sm:h-screen overflow-y-scroll bg-[#D9D9D9] dark:bg-[#013847]'>
            <div className="relative mx-auto">
                <div className="flex flex-col justify-evenly align-middle w-[320px] mx-auto">
                    <img src={homecook_logo} alt="HomeCook" className='w-[100px] mx-auto cursor-pointer' onClick={()=> null} />
                    <h4 className=" text-center m-[1%] text-[20px] text-[#2D3748] dark:text-[#E1E1E1]">New to HomeCook?</h4>
                    <p className="text-[16px] text-[#a9b2c0] text-center">Create account</p>
                    <div className="flex-row w-[100%]">
                   
                      <input placeholder="First Name" value={account.first_name} className="h-[50px] w-[98%] m-[1%] bg-transparent p-2 rounded-md border-2 border-[#013847] text-[#000000] dark:border-[#D9D9D9] dark:text-[#FFFFFF] text-start" name="first_name" type="text" onChange={handleChange} />
                      <input placeholder="Last Name" value={account.last_name} className="h-[50px] w-[98%] m-[1%] bg-transparent p-2 rounded-md border-2 border-[#013847] text-[#000000] dark:border-[#D9D9D9] dark:text-[#FFFFFF] text-start" name="last_name" type="text" onChange={handleChange} />
                      <input placeholder="Email Address" value={account.email} className='h-[50px] w-[98%] m-[1%] bg-transparent p-2 rounded-md border-2 border-[#013847] text-[#000000] dark:border-[#D9D9D9] dark:text-[#FFFFFF] text-start' name="email" type="email" onChange={handleChange} onKeyDown={e => (e.key === 'Enter' && account.email) ? goToOTP() : null } />
                    </div>

                    <button className="bg-[#5DB6CE] text-[#013847] dark:bg-[#E1E1E1] m-3 border-0 font-open-sans" disabled={!(account && account.email)}  onClick={() => goToOTP()}>SIGN UP</button>

                    <p className="text-[12px] text-[#a9b2c0] dark:text-[#E1E1E1] text-center font-extralight cursor-pointer hover:text-[#5DB6CE]" onClick={toggleSignIn}><span className="text-[#013847]">Already have an Account? </span>Sign in</p>

                </div>
           </div>
        </div>
    )
}






export default EmailSignUp
