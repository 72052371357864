import React from 'react'

import { useNavigate } from 'react-router-dom';

// import KitchenIcon from '@mui/icons-material/Kitchen';
import KitchenIcon from '@mui/icons-material/KitchenOutlined';

// import { useAuthState } from '../contexts/authcontext'
import { useMemberState } from '../contexts/membercontext'

import { ReactComponent as CommunityIcon } from '../icons/Community.svg'

import { CommunityCardProps } from "../data/types";
import { getMembershipStatusColor, findObjectByAttribute } from "../utils/helpers";



const CommunityCard: React.FC<CommunityCardProps> = ({community, allMemberships}) => {
    // const [showLogo, updateShowLogo] = useState(true) 
    const navigate = useNavigate();
    var { memberships } = useMemberState()

    const getBgColor  = () => {
      // console.log("memberships", memberships)
      // console.log("allMemberships", allMemberships)
      let memberStatus = findObjectByAttribute(memberships, "community_id", community?.id)?.status
      return memberStatus ? getMembershipStatusColor(memberStatus).bgColor : "#bbb"
    }

    const getPrimaryTextColor  = () => {
      // console.log("memberships", memberships)
      // console.log("allMemberships", allMemberships)
      let memberStatus = findObjectByAttribute(memberships, "community_id", community?.id)?.status
      return memberStatus ? getMembershipStatusColor(memberStatus).primaryTextColor : "#202124"
    }

    const getSecondaryTextColor  = () => {
      // console.log("memberships", memberships)
      // console.log("allMemberships", allMemberships)
      let memberStatus = findObjectByAttribute(memberships, "community_id", community?.id)?.status
      return memberStatus ? getMembershipStatusColor(memberStatus).secondaryTextColor : "#696969"
    }

    
    // var { isloggedin, handleUpdateLoginType } = useAuthState()
    // var { getCommunity } = useCommunity()

    // const handleUnAuthClick = () => {
    //   if (handleUpdateLoginType !== undefined){
    //     handleUpdateLoginType('email')
    //     navigate(`/login`)
    //   }
    // }

    // const handleAuthClick = async (communityId: string) => {
    //   if (communityId && getCommunity!== undefined){
    //     getCommunity(communityId)
    //     navigate(`/community/${communityId}`)
    //   }
    // }

    const handleCardClick = async (communityId: string) => {
      if (communityId){
        // getCommunity(communityId)
        navigate(`/community/${communityId}`)
      }
    }



    return (
              <div className={`flex flex-row h-[220px] sm:h-[224px] sm:w-[419px] object-cover rounded-xl border-0  shadow-sm items-center text-start p-4 cursor-pointer hover:scale-95 duration-1000`} 
                   style={{backgroundColor: getBgColor(), opacity: 0.7}} 
                    onClick={() => (community.id) ? handleCardClick(community?.id) : null}>
                  {/* <div className='h-[60px] w-[60px] rounded-full bg-orange-400/60 my-4'></div> */}
                  
                  {
                    community?.logo ? (
                      <img className=' w-1/3 object-contain m-auto rounded-lg' src={community.logo} alt={community?.id} /> 
                      ) : (
                        // <img className='relative m-4 w-1/3 p-1 bg-slate-100 dark:bg-slate-500 rounded-full text-[#5DB6CE]' alt={community?.id} src={Community} />
                        <CommunityIcon className={'relative m-4 aspect-square p-1 bg-slate-100 dark:bg-slate-500 rounded-full text-[#5DB6CE]/60 dark:text-[#eef7fa]'} />
                        )
                  }
                  {/*  */}
                  <div className='flex flex-col w-2/3 m-2'>
                      <p 
                        className='font-raleway font-bold px-2 text-md'
                        style={{color: getPrimaryTextColor()}}
                      >
                        {community.name}
                      </p>
                      <p className='px-2 font-open-sans text-sm font-medium' style={{color: getSecondaryTextColor(), opacity: 0.8}}>
                        {community && community.address ? `${community.address}, `: ``} 
                        {community && community.city ? `${community.city}, `: ``} 
                        {community && community.state ? `${community.state} `: ``}  
                        {community && community.zipcode ? `${community.zipcode} `: ``}  
                      </p>
                      <p style={{color: getPrimaryTextColor(), opacity: 0.8}} className='p-1 font-open-sans text-sm text-[#224f5c] font-bold mt-1'>
                        <KitchenIcon className='m-auto' />&nbsp;
                        <span className='m-auto'>{(community?.spaces && community?.spaces?.length > 0)? community?.spaces?.length : 'No'} {(community?.spaces && community?.spaces?.length < 2)? "Kitchen" :"Kitchens"}</span>
                        
                      </p>
                  </div>
              </div>
    )
  }




export default CommunityCard