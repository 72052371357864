import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import ErrorIcon from "@mui/icons-material/Error";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { ReactComponent as CredentialIcon } from "../../icons/credentialIcon.svg";
import { ReactComponent as PaymentIcon } from "../../icons/PaymentIcon.svg";
import { useAddCredential, useGetCredentials } from "../../hooks/memberhooks";
import { CommunitySchema, CredentialSchema, MembershipSchema } from "../../data/types"
import { useAuthState } from '../../contexts/authcontext';
import { CredentialDetailsModal } from "../../components/AppModal";
import LoadingScreen from "../../pages/shared/LoadingScreen"
import { useGetPaymentMethods } from '../../hooks/memberhooks'


type RequirementsChecklistProps = {
  // isLoadingCredentials: boolean
  currentMember: MembershipSchema
  currentCommunity: CommunitySchema
}


const RequirementsChecklist: React.FC<RequirementsChecklistProps> = ({currentMember, currentCommunity}) => {
  const navigate = useNavigate();
  var { authUser } = useAuthState()
  const emptyCredential: CredentialSchema = {};
  const [viewCredential, setViewCredential] = useState<CredentialSchema>(emptyCredential);

  const { addMemberCredential, isLoadingCredential  } = useAddCredential(currentCommunity?.id ? currentCommunity.id : "")
  const { memberCredentials, isLoadingCredentials } = useGetCredentials(currentCommunity?.id ? currentCommunity.id : "", authUser?.username ? authUser.username : "")

  var {paymentMethods} = useGetPaymentMethods()

  var communityRequirements = currentCommunity?.requirements ? currentCommunity.requirements : []
  const pay_requirements_length = (currentMember && currentMember?.plan_recurrence && !['hourly', 'daily'].includes(currentMember.plan_recurrence) && (communityRequirements?.length || communityRequirements?.length === 0)) ? communityRequirements.length + 1 : communityRequirements?.length

  const percentRequirementsMet = () => {
    let count = 0;
    const attributeSet = new Set<any>();
    if (communityRequirements && communityRequirements.length > 0){
      // Loop through requirements and add unique attribute values to a set
      for (const req of communityRequirements) {
        attributeSet.add(req['name']);
      }
    }

    if (memberCredentials){
      // Loop through memberCredentials and check for matches in the set
      for (const cred of memberCredentials) {
        if (attributeSet.has(cred['category']) && cred.is_valid) {
          count++;
          attributeSet.delete(cred['category']); // Avoid double counting duplicates within list2
        }
      }
    }

    if (currentMember && currentMember?.plan_recurrence && !['hourly', 'daily'].includes(currentMember.plan_recurrence) && (communityRequirements?.length || communityRequirements?.length === 0)){
      if (paymentMethods && paymentMethods.length > 0){
        count++;
      }
    }

    return pay_requirements_length ? (count / pay_requirements_length) * 100 : 0
  }

  const requirementsMet = percentRequirementsMet()
  // const capitalizeString = (string: string) => string?.replace(/\w/, (c) => c?.toUpperCase());

  const handleSubmitCredential = (credential: CredentialSchema) => {
    if (authUser?.username && credential) {
      credential.username = authUser.username;
      addMemberCredential(authUser.username, credential);
    }
  }

  const getCurrentCredential = (requirement: string) => {
    let credential = memberCredentials?.find(credential => credential.category === requirement)
    return credential ? credential : {} as CredentialSchema
  }
  
  const meetsRequirement = (requirement: string) => {
    let credential = memberCredentials?.find(credential => credential.category === requirement)
    return credential ? credential.is_valid : false
  }
  
  const [credentialModalIsOpen, setCredentialModalIsOpen] = useState<{
    addModal: boolean;
    viewModal: boolean;
  }>({ addModal: false, viewModal: false });
  
  return (
    <>
    <p className='font-raleway font-bold text-[#000] text-md'>Membership Requirements (<span className={`${requirementsMet < 80 ? 'text-red-950/70' : 'text-green-800/70'}`}>{Math.round(requirementsMet)}% Complete</span>)</p>
    <div className="flex flex-col justify-start text-left items-center mr-auto font-bold">
      {/* <span className="text-md text-[#013847]/60">REQUIREMENTS MET - <span className={`${requirementsMet < 80 ? 'text-red-950/70' : 'text-green-800/70'} text-xl font-bold`}>{requirementsMet}%</span></span> */}
      {
        (isLoadingCredentials) ? ( 
          <div className="flex flex-col items-start my-5 mx-auto w-[80%] h-full">
            <LoadingScreen bgColor="inherit" height="h-full" />
          </div> 
        ) : (
          <div className="flex flex-col items-start mx-auto w-9/10 overflow-y-scroll font-raleway text-sm mr-auto list-disc text-black/70">
            {
              (currentMember && currentMember?.plan_recurrence && !['hourly', 'daily'].includes(currentMember.plan_recurrence)) ? (
              <>
                <div
                  className={`flex flex-row my-1 justify-start w-full ${
                    false
                      ? "hover:bg-[#eef7fa]/80 hover:text-[#013847]/70"
                      : "hover:bg-red-50/50 hover:text-red-950/70"
                  } cursor-pointer rounded-md p-2`}

                  // onClick={() => {
                  //   setViewCredential(getCurrentCredential("Payment Method"));
                  //   setCredentialModalIsOpen({
                  //     addModal: false,
                  //     viewModal: true,
                  //   });
                  // }}
                  onClick={()=> navigate('/settings/payment')}
                  
                >
                  
                  <PaymentIcon className="my-auto h-4 w-4" />
                  <div className="flex flex-col mx-4 my-auto w-full">
                    
                    <p className="text-[#555] font-open-sans font-medium text-sm text-center sm:text-left">
                      {'Payment Method'.toUpperCase()}
                    </p>
                  </div>

                  {
                    (paymentMethods && paymentMethods.length > 0) ? (
                      <CheckCircleIcon style={{fontSize: 20}} color="success" className="my-auto ml-auto mx-1" />
                    ) : (
                      (getCurrentCredential('Payment Method').id) ? (
                          <ReportProblemIcon color="warning" style={{fontSize: 20}}  className="my-auto ml-auto mx-1" />
                      ) : (
                          <ErrorIcon color="error" style={{fontSize: 20}}  className="my-auto ml-auto mx-1" />
                      )
                    )
                  }
                  
            
                </div>
              </>
              ) : (<></>)
            }
            {
              communityRequirements && communityRequirements.length > 0 ? (
                communityRequirements?.map((requirement, i) => {
                  return (
                  
                    <div
                      key={i}
                      className={`flex flex-row my-1 justify-start w-full ${
                        false
                          ? "hover:bg-[#eef7fa]/80 hover:text-[#013847]/70"
                          : "hover:bg-red-50/50 hover:text-red-950/70"
                      } cursor-pointer rounded-md p-2`}

                      onClick={() => {
                        setViewCredential(getCurrentCredential(requirement?.name ? requirement?.name : ""));
                        setCredentialModalIsOpen({
                          addModal: false,
                          viewModal: true,
                        });
                      }}
                      
                    >
                      
                      <CredentialIcon className="my-auto h-4 w-4" />
                      <div className="flex flex-col mx-4 my-auto w-full">
                        
                        <p className="text-[#555] font-open-sans font-medium text-sm text-center sm:text-left">
                          {/* {capitalizeString(
                            requirement?.name ? requirement?.name : ""
                          )} */}
                          {requirement?.name.toUpperCase()}
                        </p>
                      </div>

                      {
                        (requirement && requirement?.name && meetsRequirement(requirement?.name)) ? (
                          <CheckCircleIcon style={{fontSize: 20}} color="success" className="my-auto ml-auto mx-1" />
                        ) : (
                          (requirement?.name && getCurrentCredential(requirement?.name).id) ? (
                              <ReportProblemIcon color="warning" style={{fontSize: 20}}  className="my-auto ml-auto mx-1" />
                          ) : (
                              <ErrorIcon color="error" style={{fontSize: 20}}  className="my-auto ml-auto mx-1" />
                          )
                        )
                      }
                      
                
                    </div>
                    
                
                  );
                })
              ) : (
                <>No Membership Requirements</>
              )
            }
            {
              (isLoadingCredential) ? ( 
                <div className="flex flex-col items-start my-5 mx-auto w-[80%] h-full">
                  <LoadingScreen bgColor="inherit" height="h-full" />
                </div> 
              ) : (<></>)
            }
          </div>
        )
      }

      <CredentialDetailsModal
        credential={viewCredential}
        isOpen={credentialModalIsOpen.viewModal}
        closeAppModal={() =>
          setCredentialModalIsOpen({
            addModal: false,
            viewModal: false,
          })
        }
        handleUpdateCredential={handleSubmitCredential}
      />
    </div>
    </>
  )
}


export default RequirementsChecklist