import { useQuery, useMutation} from '@tanstack/react-query'
import { 
        fetchMemberships,
        // getMembershipRequests, 
        addPaymentSetupIntent,
        addPayIntent,
        fetchPaymentMethods,
        getMembership, 
        addCommunityMember,
        updateCommunityMembership, 
        getMemberReviews,
        addMembershipCredentials, 
        fetchMemberCredentials,
        createBooking, 
        updateBookingData,
        fetchMemberBookings, 
        fetchBookingByID, 
        getAllMessages,
        sendMessageToUser, 
        generateAIResponse
     } from '../api/crud'
import { MembershipSchema, BookingSchema, MessageSchema, GroupedMessageListType, CredentialSchema } from "../data/types";

import { useMemberState } from '../contexts/membercontext'
import { useAuthState } from '../contexts/authcontext'

import { useLoadCurrentCommunity } from '../hooks/communityhooks'


const staleTime = 1000
const isEnabled = true

// export const useLoadRequests = () => {
//     let { communityId, setMembershipRequests } = useMemberState()
//     const {data: membershipRequests, refetch: reloadRequests, isLoading: isLoadingRequests} = useQuery({ 
//         queryKey: ['membershipRequests', communityId], 
//         queryFn: async () => {
//             if (communityId){
//                 console.log("fetching membership requests")
//                 const res = await getMembershipRequests(communityId)
//                 setMembershipRequests(res)
//                 return res
//             }
//             return []
//         },
//         staleTime,
//         enabled: isEnabled
//     })
//     return {isLoadingRequests, membershipRequests, reloadRequests}
// }

export const useGetPaymentMethods = () => {
    var {authUser}  = useAuthState()
    let memberUsername = authUser?.username ? authUser.username : ''
    const {data: paymentMethods, refetch: reloadPaymentMethods, isLoading: isLoadingPaymentMethods} = useQuery({ 
        queryKey: ['payMethods', memberUsername], 
        queryFn: async () => {
            console.log("fetching payMethods")
            const res = await fetchPaymentMethods(memberUsername)
            // console.log("fetching payMethods res: ", res)
            return res ? res : []
        },
        staleTime,
        enabled: isEnabled
    })

    const getPaymentMethod = (booking: BookingSchema) => {
        let paymentMethodId = booking.payment_method
        return paymentMethods.find((obj: any) => obj['id'] === paymentMethodId);

    }

    return { isLoadingPaymentMethods, paymentMethods, getPaymentMethod, reloadPaymentMethods}
}


export const useAddPaymentIntent = () => {

    var {authUser}  = useAuthState()

    const { reloadPaymentMethods } = useGetPaymentMethods()
    let memberUsername = authUser?.username ? authUser.username : ''

    const mutation = useMutation({ 
        mutationFn: async (amount: number) => {
            console.log("add payment intent")
            const res = await addPayIntent(memberUsername, amount)
            console.log("addPaymentIntent res: ", res)
            const {client_secret: clientSecret} = res
            // console.log("clientSecret: ", clientSecret)
            await reloadPaymentMethods()
            return clientSecret
        },
        // cacheTime: 10000
    })

    const addPaymentIntent = (amount: number) => {
        mutation.mutate(amount)
        return mutation?.data ? mutation.data : ''
    }

    const isLoadingSetupIntent = mutation.isPending
    return { addPaymentIntent, isLoadingSetupIntent }
}


export const useAddPaySetupIntent = () => {

    var {authUser}  = useAuthState()

    const { reloadPaymentMethods } = useGetPaymentMethods()
    let memberUsername = authUser?.username ? authUser.username : ''

    const mutation = useMutation({ 
        mutationFn: async () => {
            console.log("add setup intent")
            const res = await addPaymentSetupIntent(memberUsername)
            console.log("addPaymentSetupIntent res: ", res)
            const {client_secret: clientSecret} = res
            // console.log("clientSecret: ", clientSecret)
            await reloadPaymentMethods()
            return clientSecret
        },
        // cacheTime: 10000
    })

    const addSetupIntent = () => {
        mutation.mutate()
        return mutation?.data ? mutation.data : ''
    }

    const isLoadingSetupIntent = mutation.isPending
    return { addSetupIntent, isLoadingSetupIntent }
}


export const useLoadMemberships = () => {
    var {authUser}  = useAuthState()
    let { setMemberships } = useMemberState()
    const {data: memberships, refetch: reloadMemberships, isLoading: isLoadingMemberships} = useQuery({ 
        queryKey: ['memberships', authUser?.username], 
        queryFn: async () => {
            if (authUser?.username){
                console.log("fetching all memberships")
                const res = await fetchMemberships(authUser.username)
                setMemberships(res)
                return res
            }
            return []
        },
        staleTime,
        enabled: isEnabled
    })
    return {isLoadingMemberships, memberships, reloadMemberships}
}


export const useLoadMember = (communityId: string) => {
    let { updateCurrentMember } = useMemberState()
    const { authUser } = useAuthState();
    const username = authUser?.username ? authUser.username : ""
    const {data: currentMember, isLoading: isLoadingCurrentMember, refetch: reloadCurrentMember} = useQuery({ 
        queryKey: ['currentMember', communityId, username], 
        queryFn: async () => {
            console.log("fetching membership by username")
            const res = await getMembership(communityId, username)
            updateCurrentMember(res)
            return res
        }
    })
    return {isLoadingCurrentMember, currentMember, reloadCurrentMember}
}


export const useAddMembership = (communityId: string) => {
    // let { communityId } = useMemberState()
    const { reloadMemberships } = useLoadMemberships()
    const { updateAuthUser, updateLogin, updateVerified } = useAuthState()
    // const { reloadRequests } = useLoadRequests()

    const mutation = useMutation({ 
        mutationFn: async (membership: MembershipSchema) => {
            console.log("add member")
            const res = await addCommunityMember(communityId, membership)
            if (res && Object.keys(res).length !== 0 && updateAuthUser && updateLogin && updateVerified){
                if (res?.login_token && res?.refresh_token){
                    localStorage.setItem('access_token', res.login_token)
                    localStorage.setItem('refresh_token', res.refresh_token)
                }
                updateAuthUser(res)
                updateLogin(true)
                if (res.auth_status){
                    updateVerified(res.auth_status) 
                }
            }
            await reloadMemberships()
            // await reloadRequests()
            return res
    }})

    const addMembership = (membershipUpdate: MembershipSchema) => {
        mutation.mutate(membershipUpdate) 
        return mutation.isSuccess
    }

    const isLoadingMembership = mutation.isPending
    const isAddMembershipError = mutation.isError
    const isAddMembershipIdle = mutation.isIdle
    const isAddMembershipSuccess = mutation.isSuccess
    return { addMembership, isLoadingMembership, isAddMembershipSuccess, isAddMembershipError, isAddMembershipIdle }
}


export const useUpdateMembership = (communityId: string) => {
    // var { communityId } = useMemberState()
    const { reloadCurrentKitchen } = useLoadCurrentCommunity(communityId)
    // const { reloadRequests } = useLoadRequests()

    const mutation = useMutation({ 
        mutationFn: async (updateData: MembershipSchema) => {
            console.log("update member")
            const res = await updateCommunityMembership(communityId, updateData)
            console.log("update member res", res)
            await reloadCurrentKitchen()
            return res
    }})

    const updateMembership = (membershipUpdate: MembershipSchema) => {
        mutation.mutate(membershipUpdate)
        return mutation?.isSuccess
    }

    const isLoadingMembership = mutation.isPending
    return { updateMembership, isLoadingMembership }
}


export const useAddCredential = (communityId: string) => {
    // var {  } = useMemberState()
    type mutationProps = {
        username: string
        credential: CredentialSchema
    }
    const mutation = useMutation({ 
        mutationFn: async ({username, credential}: mutationProps) => {
            console.log("add member credentials")
            const res = await addMembershipCredentials(communityId, username, credential)
            return res
    }})

    const addMemberCredential = (username: string, credential: CredentialSchema) => {
        mutation.mutate({username, credential})
        return mutation?.data ? true : false
    }

    const isLoadingCredential = mutation.isPending
    return { addMemberCredential, isLoadingCredential }
}


export const useGetCredentials = (communityId: string, memberUsername: string) => {
    // var { communityId } = useMemberState()

    const {data: memberCredentials, refetch: reloadCredentials, isLoading: isLoadingCredentials} = useQuery({ 
        queryKey: ['credentials', communityId, memberUsername], 
        queryFn: async () => {
            console.log("fetching credentials")
            const res = await fetchMemberCredentials(communityId, memberUsername)
            return res
        },
        staleTime,
        enabled: isEnabled
    })

    return { isLoadingCredentials, memberCredentials, reloadCredentials}
}


export const useGetMemberReviews = (memberUsername: string) => {
    const {data: memberReviews, refetch: reloadReviews, isLoading: isLoadingReviews} = useQuery({ 
        queryKey: ['reviews', memberUsername], 
        queryFn: async () => {
            console.log("fetching member reviews")
            const res = await getMemberReviews(memberUsername)
            return res
        },
        staleTime,
        enabled: isEnabled
    })

    return { isLoadingReviews, memberReviews, reloadReviews}
}


export const useLoadBookings = () => {
    var {authUser}  = useAuthState()
    let { updateBookings } = useMemberState()
    const {data: bookings, refetch: reloadBookings, isLoading: isLoadingBookings} = useQuery({ 
        queryKey: ['bookings', authUser?.username], 
        queryFn: async () => {
            console.log("fetching bookings")
            const res = await fetchMemberBookings(authUser?.username ? authUser.username : "")
            if (res){
                updateBookings(res)
            }
            
            return res
        },
        staleTime,
        enabled: isEnabled
    })
    return { isLoadingBookings, bookings, reloadBookings }
}


export const useLoadBooking = (communityId: string, bookingId: string) => {
    let { updateCurrentBooking } = useMemberState()
    const {data: currentBooking, refetch: reloadCurrentBooking, isLoading: isLoadingBooking} = useQuery({ 
        queryKey: ['currentBooking', bookingId], 
        queryFn: async () => {
            console.log("fetching booking by id")
            const res = await fetchBookingByID(communityId, bookingId)
            updateCurrentBooking(res)
            return res
        },
        staleTime,
        enabled: isEnabled
    })
    return { isLoadingBooking, currentBooking, reloadCurrentBooking}
}



export const useAddBooking = () => {
    var { reloadBookings } = useLoadBookings()

    const mutation = useMutation({ 
        mutationFn: async (booking: BookingSchema) => {
            console.log("add booking") 
            const res = await createBooking(booking)
            reloadBookings()
            return res
    }})

    const addNewBooking = (bookingData: BookingSchema) => {
        mutation.mutate(bookingData)
        return mutation?.data ? true : false
    }

    const isLoadingBooking = mutation.isPending
    const isAddBookingSuccess = mutation.isSuccess
    const isAddBookingError = mutation.isError
    return { addNewBooking, isLoadingBooking, isAddBookingSuccess, isAddBookingError }
}


export const useUpdateBooking = (communityId: string, bookingId: string) => {
    // var { communityId } = useMemberState()
    const { reloadCurrentBooking } = useLoadBooking(communityId, bookingId)

    const mutation = useMutation({ 
        mutationFn: async (updateData: BookingSchema) => {
            console.log("update booking") 
            const res = await updateBookingData(communityId, bookingId, updateData)
            reloadCurrentBooking()
            return res
    }})

    const updateBooking = (bookingUpdate: BookingSchema) => {
        mutation.mutate(bookingUpdate)
        return mutation?.data ? true : false
    }

    const isLoadingBooking = mutation.isPending
    return { updateBooking, isLoadingBooking }
}


export const useLoadMessages = () => {
    let {  currentChatCommunity, changeChatCommunity,
            updateMessageThread, updateAllMessages, 
            updateAllsThreadsByCommunity } = useMemberState()

    var {authUser}  = useAuthState()

    const handleGroupMessages = (allMessages: MessageSchema[]): GroupedMessageListType => {
        const groupMessages = allMessages.reduce((result: GroupedMessageListType, message: MessageSchema) => {
            const { community_id } = message; // Destructure the object, excluding the 'username' property
            if (!result[community_id]) {
                result[community_id] = []; // Create an empty array for the username if it doesn't exist
            }
            result[community_id].push(message); // Add the object to the corresponding username array
            return result;
        }, {})
        return groupMessages
    }

    const {data: messages, refetch: reloadMessages, isLoading: isLoadingMessages} = useQuery({ 
        queryKey: ['messages', authUser?.username], 
        queryFn: async () => {
            console.log("fetching messages")
            const res = await getAllMessages(authUser?.username ? authUser.username : "")
            updateAllMessages(res)

            let groupMessages = handleGroupMessages(res)
            updateAllsThreadsByCommunity(groupMessages)

            var chatbotMessages = groupMessages[currentChatCommunity]
            if (!currentChatCommunity){
                changeChatCommunity('chatbot')
                chatbotMessages = groupMessages['chatbot']
            }
            
            chatbotMessages = chatbotMessages ? chatbotMessages : []
            updateMessageThread(chatbotMessages)
            return res
        },
        staleTime,
        enabled: isEnabled
    })

    const switchMember = async (member: string) => {
        if (member !== currentChatCommunity){
            await changeChatCommunity(member)
            reloadMessages()
        }
      
    }
    return {isLoadingMessages, messages, switchMember, reloadMessages}
}



export const useSendMessage = () => {
    // var { communityId } = useMemberState()
    var { reloadMessages } = useLoadMessages()

    type messageMutationType = {
        username: string
        content: string
    }

    const mutation = useMutation({ 
        mutationFn: async ({username, content}: messageMutationType) => {
            console.log("send message") 
            if (username === "chatbot"){
                const res = await generateAIResponse(content) 
                await reloadMessages()
                return res
            } else {
                const res = await sendMessageToUser('', username, content) 
                await reloadMessages()
                return res
            }
            
    }})

    const sendMessage = (username: string, content: string) => {
        mutation.mutate({username, content})
        return mutation.isSuccess
    }

    // const getAIMessage = (username: string, content: string) => {
    //     mutation.mutate({username, content})
    //     return true
    // }
    const isLoadingMessage = mutation.isPending
    return { sendMessage, isLoadingMessage }
}