import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";

import { useCommunity } from '../contexts/communitycontext'
import { states } from "../utils/enums";

import { MembershipSchema, PlanSchema } from "../data/types";
import { styleThousandCommas } from "../utils/helpers";
// import { useMemberState } from '../contexts/membercontext';
import { useAuthState } from '../contexts/authcontext';

import { useAddMembership } from '../hooks/memberhooks';
// import { useCommunity } from '../contexts/communitycontext';

// import LoadingScreen from "../pages/shared/LoadingScreen"

type CreateMembershipProps = {
  handleClose: () => void;
  isOpen?: boolean
  communityId: string
}

const CreateMembership: React.FC<CreateMembershipProps> = props => {
  const navigate = useNavigate()
  
  var { currentCommunity } = useCommunity()
  // var { addMembership } = useAddMembership()
  var { authUser } = useAuthState()
  // var { isLoadingMembership, isAddMembershipError, isAddMembershipSuccess } = useAddMembership()
  // useMemberState
  var { communityId } = props
  
  const { addMembership } = useAddMembership(communityId)
  const [newMembership, setNewMembership] = useState<MembershipSchema>({});
  const [selectedState, setSelectedState] = useState<string>("");
  const [selectedPlan, setSelectedPlan] = useState<PlanSchema>({});

  // useEffect(() => { 
  // }, [newMembership])
 

  const handleAddNewMembership = async () => {
    
    let memberEmail = authUser?.email ? authUser.email : newMembership.email 
    let firstName = authUser?.first_name?  authUser.first_name : newMembership.first_name 
    let lastName = authUser?.last_name ? authUser.last_name : newMembership.last_name 

    if (firstName && lastName && memberEmail && newMembership?.zipcode && newMembership.plan_id && communityId && addMembership !==undefined){
      
      newMembership.first_name = firstName
      newMembership.last_name = lastName
      newMembership.email = memberEmail
      
      if (newMembership.phone){
        newMembership.phone = (newMembership.phone.length === 10 && !newMembership.phone.includes('+1')) ? `+1${newMembership.phone}` : ""
      }
        // let added = 
        addMembership(newMembership)
        // if(added){
          setNewMembership({})
          // handleClose()
          navigate('/login')
        // }
    }
  }  

  const phone_style = (phone_number: string) => {
      var cleaned = ('' + phone_number).replace(/\D/g, '')
      var match = cleaned.match(/^(0-1|)?(\d{3})(\d{3})(\d{4})$/)
      // let number;
      if (match) {
          var intlCode = (match[1] ? '+1 ' : '')
          if (phone_number.length === 10){
              phone_number = [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
          } else if (phone_number.length > 3 && phone_number.length < 7){
              phone_number = [intlCode, '(', match[2], ') ', match[3]].join('');
          } else if (phone_number.length < 4){
              phone_number = [intlCode, '(', match[2], ')'].join('');
          }
      }
      return phone_number
  }

  const handleStateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    setSelectedState(value)
    setNewMembership((prevMembership) => ({
      ...prevMembership,
      state: value,
    }))
  }

  const handlePlanChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    let currentPlan = event.target.value ? JSON.parse(event.target.value) : {}
    setSelectedPlan(currentPlan)
    var { id,  plan_name, plan_description, plan_recurrence, plan_rate } = currentPlan
    setNewMembership((prevMembership) => ({
      ...prevMembership,
      plan_id: id ? id : "",  
      plan_name: plan_name ? plan_name : "", 
      plan_description: plan_description ? plan_description : "", 
      plan_recurrence: plan_recurrence ? plan_recurrence : "", 
      plan_rate: (plan_rate || plan_rate === 0) ? parseFloat(plan_rate) : 0
    }))
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;

    if (name === 'phone'){
      let phone = ('' + value).replace(/\D/g, '')
      if ((!isNaN(parseInt(phone)) && phone.length <= 10) || phone === ""){
        setNewMembership((prevMembership) => ({
          ...prevMembership,
          phone,
        }))
      }
    } else {
      setNewMembership((prevMembership) => ({
        ...prevMembership,
        [name]: value,
      }))
    }
}
  // console.log("newMembership", newMembership)
  // console.log("selectedState", selectedState)
  // console.log("selectedPlan", selectedPlan)
  return (
    <div className='flex flex-col items-center font-open-sans text-inherit p-4 col-span-1 rounded-md justify-evenly'> 
      <div className="flex flex-col">
        <img src={currentCommunity?.logo ? currentCommunity.logo : "/logo192.png"} alt="homecook-logo" className="h-[60px] self-center mb-4 m-1 rounded-lg"/>
        <h1 className="text-2xl text-primary-200 text-center">Join {(currentCommunity?.name) ? currentCommunity.name : 'Community'}</h1>
        <h1 className="text-base text-back-200 text-center">Enter membership information</h1>
      </div>
        
      <div className='flex flex-col w-full font-open-sans my-2'>
        <div className='flex flex-row w-full m-1 justify-between p-2'>
            <div className="flex flex-col">
              {/* <div className="flex">
                <input placeholder="First Name" className="formInput mr-1 bg-inherit" name="first_name" type="text" onChange={handleInputChange} />
                <input placeholder="Last Name" className="formInput bg-inherit" name="last_name" type="text" onChange={handleInputChange} />
              </div> */}
              <div className="flex">
                {(authUser && authUser.first_name) ? authUser.first_name : <input placeholder="First Name" className="formInput mr-1 bg-inherit" name="first_name" type="text" onChange={handleInputChange} />}
                {(authUser && authUser.last_name) ? `\t ${authUser.last_name}` : <input placeholder="Last Name" className="formInput bg-inherit" name="last_name" type="text" onChange={handleInputChange} />}
              </div>
              {(authUser && authUser.email) ? authUser.email : <input placeholder="Email address" className="formInput mt-1 bg-inherit" name="email" type="email" onChange={handleInputChange} />}
              {/* <input placeholder="Email address" className="formInput mt-1 bg-inherit" name="email" type="email" onChange={handleInputChange} /> */}
              <input placeholder="Phone Number" value={(newMembership.phone) ? phone_style(newMembership.phone) : ""} className="formInput mt-1 bg-inherit" name="phone" type="tel" onChange={handleInputChange} />

              <div className="flex">
                <select
                  value={selectedState}
                  onChange={handleStateChange}
                  className="formInput text-slate-500 mt-1 mr-1 bg-inherit"
                >
                  <option value="">Select a state</option>
                  {Object.values(states).map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </select>    

                <input placeholder="Zip Code" className="formInput mt-1 w-full bg-inherit" name="zipcode" type="text" onChange={handleInputChange} />
              </div>

              <select
                value={selectedPlan ? JSON.stringify(selectedPlan) : ""}
                onChange={handlePlanChange}
                className="formInput text-slate-500 mt-1 bg-inherit"
              >
                <option value="">Select a plan</option>
                {
                  (currentCommunity?.plans) ? currentCommunity.plans.map((plan, i) => (
                    <option key={i} value={plan ? JSON.stringify(plan) : ""}>
                      {plan.plan_name}
                    </option>
                  )) : null 
                }
              </select>
              <div className="flex justify-center p-1 m-1 text-[16px]">
                {
                  (selectedPlan.hasOwnProperty("plan_name") && selectedPlan.plan_name) ? (
                    <>
                    <b>{selectedPlan.plan_name.toUpperCase()}:</b> &nbsp; ${styleThousandCommas(selectedPlan?.plan_rate ? selectedPlan.plan_rate : "")} ({selectedPlan.plan_recurrence})
                    </>
                  ) : null
                }
              </div>
            </div>
        </div>
      </div>
      
      <button className='border-0 hover:bg-[#53a3b9] bg-[#013847] text-[#D9D9D9] px-3 py-1 rounded-md mx-auto w-max' onClick={handleAddNewMembership}>Request Membership</button>
    </div>
  )
}


export default CreateMembership