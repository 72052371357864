import React, {useState} from 'react'
import { CommunitySchema, MembershipSchema } from '../../data/types';
import { useUpdateMembership } from '../../hooks/memberhooks'
import { styleThousandCommas } from "../../utils/helpers";

type MemberPlansFormProps = {
    currentCommunity: CommunitySchema
    isMember: MembershipSchema
    isRequestedMember: MembershipSchema
    toggleUpdatePlan: React.Dispatch<React.SetStateAction<boolean>>
}
  
  
const MemberPlansForm: React.FC<MemberPlansFormProps> = ({currentCommunity, isMember, isRequestedMember, toggleUpdatePlan}) => {

    const username = isMember?.username ? isMember.username :  (isRequestedMember?.username ? isRequestedMember.username : '')
    const member_plan_id = isMember?.plan_id ? isMember.plan_id :  (isRequestedMember?.plan_id ? isRequestedMember.plan_id : '')
    const plans = (currentCommunity?.plans && currentCommunity?.plans?.length > 0) ? currentCommunity?.plans : []
    

    const [selectedOption, setSelectedOption] = useState<string>(member_plan_id);
    // const [member, setMembership] = useState<MembershipSchema>(isMember?.plan_id ? isMember :  (isRequestedMember?.plan_id ? isRequestedMember : {}))
    const [member, setMembership] = useState<MembershipSchema>({username})

    const community_id = currentCommunity?.id ? currentCommunity.id : ''
    // console.log('currentCommunity', currentCommunity)
    // console.log('community_id', community_id)
    const { updateMembership } = useUpdateMembership(community_id)

    const submitUpdate = async () => {
        updateMembership(member)
        toggleUpdatePlan(false)
      }

    // const [membershipChanges, setMembershipChanges] = useState<MembershipSchema>({username, first_name, last_name, team_name, plan_id, plan_name, plan_description, plan_rate, plan_recurrence});

    const handlePlanChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        var { value: plan_id } = event.target
        if (plan_id){
            setSelectedOption(plan_id)
            let plan_details = plans?.find(plan => plan_id === plan.id)
            // console.log("plan_details", plan_details)
            if (plan_details){
                let { plan_name, plan_description, plan_recurrence, plan_rate, plan_max_hours } = plan_details
                setMembership((prevMembership) => ({
                    ...prevMembership, plan_id, plan_name, plan_description, plan_recurrence, plan_rate,
                    member_balance: plan_max_hours
                    // member_balance: (kitchen_capacity && plan_recurrence) ? maxHours(kitchen_capacity, plan_recurrence) : 0
                }))
            }
        }
    }

    // const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //   setSelectedOption(event.target.value);
    // };
    
    return (
        (plans && plans?.length > 0) ? (
            <>
                {
                    (selectedOption === member_plan_id) ? (
                    // <span className='ml-auto w-full sm:w-1/2'>
                    <span className='w-full' style={{ marginLeft: `${(currentCommunity?.plans && currentCommunity.plans.length > 0 && currentCommunity.plans.length <=8) ? (((currentCommunity?.plans && currentCommunity.plans.length > 4) ? 12 : 20) * currentCommunity.plans.length) : 12}%`}}>
                        <button 
                            type='button' 
                            className='buttonsPrimary bg-red-900 hover:bg-gray-600 text-gray-200 border-0 text-xs mb-2 m-auto'
                            onClick={() => toggleUpdatePlan(false)}>
                            Cancel
                        </button> 
                    </span>
                    ) : (
                    
                    <span className='w-full' style={{ marginLeft: `${(currentCommunity?.plans && currentCommunity.plans.length > 0 && currentCommunity.plans.length <=8) ? (((currentCommunity?.plans && currentCommunity.plans.length > 4) ? 12 : 20) * currentCommunity.plans.length) : 12}%`}}>
                        <button 
                            type='button' 
                            className='buttonsPrimary bg-primary-200 hover:bg-gray-400 text-gray-200 border-0 text-xs mb-2 m-1'
                            onClick={submitUpdate}>
                                Update Plan
                        </button>
                        <button 
                            type='button' 
                            className='buttonsPrimary bg-red-900 hover:bg-gray-600 text-gray-200 border-0 text-xs mb-2 m-1'
                            onClick={() => toggleUpdatePlan(false)}>
                            Cancel
                        </button> 
                    </span>
                
                    )
                }
                
                
                <div 
                    className="flex flex-row justify-items-start font-normal sm:w-3/4 text-black/70 bg-[#d1d1d1] rounded-md p-1"
                    style={{ width: `${(currentCommunity?.plans && currentCommunity.plans.length > 0 && currentCommunity.plans.length <=8) ? (((currentCommunity?.plans && currentCommunity.plans.length > 4) ? 12 : 20) * currentCommunity.plans.length) : 12}%`}}
                >
                    {
                        plans.map((plan, i) => (
                                // <div key={i} className="font-raleway text-sm mr-auto">
                                //     <div>
                                //         <span className='font-medium'>{plan.plan_name && plan.plan_name.toUpperCase()}</span>
                                //         <div>${styleThousandCommas(plan.plan_rate)} ({plan.plan_recurrence})</div>
                                //         { 
                                //         ((plan.id === isMember.plan_id) || (plan.id === isRequestedMember.plan_id)) ? (<span className='font-open-sans text-primary-300 text-[12px] italic'>Current Plan &nbsp; <button type='button' className='buttonsPrimary bg-primary-400 hover:bg-primary-300 text-gray-200 border-0 text-[10px] m-auto'>Change Plan</button></span>) : (<></>)
                                //         }
                                //     </div>
                                // </div>
                                <div key={i} className={`font-open-sans text-sm mr-auto flex p-2 ${(selectedOption === plan.id) ? 'border-primary-300 border-[1px] rounded-md bg-white/50' : ''} `}>
                                    <input
                                        type="radio"
                                        id={plan.id}
                                        name="plan"
                                        value={plan.id}
                                        className="mr-2 appearance-none w-4 h-4 rounded-full border border-gray-300 checked:bg-primary-300 checked:border-transparent cursor-pointer"
                                        checked={selectedOption === plan.id}
                                        onChange={handlePlanChange}
                                    />
                                    <label htmlFor={plan.id} className={`text-primary-200 ${(plan.id === member_plan_id) ? 'text-primary-200/60' : ''} ${(plan.id === selectedOption) ? '' : 'cursor-pointer'}`}>
                                        <span className={`font-bold ${(plan.id === member_plan_id) ? 'text-primary-200/65' : 'text-primary-200/85'}`}>{plan.plan_name && plan.plan_name.toUpperCase()}</span>
                                        <div className={`${(plan.id === member_plan_id) ? 'text-gray-500/65' : 'text-gray-500/85'}`}>
                                            ${styleThousandCommas(plan.plan_rate)} 
                                            &nbsp; <span className='text-xs text-primary-200/60'>({plan.plan_recurrence})</span>
                                        </div>
                                        { 
                                            ((plan.id === member_plan_id)) ? (
                                                <span className='font-open-sans text-primary-300 text-[12px] italic'>Current Plan</span>
                                            ) : (<></>)
                                        }
                                    </label>
                                </div>
                            )
                        )
                    }
                </div>
            </>
        ) : (
            <div className="font-open-sans text-black/50 text-sm font-thin">Not Available</div>
        )
    )
}


// const StylishForm: React.FC = () => {
//   const [selectedOption, setSelectedOption] = useState<string>('option1');

//   const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     setSelectedOption(event.target.value);
//   };

//   return (
//     <div className="max-w-md mx-auto p-6 bg-white rounded-md shadow-md">
//       <h2 className="text-2xl font-semibold mb-4">Choose an option:</h2>
//       <div className="flex items-center mb-4">
//         <input
//           type="radio"
//           id="option1"
//           name="option"
//           value="option1"
//           className="mr-2"
//           checked={selectedOption === 'option1'}
//           onChange={handleOptionChange}
//         />
//         <label htmlFor="option1" className="text-lg text-gray-800 cursor-pointer">
//           Option 1
//         </label>
//       </div>
//       <div className="flex items-center mb-4">
//         <input
//           type="radio"
//           id="option2"
//           name="option"
//           value="option2"
//           className="mr-2"
//           checked={selectedOption === 'option2'}
//           onChange={handleOptionChange}
//         />
//         <label htmlFor="option2" className="text-lg text-gray-800 cursor-pointer">
//           Option 2
//         </label>
//       </div>
//       <div className="flex items-center">
//         <input
//           type="radio"
//           id="option3"
//           name="option"
//           value="option3"
//           className="mr-2"
//           checked={selectedOption === 'option3'}
//           onChange={handleOptionChange}
//         />
//         <label htmlFor="option3" className="text-lg text-gray-800 cursor-pointer">
//           Option 3
//         </label>
//       </div>
//     </div>
//   );
// };

// export default StylishForm;


export default MemberPlansForm