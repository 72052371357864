import React from 'react';
// import { classNames } from '@emotion/react';

interface ToggleSwitchProps {
  onChange: () => void;
  isChecked?: boolean;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  onChange,
  isChecked = false,
}) => {

  const handleToggle = () => {
      onChange()
  };

  return (
    <button
      onClick={handleToggle}
      className={`border border-gray-400 px-2 py-1 m-2 rounded-full transition-colors duration-300 ease-in-out ${
        isChecked ? 'bg-gray-400 text-white' : 'bg-gray-300 text-gray-700 '} `}>
      {(isChecked) ? 'View Summary' : 'View Calendar'}
    </button>
  );
};

export default ToggleSwitch;