import { useEffect } from "react";
// import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import moment from "moment";

// import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
// import ErrorIcon from "@mui/icons-material/Error";
// import ReportProblemIcon from '@mui/icons-material/ReportProblem';
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RecommendIcon from '@mui/icons-material/Recommend';
import AddIcon from '@mui/icons-material/Add';

import Dinnerplate from "../../assets/img/Dinnerplate.png";
// import Dinnerplate from "../../assets/img/Dinnerplate2.png";

// import { ReactComponent as DateIcon } from '../../icons/dateIcon.svg'
// import { ReactComponent as CommunityIcon } from '../../icons/Community.svg'
// import { ReactComponent as CredentialIcon } from "../../icons/credentialIcon.svg";
import { ReactComponent as MessageIcon } from "../../icons/Message.svg";
import { ReactComponent as StarIcon } from "../../icons/star.svg";
// import { ReactComponent as LocationIcon } from "../../icons/locationIcon.svg";
import { ReactComponent as EmailIcon } from "../../icons/emailIcon.svg";

import { useAuthState } from "../../contexts/authcontext";
// import { useMemberState } from "../../contexts/membercontext";
// import { useCommunity } from "../../contexts/communitycontext";
import { useGetMemberReviews } from "../../hooks/memberhooks";
import { ReviewSchema } from "../../data/types";
// import { getMembershipStatusColor } from "../../utils/helpers";

// import {
//   AddCredentialModal,
//   CredentialDetailsModal,
// } from "../../components/AppModal";

// import RequirementsChecklist from "../../molecules/views/RequirementsChecklist";

// // import { CredentialSchema } from "../../data/types";
// import NotFound from "../shared/notfound";
// import LoadingScreen from "../shared/LoadingScreen"
// import AllCredentialsView from "../shared/AllCredentialsView";


// const capitalizeString = (string: string) =>
//   string?.replace(/\w/, (c) => c?.toUpperCase());

// type RouteParams = {
//   [key: string]: string;
// };

const AccountInfo = () => {
  var navigate = useNavigate();

  const account_avatar = `https://upload.wikimedia.org/wikipedia/commons/1/1e/Default-avatar.jpg`;

  // var { memberid } = useParams<RouteParams>();
  var { authUser } = useAuthState();
  // var { changeChatMember } = useMemberState();
  // const { currentCommunity } = useCommunity()
  
  // var communityId = (authUser && "primary_community" in authUser && authUser.primary_community) ? authUser.primary_community : "";
  // let {currentMember, isLoadingCurrentMember} = useLoadMember(memberid ? memberid : "")
  var memberUsername = authUser?.username ? authUser.username : "";

  // const { addMemberCredential } = useAddCredential()
  // const { memberCredentials } = useGetCredentials(memberUsername, currentCommunity?.id ? currentCommunity.id : "")
  const { memberReviews } = useGetMemberReviews(memberUsername)

  // const [memberCredentials, setMemberCredentials] = useState<CredentialSchema[]>([]);
  // memberCredentials
  // const emptyCredential: CredentialSchema = {};
  // const [viewCredential, setViewCredential] =
  //   useState<CredentialSchema>(emptyCredential);
  // const [credentialModalIsOpen, setCredentialModalIsOpen] = useState<{
  //   addModal: boolean;
  //   viewModal: boolean;
  // }>({ addModal: false, viewModal: false });
  // const [allCredentialsPage, setAllCredentialsPage] = useState(false);

  useEffect(() => {
    document.title = `HomeCook | Member Details`;
  }, []);

  // const handleSubmitCredential = (credential: CredentialSchema) => {
  //   if (memberUsername && credential) {
  //     credential.username = memberUsername;
  //     addMemberCredential(memberUsername, credential);
  //   }
  // }

  // const meetsRequirement = (requirement: string) => {
  //   let credential = memberCredentials?.find(credential => credential.category === requirement)
  //   return credential ? credential.is_valid : false
  // }

  // const getCurrentCredential = (requirement: string) => {
  //   let credential = memberCredentials?.find(credential => credential.category === requirement)
  //   return credential ? credential : {} as CredentialSchema
  // }

  // const percentRequirementsMet = () => {
  //   let count = 0;
  //   const attributeSet = new Set<any>();

  //   if (requirements){
  //     // Loop through requirements and add unique attribute values to a set
  //     for (const req of requirements) {
  //       attributeSet.add(req['name']);
  //     }
  //   }

  //   if (memberCredentials){
  //     // Loop through memberCredentials and check for matches in the set
  //     for (const cred of memberCredentials) {
  //       if (attributeSet.has(cred['category']) && cred.is_valid) {
  //         count++;
  //         attributeSet.delete(cred['category']); // Avoid double counting duplicates within list2
  //       }
  //     }
  //   }

  //   return requirements ? (count / requirements?.length) * 100 : 0
  // }


  // const formatBookingDate = (startDate: any, endDate: any) => {
  //     startDate = moment(startDate)
  //     endDate = moment(endDate)

  //     if (startDate.day() === endDate.day()){
  //         return `${startDate.format("ddd MMM Do, YYYY")} (${startDate.format("h:mm a")} - ${endDate.format("h:mm a")})`
  //     } else if (startDate.month() === endDate.month()){
  //         return `${startDate.format("ddd MMM Do h:mm a")} - ${endDate.format("Do h:mm a, YYYY")}`
  //     } else if (startDate.year() === endDate.year()){
  //         return `${startDate.format("ddd MMM Do h:mm a")} - ${endDate.format("MMM Do h:mm a (YYYY)")}`
  //     }
  // }


  // const isExpired = (dateString: string) => {
  //   // Parse the 'MM/YYYY' formatted date into a TypeScript Date object
  //   const dateParts = dateString.split("/");
  //   if (dateParts.length === 2) {
  //     const month = parseInt(dateParts[0], 10); // Parse the month part
  //     const year = parseInt(dateParts[1], 10); // Parse the year part

  //     // Check if month and year are valid numbers
  //     if (!isNaN(month) && !isNaN(year)) {
  //       // Create a Date object using the parsed values
  //       const parsedDate = new Date(year, month - 1, 1); // Subtract 1 from the month since months are zero-based

  //       // Get the current date
  //       const currentDate = new Date();

  //       // Compare the parsed date with the current date
  //       if (parsedDate < currentDate) {
  //         return true;
  //       }
  //     }
  //   }
  //   return false;
  // };

  const capitalizeWords = (str: string) => {
    if (str) {
      // Split the string into an array of words
      const words = str.split(" ");
      // Capitalize the first letter of each word
      const capitalizedWords = words.map(
        (word) => word.charAt(0).toUpperCase() + word.slice(1)
      );
      // Join the capitalized words back into a string
      const capitalizedString = capitalizedWords.join(" ");
      return capitalizedString;
    }
  };

  const handleMessageRoute = () => {
    navigate(`/messages`);
    // changeChatMember(memberUsername ? memberUsername : '');
  };

  // var memberCredentials: {name: string, expiration: string}[] | [] = [
  //     // {name: "ServSafe Certification", expiration: '11/2022'},
  // ]

  const userTeam: any[] = [
    // {name: "Morgan", icon: CommunityIcon},
    // {name: "PGCC", icon: CommunityIcon},
    // {name: "MCC", icon: CommunityIcon},
    // {name: "dssf", icon: CommunityIcon},
    // {name: "scsc", icon: CommunityIcon},
    // {name: "scs", icon: CommunityIcon},
  ];

  // const requirementsMet = percentRequirementsMet()

  // if (isLoadingCurrentMember || isLoadingReviews){
  //   return (
  //     <LoadingScreen />
  //   )
  // } 
  // else if (!currentMember) {
  //   return (
  //     <div className="bg-back-400 min-h-screen w-screen font-open-sans">
  //       <NotFound dataName="Member" previousPath="/kitchens/members" />
  //     </div>
  //   );
  // } 
  // else if (allCredentialsPage) {
  //   return <AllCredentialsView currentMember={currentMember} credentials={memberCredentials ? memberCredentials : []} setAllCredentialsPage={setAllCredentialsPage}/>;
  // } 
  // else {
    return (
      <div className="flex w-full p-5 h-screen overflow-y-scroll bg-[#D9D9D9] font-open-sans">
        <div className="h-full flex flex-col items-center w-full text-[#013847]/80 m-5">
          {/* {currentMember?.status ? (
            <div
              style={{
                backgroundColor: getMembershipStatusColor(currentMember?.status).bgColor,
              }}
              className={`rounded-md p-2 m-1 text-md w-full`}
            >
              {capitalizeWords(currentMember?.status)}
            </div>
          ) : null} */}
          <div className="flex flex-col md:flex-row mx-auto bg-white  rounded-md h-auto w-full p-2 shadow-sm">
            {/* <ArrowBackIosIcon
              className="text-[#5DB6CE] hover:text-gray-500 cursor-pointer"
              onClick={() => navigate("/kitchens/members")}
            /> */}
            <div className="flex flex-col justify-center items-center m-2">
              {" "}
              <img
                src={
                  authUser?.avatar ? authUser.avatar : account_avatar
                }
                alt="account_avatar"
                className="h-[150px] w-[150px] md:h-[200px] md:w-[200px] rounded-full m-2"
              />
            </div>
            <div className="flex flex-col justify-center p-4 w-full">
              <p className="text-[#013847] font-bold text-[20px]">
                {authUser?.first_name} {authUser?.last_name}
              </p>
              <p className="text-[#013847] font-normal text-[16px]">
                ID#:{" "}
                <i>
                  {authUser?.primary_community ? (
                    authUser.primary_community
                  ) : (
                    <span className="font-light text-[14px] italic opacity-80">
                      No Community Yet{" "}
                      <button className="text-[12px] text-white bg-[#5DB6CE] border-0 p-1 m-2 hover:bg-[#013847]/80">
                        + Add Community
                      </button>
                    </span>
                  )}
                </i>
              </p>
              {/* <div className='flex flex-row text-[#013847] font-light text-[14px]'><img src={phoneIcon} alt="phoneIcon"  className='mr-2 h-[20px]' /> {currentMember?.phone ? phone_style(currentMember.phone.slice(2)) : ( <span className='text-[14px] italic opacity-80'>No Phone Number Yet <button className='text-[12px] text-white bg-[#5DB6CE] border-0 p-1 mx-2 hover:bg-[#013847]/80'>+ Add Phone Number</button></span>)}</div> */}
              <div className="flex flex-row text-[#013847] font-thin text-[14px]">
                <EmailIcon className="mr-2 h-[20px]" />{" "}
                {authUser?.email ? (
                  authUser.email
                ) : (
                  <span className="text-[14px] italic opacity-80">
                    No Email Yet{" "}
                    <button className="text-[12px] text-white bg-[#5DB6CE] border-0 p-1 mx-2 hover:bg-[#013847]/80">
                      + Add Email Address
                    </button>
                  </span>
                )}
              </div>
              {/* <div style={{color: getMembershipStatusColor(currentMember?.status ? currentMember.status : '').bgColor}} className="flex flex-row font-bold text-sm m-1">
                {currentMember.plan_name} Plan - ${currentMember.plan_rate} ({currentMember.plan_recurrence})
              </div> */}
              {/* <div className="flex flex-row text-[#013847] font-thin text-[14px]">
                <LocationIcon className="mr-2 h-[20px]" /> {authUser}
              </div> */}
              <span className="flex flex-row justify-end w-full text-[12px] text-slate-300 ">
                <span className="font-bold text-slate-400">Joined</span>&nbsp;
                {moment.utc(authUser?.created).fromNow()}
              </span>

              <div className="flex flex-row justify-end  text-[#013847] font-thin text-[14px] pt-4">
                <MessageIcon
                  className="mr-2 h-[20px] text-[#5db6ce] cursor-pointer"
                  onClick={() => handleMessageRoute()}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col sm:flex-row justify-evenly w-full mx-auto mt-4 text-[#013847]">
            {/* <RequirementsChecklist currentMember={authUser as MembershipSchema} currentCommunity={currentCommunity ? currentCommunity : {}} /> */}
            <div className="flex flex-col justify-start items-center bg-white rounded-md h-[600px] w-auto sm:w-4/12 m-1 px-2 py-4 shadow-sm font-bold text-[18px]"></div>
            <div className="flex flex-col justify-start items-center bg-white rounded-md h-[600px] w-auto sm:w-4/12 m-1 px-2 py-4 shadow-sm font-bold text-[18px]">
            <span className="text-[#013847]/60 text-md">REVIEWS</span>
              <div className="flex flex-col items-start my-3 mx-auto w-5/6">
                <div className="flex flex-col my-5 w-full">
                  {
                  (memberReviews && memberReviews?.review_ratings) ? (
                  <ul>
                    {memberReviews.review_ratings.map((review: {category: string, rating: number}, i: number) => {
                      return (
                        <li
                          className="grid grid-flow-col justify-between w-full"
                          key={i}
                        >
                          <h1>{capitalizeWords(review.category)}</h1>
                          <div className="grid grid-flow-col gap-x-2">
                            {review?.rating && review.rating > 0
                              ? Array.from({ length: 5 }, (_, index) =>
                                  index < review.rating ? 1 : 0
                                ).map((s, i) => (
                                  <StarIcon
                                    key={i}
                                    className={`w-6 h-6 ${
                                      s === 1
                                        ? "text-[#ED8A19]"
                                        : "text-primary-200"
                                    } `}
                                  />
                                ))
                              : Array.from({ length: 5 }).map((s, i) => (
                                  <StarIcon
                                    key={i}
                                    className="w-6 h-6 text-primary-200"
                                  />
                                ))}
                          </div>
                        </li>
                      );
                    })}
                  </ul> ) : (
                  <div className='flex flex-col justify-evenly font-light text-center text-sm text-primary-200/60 '>
                    No Reviews to show yet 
                    <button className="bg-primary-200 hover:bg-back-500 hover:text-primary-200 text-white rounded-md mt-4 m-auto w-1/3 p-2"><AddIcon className="m-auto" style={{fontSize: 15}} /> Add Review</button>
                    <button className="bg-primary-300 hover:bg-back-200 text-black rounded-md my-10 m-auto p-2">Request Review <RecommendIcon className="m-auto" style={{fontSize: 15}} /></button>
                  </div>
                  )
                  }
                </div>

                {memberReviews?.review_comments && memberReviews.review_comments.map((review: ReviewSchema, i: number) => {
                  return (
                    <div
                      key={i}
                      className="flex flex-col items-start my-5 w-full"
                    >
                      <div className="grid col-span-2 grid-flow-col gap-5">
                        <img
                          src={review?.avatar ? review.avatar : Dinnerplate}
                          alt="dinner-plate"
                          className="justify-center content-center h-24"
                        ></img>
                        <div>
                          <h1 className="text-md text-primary-200">
                            {review.reviewer_community_name}
                          </h1>
                          <h2 className="text-sm font-thin text-[#888]">{moment(new Date(review?.timestamp)).format("MMM DD, YYYY")}</h2>
                          <div className="flex flex-row gap-2">
                            {review?.experience_rating && review.experience_rating > 0
                              ? Array.from({ length: 5 }, (_, index) =>
                                  index < review.experience_rating ? 1 : 0
                                ).map((s, i) => (
                                  <StarIcon
                                    key={i}
                                    className={`w-3 h-3 ${
                                      s === 1
                                        ? "text-[#ED8A19]"
                                        : "text-primary-200"
                                    } `}
                                  />
                                ))
                              : Array.from({ length: 5 }).map((s, i) => (
                                  <StarIcon
                                    key={i}
                                    className="w-6 h-6 text-primary-200"
                                  />
                                ))}
                          </div>
                          <p className="text-sm font-light">{review.comment}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="flex flex-row justify-between w-full items-end h-full">
                <div className="font-light text-[16px] text-[#5DB6CE] cursor-pointer hover:text-[#013847]"></div>
                <div className="font-light text-[16px] text-[#5DB6CE] cursor-pointer hover:text-[#013847]">
                  See More
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-start items-center bg-white  rounded-md h-[600px] w-auto sm:w-4/12 m-1 p-4 shadow-sm font-bold text-[18px]">
              <span className="text-[#013847]/60 text-md">TEAM</span>
              <div className="grid grid-cols-3 grid-flow-row-dense p-4">
                {userTeam?.map((community, i) => {
                  return (
                    <div
                      key={i}
                      className="flex rounded-full m-4 hover:scale-95 duration-1000 cursor-pointer"
                    >
                      {/* <community.icon className='flex-item m-auto text-[#5DB6CE]/50' /> */}
                      <img
                        src={account_avatar}
                        alt="account_avatar"
                        className="h-[50px] w-[50px] rounded-full m-2"
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  
};

// const phone_style = (phone_number: string): string => {
//     var cleaned = ('' + phone_number).replace(/\D/g, '')
//     var match = cleaned.match(/^(0-1|)?(\d{3})(\d{3})(\d{4})$/)
//     // let number;
//     if (match) {
//         var intlCode = (match[1] ? '+1 ' : '')

//         if (phone_number.length === 10){
//             phone_number = [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
//         } else if (phone_number.length > 3 && phone_number.length < 7){
//             phone_number = [intlCode, '(', match[2], ') ', match[3]].join('');
//         } else if (phone_number.length < 4){
//             phone_number = [intlCode, '(', match[2], ')'].join('');
//         }

//       }
//     return phone_number
// }

export default AccountInfo;
