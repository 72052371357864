import React from 'react'
import { CommunitySchema, MembershipSchema } from '../../data/types';
import { styleThousandCommas } from "../../utils/helpers";

type MemberPlansProps = {
    currentCommunity: CommunitySchema
    isMember: MembershipSchema
    isRequestedMember: MembershipSchema
    toggleUpdatePlan: React.Dispatch<React.SetStateAction<boolean>>
}
  
  
const MemberPlans: React.FC<MemberPlansProps> = ({currentCommunity, isMember, isRequestedMember, toggleUpdatePlan}) => {

    var member_plan_id = isMember?.plan_id ? isMember.plan_id :  (isRequestedMember?.plan_id ? isRequestedMember.plan_id : '')

    return (
        (currentCommunity?.plans && currentCommunity?.plans?.length > 0) ? (
            <>
            {
                (member_plan_id) && (
                    <span className='w-full' style={{ marginLeft: `${(currentCommunity?.plans && currentCommunity.plans.length > 0 && currentCommunity.plans.length <=8) ? (((currentCommunity?.plans && currentCommunity.plans.length > 4) ? 12 : 20) * currentCommunity.plans.length) : 12}%`}}>
                        <button 
                            type='button' 
                            className='buttonsPrimary bg-primary-400 hover:bg-primary-300 text-gray-200 border-0 text-xs mb-2 m-auto'
                            onClick={() => toggleUpdatePlan(true)}>
                            Change Plan
                        </button>
                    </span>
                )
            }
            
            <div className="flex flex-row justify-items-start font-normal w-full sm:w-3/4 text-black/70">
            {
                currentCommunity.plans.map((plan, i) => (
                    <div key={i} className={`font-open-sans text-sm mr-auto flex max-w-md  p-2 ${(plan.id === member_plan_id) ? 'border-primary-300 border-[1px] rounded-md bg-white/50' : ''}`}>
                        <div className={`${(plan.id === member_plan_id) ? 'text-primary-200/60' : ''}`}>
                            <span className={`font-bold ${(plan.id === member_plan_id) ? 'text-primary-200/65' : 'text-primary-200/85'}`}>{plan.plan_name && plan.plan_name.toUpperCase()}</span>
                            <div className={`${(plan.id === member_plan_id) ? 'text-gray-500/65' : 'text-gray-500/85'}`}>
                                ${styleThousandCommas(plan.plan_rate)} 
                                &nbsp; <span className='text-xs text-primary-200/60'>({plan.plan_recurrence})</span>
                            </div>
                            { 
                                ((plan.id === member_plan_id)) ? (
                                    <span className='font-open-sans text-primary-300 text-[12px] italic'>Current Plan</span>
                                ) : (<></>)
                            }
                        </div>
                    </div>)
                )
            }
            </div>
            </>
        ) : (
            <div className="font-open-sans text-black/50 text-sm font-thin">Not Available</div>
        )
    )
}

export default MemberPlans