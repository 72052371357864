import React, {ReactNode, useState, useContext, createContext} from 'react'
// import { startNewAuth, createNewCommunity, getAuthUser, verifyUserOTP, fetchCommunity, signOut, updateUserData } from '../api/crud'

import { AuthSchema, AuthStateType, CommunitySchema } from "../data/types";


// const AuthContext = createContext<AuthContextType | null>(null);
const AuthContext = createContext<AuthStateType | {}>({});


type PropsChildren = {children?: ReactNode}

const AuthProvider = (props: PropsChildren) => {
    const [authUser, updateAuthUser] = useState<AuthSchema>()
    const [primaryCommunity, updatePrimaryCommunity] = useState<CommunitySchema>()
    const [isloggedin, updateLogin] = useState<boolean>(false)
    const [isverified, updateVerified] = useState<boolean>(false)

    var communityId = (primaryCommunity && primaryCommunity.id) ? primaryCommunity.id : ""


    let data = { 
        primaryCommunity, communityId, 
        authUser, isloggedin, isverified,  
        updateLogin, updateVerified,
        updateAuthUser, updatePrimaryCommunity,
    }
   
    return <AuthContext.Provider value={data} {...props} />
}

const useAuthState = () => {
    const context: AuthStateType = useContext(AuthContext)
    if (context === undefined ){
        throw new Error(`useAuthState must be used within AuthProvider`)
    }
    return context
}

export { AuthProvider, useAuthState }
