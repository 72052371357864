// import React, { useState } from "react";
// import { states } from "../utils/enums";
import { CommunitySchema, MembershipSchema, } from "../data/types";
// import StartBooking from "./StartBooking";
// import { useKitchen } from "../contexts/kitchencontext";

// import StartBooking from "./StartBooking";
import CreateMembership from "./CreateMembership";
// import AddMessageList from "../molecules/addMessageList";
// import { ReactComponent as Avatar } from "../icons/ChatbotIcon.svg";
// import { useMemberState } from "../contexts/membercontext";

// TO BE LOADED FROM COMMUNITIES / REQUIRED DOCS
// const credentialCategories = ["ID", "license", "certificate", "insurance", "other"]

// const capitalizeString = (string: string) => string?.replace(/\w/, c => c?.toUpperCase())

// type AddCredentialFormProps = {
//   handleClose: () => void;
//   handleSubmitCredential: (credential: CredentialSchema) => void;
// };

// const AddCredentialForm: React.FC<AddCredentialFormProps> = (props) => {
//   var { handleSubmitCredential, handleClose } = props;
//   var { requirements } = useKitchen();

//   const [credential, setCredential] = useState<CredentialSchema>(
//     {} as CredentialSchema
//   );
//   const [selectedCategory, setSelectedCategory] = useState<string>("");

//   const handleCategoryChange = (
//     event: React.ChangeEvent<HTMLSelectElement>
//   ) => {
//     setSelectedCategory(event.target.value);
//     setCredential((prevCred: CredentialSchema) => ({
//       ...prevCred,
//       category: event.target.value,
//     }));
//   };

//   const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     const { name, files } = event.target;
//     if (name && files && files.length > 0) {
//       let file_data = files[0];
//       setCredential((prevCredential: CredentialSchema) => ({
//         ...prevCredential,
//         [name]: file_data,
//       }));
//     }
//   };

//   const handleSubmit = () => {
//     if (credential) {
//       handleSubmitCredential(credential);
//       handleClose();
//     }
//   };

//   return (
//     <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2 md:justify-center items-center md:my-20 md:gap-x-2 m-10 md:m-0">
//       <button
//         className="absolute top-0 left-0 p-2 text-gray-600 hover:text-gray-800 "
//         onClick={handleClose}
//       ></button>
//       <div className="text-center md:w-1/3 pr-16 ml-24 md:ml-0">
//         <h1 className="text-4xl text-primary-400">Add Credential</h1>
//         <p className="text-sm text-black">
//           Please fill in the following information
//         </p>
//       </div>
//       <div className="w-full grid grid-flow-row gap-y-5">
//         <select
//           value={selectedCategory}
//           onChange={handleCategoryChange}
//           className="formInput text-slate-500"
//         >
//           <option value="" className="text-slate-400">
//             -- Requirement --
//           </option>
//           {requirements &&
//             requirements.map((c, i) => (
//               <option key={i} value={c.name}>
//                 {c.name}
//               </option>
//             ))}
//         </select>

//         <div className="grid ">
//           <label className="text-slate-500">Upload File</label>
//           <input
//             type="file"
//             placeholder="Upload File"
//             className="form-input"
//             name="file"
//             onChange={handleFileChange}
//           />
//         </div>
//         <button
//           className="buttonsPrimary h-10 text-xl"
//           onClick={handleSubmit}
//           data-testid="submit-button"
//         >
//           Submit
//         </button>
//       </div>
//     </div>
//   );
// };
type AddReservationFormProps = {
  handleClose: () => void;
  membership: MembershipSchema
};

const AddReservationForm: React.FC<AddReservationFormProps> = (props) => {
  // return <StartBooking handleClose={props.handleClose} />;
  return <></>
};


type AddMemberFormProps = {
  communityId: string
  handleClose: () => void;
  handleAddMember: (kitchen: CommunitySchema | {}) => void;
};

const AddMemberForm: React.FC<AddMemberFormProps> = (props) => {
  return <CreateMembership communityId={props.communityId} handleClose={props.handleClose} />;
};

// type AddReservationFormProps = {
//   handleClose: () => void;
// };

// const AddReservationForm: React.FC<AddReservationFormProps> = (props) => {
//   return <StartBooking handleClose={props.handleClose} />;
// };

type DeleteConfirmationFormProps = {
  handleClose: () => void;
  onDeleteConfirmation: () => void;
};

const DeleteConfirmationForm: React.FC<DeleteConfirmationFormProps> = (
  props
) => {
  let { handleClose, onDeleteConfirmation } = props;
  return (
    <div className="grid container space-x-2 space-y-2 justify-center align-center">
      <h1 className="text-xl text-primary-400">
        Are you sure you want to delete?
      </h1>
      <div className="text-lg text-center pb-5">
        Please confirm kitchen deletion
      </div>
      <div className="text-center grid grid-cols-2 gap-5">
        <button
          className="h-10 text-center text-white bg-cancel rounded-md drop-shadow-lg"
          onClick={onDeleteConfirmation}
          data-testid="delete-button"
        >
          Yes
        </button>
        <button
          className="text-center text-white bg-stone-400 rounded drop-shadow-lg"
          onClick={handleClose}
        >
          No
        </button>
      </div>
    </div>
  );
};

// type NewMessageFormProps = {
//   handleClose: () => void;
// };

// const NewMessageForm: React.FC<NewMessageFormProps> = (props) => {
//   const { newMessageMember, changeChatCommunity } = useMemberState();
//   let { handleClose } = props;

//   const capitalizeString = (string: string) =>
//     string?.replace(/\w/, (c) => c?.toUpperCase());

//   let get_primary_text = (member?: string) => {
//     return (
//       <p className="font-open-sans font-extrabold text-[14px] text-[#2D3748]">
//         {member === "chatbot" ? capitalizeString(member) : member}
//       </p>
//     );
//   };
//   const handleNewMessage = () => {
//     changeChatCommunity(newMessageMember);
//     handleClose();
//   }
//   return (
//     <div className="flex flex-row container space-x-2 space-y-2 justify-center align-center w-[500px] ">
//       <div className="flex flex-col w-full">
//         <h1 className="text-3xl border-b-2 w-full text-center p-2">
//           New Message
//         </h1>
//         <div className="text-xl border-b-2  flex flex-row px-2 pt-2">
//           <h1 className="mt-1">To: </h1>{" "}
//           {newMessageMember ? (
//             <div
//               className={`flex justify-start m-1 px-5 cursor-pointer h-[40px] rounded `}
//             >
//               <Avatar
//                 className={`h-[30px] w-auto ${
//                   newMessageMember === "chatbot"
//                     ? "text-[#51a0b5]/90"
//                     : "text-[#013847]/90"
//                 }`}
//               />
//               <div className="flex flex-col mx-4">
//                 <div>{get_primary_text(newMessageMember)}</div>
//               </div>
//             </div>
//           ) : null}
//         </div>
//         <hr />
//         <ul>
//           <AddMessageList />
//         </ul>
//         <button className="buttonsPrimary text-black p-1 m-2" onClick={() => handleNewMessage()}>Start Chat</button>
//       </div>
//     </div>
//   );
// };

export {
  AddReservationForm,
  DeleteConfirmationForm,
  // NewMessageForm,
  AddMemberForm,
  // AddCredentialForm,
};
