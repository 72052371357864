import React from 'react'
import { Route, Routes } from 'react-router-dom'

import BookingDetail from '../pages/Auth/BookingDetail'
import Bookings from '../pages/Auth/Bookings'
// import Home from '../pages/Auth/Home'
import Messages from '../pages/Auth/Messages'
import CommunityDetails from '../pages/shared/CommunityDetails'
import KitchenDetails from '../pages/shared/KitchenDetails'
import Communities from '../pages/shared/Communities'
import Settings from '../pages/Auth/Settings'
import AccountPaymentMethods from '../pages/Auth/AccountPayments'
import AccountInfo from '../pages/Auth/AccountInfo'
import Privacy from '../pages/shared/Privacy'
import Terms from '../pages/shared/Terms'
import Disclaimer from '../pages/shared/Disclaimer'
import Eula from '../pages/shared/Eula'
// import UserCircles from '../pages/Auth/UserCircles'



const AuthView = () => {
    // let { isloggedin, authUser } = useAuthState()
    return (
        // <div className='w-10/12 ml-auto sm:w-full '>
        <div className='w-full mt-12 sm:w-full '>
            {/* <SideDrawer  nav={true} isloggedin={isloggedin} authUser={authUser} /> */}
            <Routes>
                {/* <Route path="/" element={<Home />}  /> */}
                {/* <Route path="/login" element={<Schools />}  /> */}
                <Route path="/account" element={<AccountInfo />}  />
                {/* <Route path="/community/schools/:schoolId" element={<CommunityDetail />} /> */}
                <Route path="/community/:communityId" element={<CommunityDetails />} />
                <Route path="/community/:communityId/:kitchenId" element={<KitchenDetails />} />
                {/* <Route path="/community/schools" element={<Schools />} /> */}
                {/* <Route path="/circles" element={<UserCircles />} /> */}
                <Route path="/bookings" element={<Bookings />} />
                <Route path="/bookings/:communityId/id/:bookingId" element={<BookingDetail />} />
                <Route path="/messages" element={<Messages />} />
                <Route path="/settings" element={<Settings />} /> 
                <Route path="/settings/payment" element={<AccountPaymentMethods />} /> 
                {/* <Route path="/kitchens" element={<Communities forKitchen={true} />}  /> */}
                <Route path="*" element={<Communities />}  />
                <Route path="/privacy" element={<Privacy />}  />
                <Route path="/terms" element={<Terms />}  />
                <Route path="/disclaimer" element={<Disclaimer />}  />
                <Route path="/eula" element={<Eula />}  />
            </Routes>
        </div>
    )
}


export default AuthView;