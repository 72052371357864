import React from "react";
import { useNavigate } from "react-router-dom";

import { KitchenSpaceSchema, MembershipSchema } from "../data/types";

import StarRating from "../components/StarRating"
// import { ReactComponent as LocationIcon }  from '../icons/locationIcon.svg'
// import { useMemberState } from "../contexts/membercontext";

type KitchenRecordProps = {
  kitchenKey?: number
  communityId?: string
  hasHomeQuery ?: boolean
  kitchen: KitchenSpaceSchema;
  toggleNewMember?: React.Dispatch<React.SetStateAction<boolean>>
  toggleNewReservation?: React.Dispatch<React.SetStateAction<boolean>>
  isMember?: MembershipSchema
  isRequestedMember?: MembershipSchema
};

const KitchenRecord: React.FC<KitchenRecordProps> = (props) => {
  var navigate = useNavigate();

  // var { memberships } = useMemberState()

  var { kitchenKey, kitchen, isMember, isRequestedMember, hasHomeQuery } = props;

  const openKitchenView = (kitchenIndex: number, isAddingBooking: boolean) => {
    navigate(`/community/${kitchen.community_id}/${kitchenIndex}${hasHomeQuery ? '?home=true' : ''}${isAddingBooking ? '?addBooking=true' : ''}`);
  };


  return kitchen ? (
    <div className="flex flex-col mx-auto h-auto bg-primary-200 rounded-md p-2" >
      {/* <div className="flex w-full justify-evenly">
        <img
          src={kitchen.bgPhoto}
          alt="Background"
          className="col-span-1 w-54 h-30 rounded-2xl shadow-lg position-absolute object-cover mr-1"
        />
      </div> */}
      
      <div className="contain text-center">
      <h1 className="text-md m-1 text-center text-white">
        {kitchen.name}
      </h1>

      <div className="flex flex-col w-full text-center mx-auto">
        <p className='font-raleway text-sm flex flex-row mx-auto text-gray-400'>
            {kitchen && kitchen.address ? `${kitchen.address}`: ``} 
        </p>
        <p className='font-raleway text-sm flex flex-row mx-auto text-gray-400'>
            {kitchen && kitchen.city ? `${kitchen.city}, `: ``} 
            {/* {kitchen && kitchen.county ? `${kitchen.county}, `: ``}   */}
            {kitchen && kitchen.state ? `${kitchen.state} `: ``} 
            {kitchen && kitchen.zipcode ? `${kitchen.zipcode} `: ``}  
            {/* {kitchen && kitchen.zipcode} {kitchen && kitchen.country}  */}
        </p>
      </div>
      

      <div className='flex flex-col mt-2 text-white'>
          <p className='font-raleway font-bold text-sm'>Kitchen Size</p>
          <div className="text-gray-400">
          {
              kitchen.size
          } sqft
          </div>
      </div>

      <div className='flex flex-col text-white'>
          <p className='font-raleway font-bold  text-sm'>Kitchen Capacity</p>
          <div className="text-gray-400">
            {
                kitchen?.capacity ? kitchen.capacity : 0
            } 
            {
                kitchen.capacity === '1' ? ' Person' : ' People'
            }
          </div>
      </div>

      <div className='flex flex-col justify-center items-center'>
        <li className="grid grid-flow-col justify-start">
          <StarRating review_rating={4} />
        </li>
      </div>

      <div className="mt-2">
        <button className='rounded-md text-sm border-0 bg-[#53a3b9]/80 hover:bg-gray-100 text-gray-100 hover:text-[#53a3b9] m-1' onClick={() => openKitchenView(kitchenKey ? kitchenKey : 0, false)}>View Kitchen</button>
      
        {
          (isMember && isMember?.id && isMember?.status) ? (
            <button className='rounded-md text-sm border-0 bg-gray-100 hover:bg-[#53a3b9]/80 text-primary-200 hover:text-[#aedce9] m-1' onClick={() => openKitchenView(kitchenKey ? kitchenKey : 0, true)}>Request Booking</button>
          ) : (
            (isRequestedMember && isRequestedMember?.id && isRequestedMember?.status) ? (
              <button disabled className='mx-auto rounded-md text-sm border-0 bg-[#aedce9] text-[#53a3b9] m-1'>Request Booking</button>
            ) : (<></>)
          )
        }
      </div>
    </div>
  </div>
  ) : (<></>)
};

export default KitchenRecord;
