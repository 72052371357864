import React from 'react';
import { startOfMonth, startOfWeek, endOfWeek, endOfMonth, eachDayOfInterval, format } from 'date-fns';

import { BookingSchema } from "../data/types";
import { darkenColor, getBookingStatusColor } from "../utils/helpers";

type MonthViewProps = {
  currentDate: Date
  bookings: BookingSchema[]
}

const MonthView: React.FC<MonthViewProps> = ({bookings, currentDate}) => {
  const firstDayOfMonth = startOfWeek(startOfMonth(currentDate));
  const lastDayOfMonth = endOfWeek(endOfMonth(currentDate));

  const daysInMonth = eachDayOfInterval({ start: firstDayOfMonth, end: lastDayOfMonth });

  const areDatesInSameMonth = (date1: Date, date2: Date) => {
    // Extract year and month components from both dates
    const year1 = date1.getFullYear();
    const month1 = date1.getMonth();
  
    const year2 = date2.getFullYear();
    const month2 = date2.getMonth();
  
    // Compare the year and month components
    return year1 === year2 && month1 === month2;
  }

  return (
    <div>
      <div className="grid grid-cols-7 gap-2 grid-flow-dense">

        {daysInMonth.slice(0, 7).map((day) => (
          <div key={day.toISOString()} className="bg-[#ccc] text-[#fff]  p-2 relative col-span-1">
            <div>{format(day, 'E')}</div>
          </div>
        ))}

       
        {daysInMonth.map((day) => (
          <div key={day.toISOString()} className="border border-gray-400  p-2 relative col-span-1">
            <div className={`${areDatesInSameMonth(day, currentDate) ? '' : 'text-slate-400/70'}`}>
              {format(day, 'dd')}
              </div>
            <div className="mt-2">
              <ul>
                {bookings.map((booking: any, index: number) => {
                  const eventStartDate = new Date(booking.start_time);
                  const eventEndDate = new Date(booking.end_time);
                  if (
                    eventStartDate.getDate() === day.getDate() &&
                    eventStartDate.getMonth() === day.getMonth() &&
                    eventStartDate.getFullYear() === day.getFullYear()
                  ) {
                    const topPercentage =
                      (eventStartDate.getHours() * 60 + eventStartDate.getMinutes()) / (24 * 60) * 100;
                    const heightPercentage =
                      ((eventEndDate.getHours() - eventStartDate.getHours()) / (24 * 60 * 60 * 1000)) * 100;
                    return (
                      <li
                        key={index}
                        className="text-[10px] rounded px-2 py-1 mb-1 w-full"
                        style={{
                          position: 'absolute',
                          left: 0,
                          right: 0,
                          top: `${topPercentage}%`,
                          height: `${heightPercentage}%`, 
                          color: (booking?.status) ? darkenColor(getBookingStatusColor(booking.status), 90) : darkenColor('#38b2ac', 90),
                          backgroundColor: (booking?.status) ? darkenColor(getBookingStatusColor(booking.status), 50) : darkenColor('#38b2ac', 50)
                        }}
                      >
                        {`${booking.summary} | ${format(eventStartDate, 'h:mm a')} - ${format(eventEndDate, 'h:mm a')}`}
                      </li>
                    );
                  }
                  return null;
                })}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MonthView;
