import { useState, useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {AiOutlineLink} from 'react-icons/ai'
import { AddMemberFormModal, AddReservationModal } from '../../components/AppModal'
import RequirementsChecklist from '../../molecules/views/RequirementsChecklist' 

// import moment from 'moment'

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
// import { ReactComponent as ScrollUpIcon } from '../../icons/ScrollUpIcon.svg'
// import { ReactComponent as ScrollDownIcon } from '../../icons/ScrollDownIcon.svg'

// import { ReactComponent as LocationIcon }  from '../../icons/locationIcon.svg'
import KitchenRecord from '../../components/KitchenRecord'
import MemberPlans from '../../molecules/views/MemberPlans'
import MemberPlansForm from '../../molecules/views/MemberPlansForm'

// import { useAuthState } from '../../contexts/authcontext'
// import { useMemberState } from '../../contexts/membercontext'
// import { useCommunity } from "../../contexts/communitycontext"
import { useLoadCurrentCommunity } from "../../hooks/communityhooks"
// import {  MembershipSchema } from "../../data/types"
import { useAuthState } from '../../contexts/authcontext';
import { getMembershipStatusColor, findObjectByAttribute } from "../../utils/helpers";
// import EditKitchenInfo from "./EditKitchenInfo"
// import EditKitchenDetails from './EditKitchenDetails'
// import { AddPlanModal, AddRequirementModal } from "../../components/AppModal";

// import {CreateBooking } from '../../molecules/views/CreateBooking'
// import  EquipmentsWidget from '../../molecules/views/EquipmentsWidget'

// TO BE LOADED FROM COMMUNITIES / REQUIRED DOCS

// const credentialCategories = ["ID", "license", "certificate", "insurance", "other"]

// const account_avatar: string = `https://upload.wikimedia.org/wikipedia/commons/1/1e/Default-avatar.jpg`



const openInNewTab = (url: string) => {
  window.open(url, '_blank');
};

type RouteParams = { 
  communityId: string;
};

const CommunityDetails = () => {
  var navigate = useNavigate();
  const { communityId } = useParams<RouteParams>()

  const { currentCommunity } = useLoadCurrentCommunity(communityId ? communityId : "")
  // useLoadCurrentCommunity(communityId)


  
  const [searchParams] = useSearchParams();
  const homeQuery = searchParams.get('home');

  // var { currentCommunity } = useCommunity()
  var { authUser } = useAuthState()

  // var { 
  //   // memberships, 
  //   loadData: loadAllMembershipData 
  // } = useMemberState()

  // const loadCurrentKitchen = async () => {
  //     if (getCommunity !== undefined && communityId) {
  //       await getCommunity(communityId);
  //     }
  
  //     // if (loadAllMembershipData !== undefined ){
  //     //     await loadAllMembershipData()
  //     // }
  // }



  // const [isEditing, setIsEditing] = useState(false);
  // const [isOpen,setIsOpen] = useState(false);
  // const [isOpenReq,setIsOpenReq] = useState(false);
  const [addMember, toggleNewMember] = useState(false)
  const [addReservation, toggleNewReservation] = useState(false)
  const [updatePlan, toggleUpdatePlan] = useState(false)


  const kitchenName = currentCommunity?.name ? currentCommunity.name : ''

 
  let allMemberships = currentCommunity?.memberships
  let membershipRequests = currentCommunity?.membership_requests
  let username = authUser?.username ? authUser.username : ''
  var isMember = (username && allMemberships) ? findObjectByAttribute(allMemberships, "username", username) : {}
  var isRequestedMember = (username && membershipRequests) ? findObjectByAttribute(membershipRequests, "username", username) : {}

  // var isMember: boolean = false;

  const currentMember = (isMember && isMember?.username) ? isMember : ((isRequestedMember && isRequestedMember?.username) ? isRequestedMember : {})



  useEffect(() => {
    document.title = `Community | ${kitchenName ? kitchenName : 'HomeCook'}`
    // loadCurrentKitchen()
    // if (!currentCommunity || !currentCommunity?.name){loadCurrentKitchen()}
    // eslint-disable-next-line 
  }, []);

  const closeAppModal = () => {
    toggleNewMember(false)
    toggleNewReservation(false)
  }


//   console.log("currentCommunity", currentCommunity)
if (!currentCommunity || (currentCommunity && !currentCommunity?.name)){
    return (
        <div
          role="status"
          className="grid items-center justify-center h-screen md:p-[1%] w-full bg-back-400 pt-16 md:pt-16"
        >
          <svg
            aria-hidden="true"
            className="w-28 h-28 mr-2 text-gray-200 animate-spin  fill-primary-400"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span className="sr-only">Loading...</span>
        </div>
      )
  } else {
        return (
          <>
            <div className='flex mx-auto w-full p-4 h-screen bg-[#D9D9D9] dark:bg-[#08090a]'>
                <div className='font-raleway flex flex-col w-full relative items-start p-4'>
            
                    <>
                        {(homeQuery) ? (<></>) : (<span className="text-[#5DB6CE] hover:text-gray-500 mb-4 cursor-pointer" onClick={() => navigate('/kitchens')}><ArrowBackIosIcon /> All Communities</span>)}

                        <div className='flex flex-col sm:flex-row w-full sm:w-auto text-xl text-[#000]'>
                          <img className='h-20 sm:h-16 aspect-auto relative my-auto mx-auto rounded-lg' alt={currentCommunity.logo} src={currentCommunity.logo} /> 
                          <p className='mx-2 sm:text-left text-center mt-2 sm:mt-auto my-auto'>{currentCommunity ? `${currentCommunity.name}` : null}</p>
                        </div>
                        
                        <p className='font-raleway w-full text-md flex flex-row mx-1 p-1 text-primary-200'>
                            {/* <LocationIcon className='w-4 h-4 m-1' /> &nbsp;
                            {currentCommunity && currentCommunity.address ? `${currentCommunity.address}, `: ``} 
                            {currentCommunity && currentCommunity.city ? `${currentCommunity.city}, `: ``} 
                            {currentCommunity && currentCommunity.county ? `${currentCommunity.county}, `: ``}  
                            {currentCommunity && currentCommunity.state ? `${currentCommunity.state} `: ``}  
                            {currentCommunity && currentCommunity.zipcode} {currentCommunity && currentCommunity.country}  */}
                            <span className='flex flex-row text-sm text-[#013847]  hover:text-gray-400 cursor-pointer mx-auto sm:ml-4 my-1' onClick={() => (currentCommunity && currentCommunity.website) ? openInNewTab(currentCommunity.website) : null}><AiOutlineLink className='mx-1' />Visit Website</span>
                        </p>

                      

                        {
                          (isMember && isMember.username && isMember?.status) ? (
                          <div 
                            className={`flex flex-row text-[#fff]/80 rounded-sm p-2 text-sm sm:text-md w-max col-span-2 mx-auto sm:mx-1  sm:ml-auto justify-evenly`}
                            style={{backgroundColor: getMembershipStatusColor(isMember?.status).bgColor, opacity: 0.7}}
                            >
                            {`MEMBERSHIP ${isMember?.status?.toUpperCase()}`}
                          </div>
                          ) : ((isRequestedMember && isRequestedMember.username && isRequestedMember?.status) ? (
                            <div 
                              className={`flex flex-row text-[#fff]/80 rounded-sm p-2 text-sm sm:text-md w-max col-span-2 mx-auto sm:mx-1 sm:ml-auto justify-evenly`}
                              style={{backgroundColor: getMembershipStatusColor(isRequestedMember.status).bgColor, opacity: 0.7}}
                              >
                              {`MEMBERSHIP ${isRequestedMember.status?.toUpperCase()}`}
                            </div>
                          ) : (<></>))
                        }

                        <div className='flex flex-row justify-evenly w-full h-[300px] my-2'>
                            

                            {
                                currentCommunity?.bgPhoto ? (
                                <img className='object-cover w-full' src={currentCommunity?.bgPhoto} alt={currentCommunity?.name} /> 
                                ) : (
                                    <>
                                    <div className='h-[280px] w-9/12 rounded-md m-1  bg-slate-400' ></div>
                                    <div className='flex flex-col h-[280px] w-3/12 rounded-md m-1' >
                                        <div className='h-1/2 w-auto rounded-md m-1  bg-slate-400' ></div>
                                        <div className='h-1/2 w-auto  rounded-md m-1 bg-slate-400' ></div>
                                    </div>
                                    </>
                                    )
                            }
                        </div>
        
                        <div className='flex flex-col sm:flex-row w-full'>
                            <div className='w-full'>
                                <div className='flex flex-col mt-4 font-raleway'>
                                    <p className=' font-bold text-[#000000]  text-md'>Description</p>
                                    {/* {
                                            currentCommunity?.logo ? (
                                            <img className='h-[100px] w-[100px] object-contain my-4' src={currentCommunity.logo} alt={currentCommunity?.id} onError={e => { e.currentTarget.src = Campus }} /> 
                                            ) : (
                                                <img className='h-[70px] w-[70px] rounded-md m-2 relative bg-slate-600' alt={currentCommunity?.id} src={Campus} />
                                                )
                                    } */}
                                    <p className=' text-[#555] font-open-sans text-sm'>{currentCommunity ? currentCommunity.description : null}</p>
                                </div>
            
                                <div className='flex flex-col mt-4'>
                                    <p className='font-raleway font-bold text-[#000] text-md'>Membership Plans</p>
                                    {
                                      (updatePlan) ? (
                                        <MemberPlansForm 
                                          currentCommunity={currentCommunity} 
                                          isMember={isMember} 
                                          isRequestedMember={isRequestedMember}
                                          toggleUpdatePlan={toggleUpdatePlan} 
                                        />
                                      ) : (
                                        <MemberPlans 
                                          currentCommunity={currentCommunity} 
                                          isMember={isMember} 
                                          isRequestedMember={isRequestedMember}
                                          toggleUpdatePlan={toggleUpdatePlan} 
                                        />
                                      )
                                    }
                                </div>
                                <div className='flex flex-col my-4'>
                                    {
                                        (currentMember && currentMember?.username) ? (<>
                                        <RequirementsChecklist currentMember={currentMember} currentCommunity={currentCommunity} />
                                        </>) : (
                                        (currentCommunity?.requirements && currentCommunity?.requirements?.length > 0) ? (
                                          <>
                                            <p className='font-raleway font-bold text-[#000] text-md'>Membership Requirements</p>
                                            <ul className="font-normal w-full list-disc ml-4">
                                            {
                                                currentCommunity?.requirements.map((requirement, i) => (
                                                        <li key={i} className="text-sm">
                                                            <span className='font-open-sans text-[#555] font-medium'>{requirement.name && requirement.name.toUpperCase()}</span>
                                                        </li>
                                                    )
                                                )
                                            }
                                            </ul>
                                          </>
                                        ) : (<><p className='font-raleway font-bold text-[#000] text-md'>Membership Requirements</p><div className='font-open-sans text-black/50 text-sm font-thin'>Not Available</div></>))
                                     }
                                </div>

                                {
                                  (currentMember && currentMember?.username) ? (<></>) : (
                                   <div className='flex flex-col mt-4'>
                                    <button className='rounded-md text-sm border-0 bg-[#53a3b9] hover:bg-[#013847]/80 text-gray-100 hover:text-[#D9D9D9] m-2 sm:w-1/3' onClick={() => toggleNewMember(true)}>Request Membership</button>
                                  </div>
                                  )
                                }

                                
                                {/* <div className='flex flex-col mt-4'>
                                    <p className='font-raleway font-bold text-[#000]  text-lg'>Kitchen Requirements</p>
                                    <div className="grid grid-cols-2 justify-items-start font-normal w-1/2">
                                    {
                                      (credentialCategories && credentialCategories.length > 0) ? (
                                            credentialCategories.map((cat, i) => <div className="font-raleway text-md mr-auto" key={i}>{cat.toUpperCase()}</div>)
                                      ) : (<></>)
                                    }
                                    </div>
                                </div> */}
                            </div>

                            {/* <div className='w-full col-span-1 my-auto'>
                                <button className='rounded-md text-sm border-0 bg-[#53a3b9] hover:bg-[#013847]/80 text-gray-100 hover:text-[#D9D9D9]' onClick={() => toggleNewMember(true)}>Request Membership</button>
                            </div> */}
            
                            {/* <div className='col-span-1 w-full'></div> */}
                            {/* isRequestedMember */}
                            {(currentMember && currentMember?.username) ? (<hr className='my-4 bg-primary-200/70 sm:invisible' />) : (<></>)}
                            <div className='sm:w-2/5 w-full mx-auto'>
                                <p className='text-center font-bold text-primary-200  text-md'>{(currentCommunity?.spaces && currentCommunity.spaces.length === 1) ? 'Kitchen (1)' : ((currentCommunity?.spaces && currentCommunity.spaces.length > 0) ? `Kitchens (${currentCommunity.spaces.length})` : 'No Kitchens Yet')}</p>
                                {
                                  (currentCommunity?.spaces) ? ( currentCommunity.spaces.map((space, i) => (<KitchenRecord key={i} kitchenKey={i} hasHomeQuery={homeQuery ? true : false} communityId={currentCommunity?.id ? currentCommunity.id : ""} kitchen={space} isMember={isMember} isRequestedMember={isRequestedMember} toggleNewMember={toggleNewMember} toggleNewReservation={toggleNewReservation} />)) ) : (<></>)
                                }
                            </div>

                            
                            {/* <div className='flex flex-col w-full'> */}
                            {/* {(currentCommunity && currentCommunity.membership?.plan_id) ? (<div className={`bg-[#0B9821] text-[#D9D9D9] rounded-md p-2 text-[11px] w-auto m-auto ml-[80%]`}>VERIFIED</div>) : null} */}
                              {/* UPDATE MEMBERSHIP TOGGLE */}
                              {/* <div className='flex flex-col items-center font-open-sans text-gray-500 p-4 col-span-1 bg-[#013847] dark:bg-white/80 rounded-md justify-evenly'> 
                                {
                                  (!isMember) ? (
                                    <div className='flex flex-col font-open-sans p-2'>
                                        <p className='text-[#D9D9D9]/80 m-2 text-[14px]'>Not a member of this community yet</p>
                                        <button className='border-0 bg-[#D9D9D9]/80 hover:bg-[#53a3b9] dark:bg-[#013847]/80 dark:text-[#D9D9D9] text-[#013847]' onClick={() => null}>Request Membership</button> 
                                    </div>
                                      ) : (
                                    <>
                                    </>
                                    )
                                }
                                </div> */}
                              {/* </div> */}
                            {/* <AddPlanModal isOpen={isOpen} closeAppModal={() => setIsOpen(false)}></AddPlanModal>
                            <AddRequirementModal isOpen={isOpenReq} closeAppModal={() => setIsOpenReq(false)}></AddRequirementModal> */}
                        </div>
                    </>
                
                </div>
            </div>
            <AddMemberFormModal communityId={currentCommunity?.id ? currentCommunity.id : ""}  closeAppModal={closeAppModal} isOpen={addMember} />
            <AddReservationModal membership={isMember} closeAppModal={closeAppModal} isOpen={addReservation} />
            </>
        )
  }
}


export default CommunityDetails