// import React from 'react'
import { Route, Routes } from 'react-router-dom'


import CommunityDetails from '../pages/shared/CommunityDetails'
import KitchenDetails from '../pages/shared/KitchenDetails'
import Communities from '../pages/shared/Communities'
import Login from "../pages/UnAuth/Login"
// import LandingHero from '../pages/UnAuth/LandingHero'
import OTPForm from '../pages/UnAuth/Login/otpform'
import Privacy from '../pages/shared/Privacy'
import Terms from '../pages/shared/Terms'
import Disclaimer from '../pages/shared/Disclaimer'
import Eula from '../pages/shared/Eula'

import { useAuthState } from '../contexts/authcontext'




const UnAuthView = () => {
  let { isloggedin, isverified } = useAuthState()
  var view_otp_form = isloggedin && !isverified

  if (view_otp_form) {
    return <OTPAuthView />
  } else {
    return <LoginView />
  }
}


// const OTPAuthView = () => {
//   return (
//       <div className='w-10/12 ml-auto sm:w-full'>
//           <Routes>
//               <Route element={<LandingHero />} path="/" />
//               <Route path="/community/schools/:schoolId" element={<CommunityDetail />} />
//               <Route path="/community/schools" element={<Schools />} />
//               <Route path="/login" element={<OTPForm />}  />
//               <Route path="*" element={<OTPForm  />}  />
//           </Routes>
//       </div>
//   )
// }

const OTPAuthView = () => {
  return (
    <div className='w-full mt-12 sm:w-full '>
        <Routes>
            <Route path="*" element={<OTPForm  />}  />
        </Routes>
    </div>
  )
}



const LoginView = () => {
  
  return (
    // <div className='w-10/12 ml-auto sm:w-full'>
    <div className='w-full mt-12 sm:w-full '>
          <Routes>
            {/* <Route path="/signup" element={<SignUp isverified={isverified} registerUser={registerUser} />} /> */}
            <Route path="/login" element={<Login />} />
            {/* <Route path="/home" element={<LandingHero />}  /> */}
            {/* <Route path="/community/schools" element={<Schools />} /> */}
            {/* <Route path="/community/schools/:schoolId" element={<CommunityDetail />} /> */}
            <Route path="/community/:communityId" element={<CommunityDetails />} />
            <Route path="/community/:communityId/:kitchenId" element={<KitchenDetails />} />
            {/* <Route path="/" element={<LandingHero />}  /> */}
            <Route path="/privacy" element={<Privacy />}  />
            <Route path="/terms" element={<Terms />}  />
            <Route path="/disclaimer" element={<Disclaimer />}  />
            <Route path="/eula" element={<Eula />}  />
            <Route path="*" element={<Communities />}  />
          </Routes>
    </div>
  )
}

export default UnAuthView;