import { useState, useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {AiOutlineLink} from 'react-icons/ai'
// import { FiEdit } from "react-icons/fi";
import { AddMemberFormModal, AddReservationModal } from '../../components/AppModal'
import RequirementsChecklist from '../../molecules/views/RequirementsChecklist' 

// import moment from 'moment'

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
// import { ReactComponent as ScrollUpIcon } from '../../icons/ScrollUpIcon.svg'
// import { ReactComponent as ScrollDownIcon } from '../../icons/ScrollDownIcon.svg'

import { ReactComponent as LocationIcon }  from '../../icons/locationIcon.svg'
// import { ReactComponent as StarIcon } from "../../icons/star.svg";
// import KitchenRecord from '../../components/KitchenRecord'

// import { useAuthState } from '../../contexts/authcontext'
// import { useMemberState } from '../../contexts/membercontext'
// import { useCommunity } from "../../contexts/communitycontext"
import { useLoadCurrentCommunity } from "../../hooks/communityhooks"

import { BookingSchema, CommunitySchema, KitchenSpaceSchema, MembershipSchema } from "../../data/types"
import { useAuthState } from '../../contexts/authcontext';
import { useMemberState } from '../../contexts/membercontext'

import { getMembershipStatusColor } from "../../utils/helpers";

import MemberPlans from '../../molecules/views/MemberPlans'
import MemberPlansForm from '../../molecules/views/MemberPlansForm'

import StartBooking from "../../components/StartBooking"
import StarRating from "../../components/StarRating"
import BookingConfirmation from "../../molecules/views/BookingConfirmation";


// if(addBooking){
//   return(
//     <>
//     <StartBooking handleClose={closeAppModal} />
//     </>
//   )
// }

// import EditKitchenInfo from "./EditKitchenInfo"
// import EditKitchenDetails from './EditKitchenDetails'
// import { AddPlanModal, AddRequirementModal } from "../../components/AppModal";

// import {CreateBooking } from '../../molecules/views/CreateBooking'
// import  EquipmentsWidget from '../../molecules/views/EquipmentsWidget'

// TO BE LOADED FROM COMMUNITIES / REQUIRED DOCS

// const credentialCategories = ["ID", "license", "certificate", "insurance", "other"]

// const account_avatar: string = `https://upload.wikimedia.org/wikipedia/commons/1/1e/Default-avatar.jpg`



const openInNewTab = (url: string) => {
  window.open(url, '_blank');
};

type RouteParams = { 
  communityId: string;
  kitchenId: string
};


const KitchenDetails = () => {
  var navigate = useNavigate();
  
  const { communityId, kitchenId } = useParams<RouteParams>()
  const [searchParams] = useSearchParams();
  const homeQuery = searchParams.get('home');
  const kitchenQuery = searchParams.get('kitchen');
  const addBookingQuery = searchParams.get('addBooking');
  
  const [addBooking, toggleAddBooking] = useState((addBookingQuery && addBookingQuery === "true") ? true : false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [updatePlan, toggleUpdatePlan] = useState(false)

  // var { currentCommunity } = useCommunity()
  var { currentCommunity } = useLoadCurrentCommunity(communityId ? communityId : "")
  var { authUser } = useAuthState()
  var { currentBooking, updateCurrentBooking } = useMemberState()

  // console.log("kitchenId", kitchenId)
  // console.log("communityId", communityId)

  // var review_rating = 4

  // var { 
  //   // memberships, 
  //   loadData: loadAllMembershipData 
  // } = useMemberState()

  // const loadCurrentKitchen = async () => {
  //     if (getCommunity !== undefined && communityId) {
  //       await getCommunity(communityId);
  //     }
  
  //     // if (loadAllMembershipData !== undefined ){
  //     //     await loadAllMembershipData()
  //     // }
  // }



  // const [isEditing, setIsEditing] = useState(false);
  // const [isOpen,setIsOpen] = useState(false);
  // const [isOpenReq,setIsOpenReq] = useState(false);
  const [add_member, toggleNewMember] = useState(false)
  const [add_reservation, toggleNewReservation] = useState(false)


  // const kitchenName = currentCommunity?.name ? currentCommunity.name : ''
  const kitchenSpaces = currentCommunity?.spaces ? currentCommunity.spaces : []
  // console.log("currentCommunity 1", currentCommunity)
  // console.log(kitchenId && Number.isInteger(kitchenId) && kitchenSpaces.length > parseInt(kitchenId))
  var kitchenSpace = currentCommunity?.spaces ? currentCommunity.spaces[0] : {} as KitchenSpaceSchema
  if (kitchenId && kitchenSpaces.length > parseInt(kitchenId)){
    kitchenSpace = kitchenSpaces[parseInt(kitchenId)]
  }

  // Define a function to find an object by attribute value
  const findObjectByAttribute = (objects: MembershipSchema[], attribute: keyof MembershipSchema, value: any) => {
    for (const obj of objects) {
        if (obj[attribute] === value) {
            return obj;
        }
    }
    return {};
  }
  let allMemberships = currentCommunity?.memberships
  let membershipRequests = currentCommunity?.membership_requests
  let username = authUser?.username
  var isMember = allMemberships ? findObjectByAttribute(allMemberships, "username", username) : {}
  var isRequestedMember = membershipRequests ? findObjectByAttribute(membershipRequests, "username", username) : {}

  const currentMember = (isMember && isMember?.username) ? isMember : ((isRequestedMember && isRequestedMember?.username) ? isRequestedMember : {})

  // var isMember: boolean = false;



  useEffect(() => {
    document.title = `Kitchen | ${kitchenSpace?.name ? kitchenSpace.name : 'HomeCook'}`
    // loadCurrentKitchen()
    // if (!currentCommunity || !currentCommunity?.name){loadCurrentKitchen()}
    // eslint-disable-next-line 
  }, []);

  const closeAppModal = () => {
    toggleNewMember(false)
    toggleNewReservation(false)
    toggleAddBooking(false)
    setIsSubmitting(false)
    updateCurrentBooking({} as BookingSchema)
  }


//   console.log("currentCommunity", currentCommunity)
if (!kitchenSpace || (kitchenSpace && !kitchenSpace?.name)){
    return (
        <div
          role="status"
          className="grid items-center justify-center h-screen md:p-[1%] w-full bg-back-400 pt-16 md:pt-16"
        >
          <svg
            aria-hidden="true"
            className="w-28 h-28 mr-2 text-gray-200 animate-spin  fill-primary-400"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span className="sr-only">Loading...</span>
        </div>
      )
  } 
  else if (isSubmitting && currentBooking) {
    return (
      <BookingConfirmation
        currentKitchen={kitchenSpace}
        currentBooking={currentBooking}
        member={isMember}
        // beforeBuffer={beforeBuffer}
        // afterBuffer={afterBuffer}
        // setStep={setStep}
        equipment={[]}
        isOpen={isSubmitting}
        handleEdit={() => setIsSubmitting(false)}
        handleClose={closeAppModal}
        toggleUpdatePlan={toggleUpdatePlan}
      />
    )
  } 
  else {
        return (
          <>
            <div className='flex mx-auto w-full p-4 h-screen bg-[#D9D9D9] dark:bg-[#08090a] font-work-sans'>
                <div className='font-raleway flex flex-col w-full relative items-start p-4'>
            
                    <>
                        {
                          (kitchenQuery) ? (
                            <></>
                          ) : (
                              <span className="text-[#5DB6CE] hover:text-gray-500 mb-2 cursor-pointer" onClick={() => navigate(`/community/${kitchenSpace.community_id}${homeQuery ? '?home=true':''}`)}><ArrowBackIosIcon /> {(currentCommunity && currentCommunity?.name) ? `${currentCommunity.name} Community` : 'Community'}</span>
                          )
                        }

                        <div className='flex flex-col sm:flex-row w-full sm:w-auto text-xl text-[#000]'>
                          <img className='h-20 sm:h-16 aspect-auto relative my-auto mx-auto rounded-lg' alt={kitchenSpace.logo} src={kitchenSpace.logo} /> 
                          <p className='mx-2 sm:text-left text-center mt-2 sm:mt-auto my-auto'>{kitchenSpace ? `${kitchenSpace.name}` : null}</p>
                        </div>
                        
                        <div className='flex flex-col my-1 sm:mx-4 mx-auto items-center w-full sm:w-auto'>
                          <li
                            className="grid grid-flow-col justify-start text-primary-200 text-sm font-open-sans"
                          >
                            <StarRating review_rating={4} /> &nbsp; (1)
                          </li>
                        </div>
                        
                        <span className='font-raleway w-full sm:w-auto text-md flex flex-col sm:flex-row sm:mx-1 mx-auto p-1 items-center text-primary-200'>
                          <div className='flex flex-row text-center text-gray-500 text-sm'>
                            <LocationIcon className='w-4 h-4 m-1 text-primary-200' /> &nbsp;
                            {kitchenSpace && kitchenSpace.address ? `${kitchenSpace.address}, `: ``} 
                            {kitchenSpace && kitchenSpace.city ? `${kitchenSpace.city}, `: ``} 
                            {/* {kitchenSpace && kitchenSpace.county ? `${kitchenSpace.county}, `: ``}   */}
                            {kitchenSpace && kitchenSpace.state ? `${kitchenSpace.state} `: ``}  
                            {kitchenSpace && kitchenSpace.zipcode ? `${kitchenSpace.zipcode} `: ``}  
                            {/* {kitchenSpace && kitchenSpace.zipcode} {kitchenSpace && kitchenSpace.country}  */}
                          </div>
                          <span className='flex flex-row text-xs text-[#013847] hover:text-gray-400 cursor-pointer mx-auto sm:ml-4' onClick={() => (currentCommunity && kitchenSpace.website) ? openInNewTab(kitchenSpace.website) : null}><AiOutlineLink className='mx-1' />Visit Website</span>
                        </span>
                        

                        {
                          (isMember && isMember.username && isMember?.status) ? (
                          <div 
                            className={`flex flex-row text-[#fff]/80 rounded-sm p-2 text-sm sm:text-md w-max col-span-2 mx-auto sm:mx-1  sm:ml-auto justify-evenly`}
                            style={{backgroundColor: getMembershipStatusColor(isMember?.status).bgColor, opacity: 0.7}}
                            >
                            {`MEMBERSHIP ${isMember?.status?.toUpperCase()}`}
                          </div>
                          ) : ((isRequestedMember && isRequestedMember.username && isRequestedMember?.status) ? (
                            <div 
                              className={`flex flex-row text-[#fff]/80 rounded-sm p-2 text-sm sm:text-md w-max col-span-2 mx-auto sm:mx-1  sm:ml-auto justify-evenly`}
                              style={{backgroundColor: getMembershipStatusColor(isRequestedMember.status).bgColor, opacity: 0.7}}
                              >
                              {`MEMBERSHIP ${isRequestedMember.status?.toUpperCase()}`}
                            </div>
                          ) : (<></>))
                        }

                        <div className='flex flex-row justify-evenly w-full h-[300px] my-2'>
                            {
                                        kitchenSpace?.bgPhoto ? (
                                        <img className='object-cover w-full' src={kitchenSpace?.bgPhoto} alt={kitchenSpace?.name} /> 
                                        ) : (
                                            <>
                                            <div className='h-[280px] w-9/12 rounded-md m-1  bg-slate-400' ></div>
                                            <div className='flex flex-col h-[280px] w-3/12 rounded-md m-1' >
                                                <div className='h-1/2 w-auto rounded-md m-1  bg-slate-400' ></div>
                                                <div className='h-1/2 w-auto  rounded-md m-1 bg-slate-400' ></div>
                                            </div>
                                            </>
                                            )
                                }
                        </div>
        
                        <div className='flex flex-col sm:flex-row w-full'>
                            <div className='col-span-2 w-full'>
                                <div className='flex flex-col mt-4 font-raleway w-full'>
                                    <p className=' font-bold text-[#000000]  text-md'>Kitchen Details</p>
                                    {/* {
                                            currentCommunity?.logo ? (
                                            <img className='h-[100px] w-[100px] object-contain my-4' src={currentCommunity.logo} alt={currentCommunity?.id} onError={e => { e.currentTarget.src = Campus }} /> 
                                            ) : (
                                                <img className='h-[70px] w-[70px] rounded-md m-2 relative bg-slate-600' alt={currentCommunity?.id} src={Campus} />
                                                )
                                    } */}
                                    <p className='text-[#555] text-sm'>{kitchenSpace ? kitchenSpace.description : null}</p>
                                </div>

                                
            
                                <div className='flex flex-col mt-4 w-full'>
                                    <p className='font-raleway font-bold text-[#000] text-md'>Plans</p>
                                    {
                                      (updatePlan) ? (
                                        <MemberPlansForm 
                                          currentCommunity={currentCommunity ? currentCommunity : {} as CommunitySchema} 
                                          isMember={isMember} 
                                          isRequestedMember={isRequestedMember}
                                          toggleUpdatePlan={toggleUpdatePlan} 
                                        />
                                      ) : (
                                        <MemberPlans 
                                          currentCommunity={currentCommunity ? currentCommunity : {} as CommunitySchema} 
                                          isMember={isMember} 
                                          isRequestedMember={isRequestedMember} 
                                          toggleUpdatePlan={toggleUpdatePlan} 
                                        />
                                      )
                                    }
                                </div>
                                <div className='flex flex-col mt-4'>
                                    {
                                        (currentMember && currentMember?.username) ? (<>
                                        <RequirementsChecklist currentMember={currentMember} currentCommunity={currentCommunity ? currentCommunity : {} as CommunitySchema} />
                                        </>) : (
                                        (currentCommunity?.requirements && currentCommunity?.requirements?.length > 0) ? (
                                          <>
                                            <p className='font-raleway font-bold text-[#000] text-md'>Membership Requirements</p>
                                            <ul className="font-normal w-full list-disc ml-4">
                                            {
                                                currentCommunity?.requirements.map((requirement, i) => (
                                                        <li key={i} className="text-sm">
                                                            <span className='font-open-sans text-[#555] font-medium'>{requirement.name && requirement.name.toUpperCase()}</span>
                                                        </li>
                                                    )
                                                )
                                            }
                                            </ul>
                                          </>
                                        ) : (<><p className='font-raleway font-bold text-[#000] text-md'>Membership Requirements</p><div className='font-open-sans text-black/50 text-sm font-thin'>Not Available</div></>))
                                     }
                                </div>

                                {(currentMember && currentMember?.username) ? (<hr className='my-4 bg-primary-200/70 sm:invisible' />) : (<></>)}
                            </div>
            
                            {/* <div className='col-span-1 w-full'></div> */}
                            {/* isRequestedMember */}
                            {/* <hr className='my-4 bg-primary-200/70 sm:invisible' /> */}
                            
                            {
                              (addBooking) ? (
                                <div className='flex flex-row justify-evenly w-full h-max bg-primary-200 col-span-2 p-1 rounded-md sm:w-[600px]'> 
                                    <StartBooking kitchenSpace={kitchenSpace} isMember={isMember} setIsSubmitting={setIsSubmitting} handleClose={closeAppModal} />
                                </div>
                              ) : (
                                  <div className="flex flex-col w-full h-max rounded-md p-1 bg-primary-200 col-span-2 sm:w-[600px]" >
                                    <KitchenDetailCard 
                                      kitchenSpace={kitchenSpace} 
                                      isMember={isMember} 
                                      isRequestedMember={isRequestedMember} 
                                      toggleNewMember={toggleNewMember}
                                      toggleAddBooking={toggleAddBooking}
                                    />
                                  </div>
                              )
                            }
                        </div>
                    </>
                
                </div>
            </div>
            <AddMemberFormModal communityId={currentCommunity?.id ? currentCommunity.id : ""} closeAppModal={closeAppModal} isOpen={add_member} />
            <AddReservationModal membership={isMember} closeAppModal={closeAppModal} isOpen={add_reservation} />
            </>
        )
  }


}


type KitchenDetailCardProps = {
  kitchenSpace: KitchenSpaceSchema
  isMember: MembershipSchema
  isRequestedMember: MembershipSchema
  toggleAddBooking: React.Dispatch<React.SetStateAction<boolean>>
  toggleNewMember: React.Dispatch<React.SetStateAction<boolean>>
}

const KitchenDetailCard: React.FC<KitchenDetailCardProps> = ({kitchenSpace, isMember, isRequestedMember, toggleNewMember, toggleAddBooking}) => {
  return (
  
      <div className="contain text-center">
        <div className='flex flex-col mt-2 text-white'>
            <p className='font-raleway font-bold text-sm'>Kitchen Size</p>
            <div className="text-gray-400">
              {
                  kitchenSpace.size
              } sqft
            </div>
        </div>

        <div className='flex flex-col text-white'>
            <p className='font-raleway font-bold text-sm'>Kitchen Capacity</p>
            <div className="text-gray-400">
              {
                  kitchenSpace?.capacity ? kitchenSpace.capacity : 0
              } 
              {
                  kitchenSpace.capacity === '1' ? ' Person' : ' People'
              }
            </div>
        </div>

      
        {
          (isMember && isMember?.username && isMember?.status) ? (
            <button className='rounded-md text-sm border-0 bg-[#53a3b9] hover:bg-[#53a3b9]/70 text-gray-200 hover:text-[#013847e6] m-2' onClick={() => toggleAddBooking(true)}>Request Booking</button>
          ) : (
            (isRequestedMember && isRequestedMember?.username && isRequestedMember?.status) ? (
              <button disabled className='mx-auto rounded-md text-sm border-0 bg-[#aedce9] text-[#53a3b9] m-2'>Request Booking</button>
            ) : (<><button className='rounded-md text-sm border-0 bg-[#53a3b9] hover:bg-[#53a3b9]/70 text-gray-200 hover:text-[#013847e6] m-2' onClick={() => toggleNewMember(true)}>Request Membership</button></>)
          )
        }
      </div>
)
}


export default KitchenDetails