import axios from 'axios'

import { CommunitySchema, KitchenSpaceSchema, MembershipSchema, AccountSchema, MessageSchema, BookingSchema, AuthSchema, CredentialSchema } from "../data/types";
import { objectToFormData } from '../utils/helpers'

// var old_api_host = `https://${new_api_id}.execute-api.us-east-1.amazonaws.com/${stage}`
// var new_api_host = "http://127.0.0.1:5000"


import config from '../config';

const new_api_host = config.apiUrl
const secretKey = process.env.REACT_APP_SECRET_KEY ? process.env.REACT_APP_SECRET_KEY : ""
const apiKey = process.env.REACT_APP_API_KEY ? process.env.REACT_APP_API_KEY : ""


type KitchenIdParameter = {
    communityId: string
    id: string;
  }
  
// type UpdateKitchenParameter = {
// communityId: string;
// spaceId: string;
// kitchenUpdates: KitchenSpaceSchema | {};
// }

// type PreferenceParameter = {
// communityId: string;
// data?: PreferenceSchema;
// }

  

const axiosInstance = axios.create({
    baseURL: new_api_host,
    timeout: 50000,
    headers: {
        "Content-Type": "application/json",
        accept: "application/json",
    },
    // headers: {
    //     'Authorization': localStorage.getItem('access_token'),
    //     'Content-Type': 'application/json',
    //     'accept': 'application/json',
    //     'x-api-key': 'Nbaa4zX9Uv4tQEhqZxvhY9lgTk00E3mMdc2SpxBi'
    // }
})

// Adding API access keys to headers
axiosInstance.defaults.headers.common['HOMECOOK-SECRET-KEY'] = secretKey
axiosInstance.defaults.headers.common['HOMECOOK-API-KEY'] = apiKey



axiosInstance.interceptors.response.use(
    response => response,
    async error => {
      const originalRequest = error.config;

      if (error.response && error.response.status === 401 && error.response.statusText === "Unauthorized") {
          const refresh_token = localStorage.getItem('refresh_token');

          return axiosInstance
              .post('/kitchens/user/auth', {refresh: refresh_token})
              .then((response) => {

                  let data = (response.data && response.data.body) ? response.data.body : response.data

                  localStorage.setItem('access_token', data.login_token);
                  localStorage.setItem('refresh_token', data.refresh_token);

                  axiosInstance.defaults.headers['Authorization'] = data.login_token
                  originalRequest.headers['Authorization'] = data.login_token

                  return axiosInstance(originalRequest);
              })
              .catch(err => {
                  console.log(err)
              });
      }
      return Promise.reject(error);
  }
)


// import schools_list from '../data/culinary_schools'
const getAllCommunities = async () => {
    let data: CommunitySchema[] | null
    let new_user_api_url = `/community`

    // let response = await axiosInstance.post(new_user_api_url, {phone, email, token_username})
    let response = await axiosInstance.get(new_user_api_url)
    // let response = require('../data/culinary_schools');
    // console.log('response', response)
    if (response){
        data = (response.data && response.data.body) ? response.data.body : response.data
        return data
    }
    
    return null
}

const getCommunityByID = async (id: string) => {
    let data: CommunitySchema | null
    let new_user_api_url = `/community/${id}`

    let response = await axiosInstance.get(new_user_api_url)
    if (response){
        data = (response.data && response.data.body) ? response.data.body : response.data
        // console.log("getCommunityByID data", data)
        return data
    }
    return null
}


const searchCommunities = async (q: string) => {
    let data: CommunitySchema[] | null
    let searchOption = q ? `?q=${q}` : ""
    let new_user_api_url = `/kitchens/community${searchOption}`

    let response = await axiosInstance.get(new_user_api_url)
    if (response){
        data = (response.data && response.data.body) ? response.data.body : response.data
        return data
    }
    return null
}


const filterCommunitiesByPreferences = async () => {
    let data: CommunitySchema | null
    let new_user_api_url = `/kitchens/community`

    // let response = await axiosInstance.post(new_user_api_url, {phone, email, token_username})
    let response = await axiosInstance.get(new_user_api_url)
    // let response = require('../data/culinary_schools');
    // console.log('response', response)
    if (response){
        data = (response.data && response.data.body) ? response.data.body : response.data
        return data
    }
    return null
}


// const createNewUser = async (user: AccountSchema) => {
//     let data: AccountSchema | null
//     let new_user_api_url = `kitchens/user`
//     if (user?.email || (user?.phone && user.phone.length === 12)){
//         let response = await axiosInstance.post(new_user_api_url, user)
//         if (response){
//             data = (response.data && response.data.body) ? response.data.body : response.data
//             if (data && data?.login_token && data.refresh_token){
//                 axiosInstance.defaults.headers['Authorization'] = data.login_token
//                 localStorage.setItem('access_token', data.login_token)
//                 localStorage.setItem('refresh_token', data.refresh_token)
//             }
//             return data
//         }
//     }
//     return null
// }


const createNewUser = async (user: AccountSchema) => {
    let data: AccountSchema | null
    let new_user_api_url = `/community/auth`
    if (user?.email || (user?.phone && user.phone.length === 12)){
        var response = await axiosInstance.post(new_user_api_url, user)
  
        if (response){
            data = (response.data && response.data.body) ? response.data.body : response.data
            if (data && data?.login_token && data.refresh_token){
                console.log("data", data)
                // axiosInstance.defaults.headers['Authorization'] = data.login_token
                localStorage.setItem('access_token', data.login_token)
                localStorage.setItem('refresh_token', data.refresh_token)
            }
            return data
        }
    }
    return null
}


const getAuthUser = async ({phone, email, token_username}: AuthSchema) => {
    let data: AccountSchema | null
    let new_user_api_url = `/community/auth`

    if (phone || email || token_username){
        let response = await axiosInstance.post(new_user_api_url, {phone, email, token_username})
        // console.log('response token_username', response)
        if (response){
            data = (response.data && response.data.body) ? response.data.body : response.data
            return data
        }
    }
    return null
}


// const verifyUserOTP = async (otp_code: string, username: string) => {
//     let data: AccountSchema | null
//     let new_user_api_url = `/community/auth/verify`
//     console.log("otp_code, phone", otp_code, username)

//     if (otp_code){
//         let response = await axiosInstance.post(new_user_api_url, {otp_code, username})
//         // console.log('response token_username', response)
//         if (response){
//             data = (response.data && response.data.body) ? response.data.body : response.data
//             return data
//         }
//     }
//     return null
// }

const verifyUserOTP = async (otp_code: string, username: string) => {
    let data: AccountSchema | null
    let new_user_api_url = `/community/auth/verify`
    // console.log("otp_code, phone", otp_code, username)
  
    if (otp_code){
        let response = await axiosInstance.post(new_user_api_url, {otp_code, username})
        // console.log('response token_username', response)
        if (response){
            data = (response.data && response.data.body) ? response.data.body : response.data
            return data
        }
    }
    return null
}


// const updateUserData = async (authUser: AccountSchema, user_data: AccountSchema) => {
//     let data: AccountSchema | null
//     // phone = (phone.length === 10) ? phone :
//     let update_user_api_url = `/kitchens/user/1`
//     let phone = authUser.phone

//     let first_name = authUser.first_name
//     if (user_data.hasOwnProperty('first_name') && user_data.first_name){
//         first_name = user_data.first_name
//     }

//     let last_name = authUser.last_name
//     if (user_data.hasOwnProperty('last_name') && user_data.last_name){
//         last_name = user_data.last_name
//     }

//     let email = authUser.email
//     if (user_data.hasOwnProperty('email') && user_data.email){
//         email = user_data.email
//     }

//     if (phone && phone.length === 12 && user_data){
//         // user_data = {...user_data, phone}
//         user_data = {...user_data, first_name, last_name, email, phone}
//         // console.log("update user_data 2", user_data)
//         let response = await axiosInstance.put(update_user_api_url, user_data)
//         if (response){
//             data = (response.data && response.data.body) ? response.data.body : response.data
//             return data
//         }
//     }
//     return null
// }
const updateUserData = async (authUser: AccountSchema, user_data: AccountSchema) => {
    let data: AccountSchema | null
    // phone = (phone.length === 10) ? phone :
    let update_user_api_url = `/community/account/${authUser.username}`
    let phone = authUser.phone
  
    let first_name = authUser.first_name
    if (user_data.hasOwnProperty('first_name') && user_data.first_name){
        first_name = user_data.first_name
    }
  
    let last_name = authUser.last_name
    if (user_data.hasOwnProperty('last_name') && user_data.last_name){
        last_name = user_data.last_name
    }
  
    let email = authUser.email
    if (user_data.hasOwnProperty('email') && user_data.email){
        email = user_data.email
    }
  
    if (phone && phone.length === 12 && user_data){
        // user_data = {...user_data, phone}
        user_data = {...user_data, first_name, last_name, email, phone}
        // console.log("update user_data 2", user_data)
        let response = await axiosInstance.put(update_user_api_url, user_data)
        if (response){
            data = (response.data && response.data.body) ? response.data.body : response.data
            return data
        }
    }
    return null
}



  // GET ALL KITCHENS
const getAllKitchens = async (communityId: string) => {
    if (communityId){
      let fetch_api_url = `/community/${communityId}/kitchens`;
  
      try{
        let response = await axiosInstance.get(fetch_api_url);
        if (response) {
          let data: any = response.data;
          return data;
        }
        return []
      }catch(err){
        console.log(err);
      }
    }
}

const getKitchenID = async ({communityId, id }: KitchenIdParameter) => {
var data: KitchenSpaceSchema
if (communityId && id){
    // console.log("communityId", communityId)
    let fetch_api_url = `/community/${communityId}/kitchens/${id}`;

    let response = await axiosInstance.get(fetch_api_url);
    if (response) {
    data = response.data;
    return data;
    }
}
return {} as KitchenSpaceSchema;
}

// const AIAxiosInstance = axios.create({
//     baseURL: "http://127.0.0.1:8000",
//     timeout: 10000,
//     headers: {
//         'Authorization': localStorage.getItem('access_token'),
//         'Content-Type': 'application/json',
//         'accept': 'application/json',
//         'x-api-key': 'Nbaa4zX9Uv4tQEhqZxvhY9lgTk00E3mMdc2SpxBi'
//     }
// })

const generateAIResponse = async (prompt: string) => {
    let data: MessageSchema | null
    let ai_api_url = `/kitchens/ai/text?prompt=${prompt}`

    if (prompt){
        let response = await axiosInstance.get(ai_api_url)
        // console.log('response token_username', response)
        if (response){
            console.log("generateAIResponse response", response)
            data = (response.data && response.data.body) ? response.data.body : response.data
            console.log("generateAIResponse data", data)
            return data
        }
    }
    return null
}


const signOut = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
}


// FETCH USER MEMBERSHIPS
const fetchMemberships = async (username: string) => {
    // let data: MembershipSchema[] | null
    // let membership_api_url = `/kitchens/user/${username}/memberships/`
    let membership_api_url = `/community/account/${username}/memberships`

    if (username){
        let response = await axiosInstance.get(membership_api_url)
        console.log('fetchMemberships response', response)
        if (response){
            return response.data
        }
    }
    return null
}


const addCommunityMember = async (communityId: string, membership: MembershipSchema | {}) => {
    if (communityId){
      let fetch_api_url = `/community/${communityId}/members`;
      try {
        let response = await axiosInstance.post(fetch_api_url, membership);
        if (response && response.status === 201) {
          console.log("create success");
          return response.data
        }
      } catch (err) {
        console.log(err);
        return null
      }
    }
  }


// CREATE NEW MEMBERSHIP
const createMembership = async (new_membership: MembershipSchema) => {
    // let data: MembershipSchema | null

    let { username, community_id, plan_id, payment_method } = new_membership

    let membership_api_url = `/kitchens/community/${community_id}/memberships/${username}`

    if (community_id && username){
        let response = await axiosInstance.post(membership_api_url, {plan_id, payment_method})
        console.log('createMembership response', response)
        if (response){
            return response.data
        }
    }
    return null
}



// FETCH MEMBER BOOKINGS
const fetchMemberBookings = async (username: string) => {
    let data: BookingSchema[] | null
    // let booking_api_url = `/kitchens/user/${username}/bookings/`
    let booking_api_url = `/community/account/${username}/bookings`
    if (username){
        let response = await axiosInstance.get(booking_api_url)
        // console.log('fetchMemberBookings response', response)
        if (response){
            data = (response.data && response.data.body) ? response.data.body : response.data
            return data
        }
    }
    return null
}

// FETCH MEMBERSHIP
const getMembership = async (communityId: string, username: string) => {
    let data: MembershipSchema | null
    let membership_api_url = `/community/${communityId}/members/${username}`
    if (communityId && username){
        let response = await axiosInstance.get(membership_api_url)
        // console.log('response', response)
        if (response){
            data = (response.data && response.data.body) ? response.data.body : response.data
            return data
        }
    }
    return null
}


// UPDATE MEMBER PROFILE
const updateCommunityMembership = async (communityId: string, membershipUpdate: MembershipSchema) => {
    let data: MembershipSchema | null
    let username = membershipUpdate?.username
    if (communityId && username){
        let booking_api_url = `/community/${communityId}/members/${username}`
        delete membershipUpdate.username; 
        let response = await axiosInstance.patch(booking_api_url, membershipUpdate)
        console.log('updateCommunityMembership response', response)
        if (response){
            data = (response.data && response.data.body) ? response.data.body : response.data
            return data
        }
    }
    return null
}


// Send Message to New or Existing User
const sendMessageToUser = async (communityId: string, username: string, content: string) => {
    let data: MessageSchema | null
    let api_url = `/community/${communityId}/messages/${username}`
  
    if (content){
        let response = await axiosInstance.post(api_url, {content})
        if (response){
            data = (response.data && response.data.body) ? response.data.body : response.data
            return data
        }
    }
    return null
}

// GET BOOKINGS FOR COMMUNITY ID
const getCommunityBookings = async (communityId: string) => {
    if (communityId){
      let fetch_api_url = `/community/${communityId}/bookings`;
  
      try{
        let response = await axiosInstance.get(fetch_api_url);
        if (response) {
          let data = response.data;
          return data;
        }
        
      }catch(err){
        console.log(err);
      }
    }
    return []
}


// GET ALL MEMBERSHIP PLANS
const getMembershipPlans = async (communityId: string) => {
    if (communityId){
      let fetch_api_url = `/community/${communityId}/plans`;
  
      try{
        let response = await axiosInstance.get(fetch_api_url);
        if (response) {
          let data = response.data;
          return data;
        }
        return []
      }catch(err){
        console.log(err);
      }
    }
}
  


// Fetch All Message Threads
const getAllMessages = async (communityId: string) => {
    let data: MessageSchema[]
    
    if (communityId){
        let api_url = `/community/${communityId}/messages`
        let response = await axiosInstance.get(api_url)
        if (response){
            data = (response.data && response.data.body) ? response.data.body : response.data
            return data
        }
    }
    return []
}

// FETCH MEMBER BOOKINGS
const fetchBookingByID = async (communityId: string, bookingId: string) => {
    let data: BookingSchema | null
    // let booking_api_url = `/kitchens/community/${communityId}/bookings/${bookingId}`
    let booking_api_url = `/community/${communityId}/bookings/${bookingId}`
    if (bookingId){
        let response = await axiosInstance.get(booking_api_url)
        // console.log('response', response)
        if (response){
            data = (response.data && response.data.body) ? response.data.body : response.data
            return data
        }
    }
    return null
}

// CREATE NEW BOOKING
const createBooking = async (new_booking: BookingSchema) => {
    let { community_id } = new_booking
    let booking_api_url = `/community/${community_id}/bookings`
    // new_booking.booking_recurrence = 'weekly'
    // new_booking.end_recurrence = "2024-06-02"
    // new_booking.recurrence_count = 2
    if (community_id){
        let response = await axiosInstance.post(booking_api_url, new_booking)
        // console.log('createBooking response', response)
        if (response){
            return response.data
        }
    }
    return null
}


// UPDATE MEMBER BOOKING
const updateBookingData = async (communityId: string, bookingId: string, bookingUpdate: BookingSchema) => {
    let data: BookingSchema | null
    if (communityId && bookingId){
        let booking_api_url = `/community/${communityId}/bookings/${bookingId}`
        let response = await axiosInstance.patch(booking_api_url, bookingUpdate)
        console.log('updateBooking response', response)
        if (response){
            data = (response.data && response.data.body) ? response.data.body : response.data
            return data
        }
    }
    return null
}


// GET MEMBER REVIEWS
const getMemberReviews = async (username: string) => {
    let fetch_api_url = `/community/all/review/${username}`
  
    try {
      let response = await axiosInstance.get(fetch_api_url);
      if (response && response.data) {
        return response.data;
      }
    }catch(err){
      console.log(err);
    }
    return []
}


const fetchMemberCredentials = async (communityId: string, username: string) => {
    let data: CredentialSchema[] = []
    try {
      if (communityId){
      let fetch_api_url = `/community/${communityId}/documents/${username}`
      let response = await axiosInstance.get(fetch_api_url)
        if (response){
            data = (response.data && response.data.body) ? response.data.body : response.data
            return data
        }
      }
    }
    catch (err) {
      console.log(err)
    }
    return data
}


// const samplePaymentMethods = [
//     {
//         "id": "pm_1NVChw2eZvKYlo2CHxiM5E2E",
//         "object": "payment_method",
//         "billing_details": {
//         "address": {
//             "city": null,
//             "country": null,
//             "line1": null,
//             "line2": null,
//             "postal_code": null,
//             "state": null
//         },
//         "email": null,
//         "name": null,
//         "phone": null
//         },
//         "card": {
//         "brand": "visa",
//         "checks": {
//             "address_line1_check": null,
//             "address_postal_code_check": null,
//             "cvc_check": "pass"
//         },
//         "country": "US",
//         "exp_month": 12,
//         "exp_year": 2034,
//         "fingerprint": "Xt5EWLLDS7FJjR1c",
//         "funding": "credit",
//         "generated_from": null,
//         "last4": "4242",
//         "networks": {
//             "available": [
//             "visa"
//             ],
//             "preferred": null
//         },
//         "three_d_secure_usage": {
//             "supported": true
//         },
//         "wallet": null
//         },
//         "created": 1689682128,
//         "customer": "cus_9s6XKzkNRiz8i3",
//         "livemode": false,
//         "metadata": {},
//         "redaction": null,
//         "type": "card"
//     }
// ]

const fetchPaymentMethods = async (username: string) => {
    try {
      if (username){
      let fetch_api_url = `/community/account/${username}/paymethods`
      let response = await axiosInstance.get(fetch_api_url)
        if (response && response.data){
            let data = (response.data && response.data.body) ? response.data.body : response.data
            return data
        }
      }
    }
    catch (err) {
      console.log(err)
    }
    return []
    // return samplePaymentMethods
}


const addPayIntent = async (username: string, amount: number) => {
    if (username && amount){
        let fetch_api_url = `/community/payintent/${username}`
        try {
            let response = await axiosInstance.post(fetch_api_url, {amount})
            if (response && response.status === 201) {
                console.log("create success");
                return response.data
            }
        } catch (err) {
            console.log(err);
            return null
        }
    }
}


const addPaymentSetupIntent = async (username: string) => {
    if (username){
        let fetch_api_url = `/community/setupintent/${username}`
        try {
            let response = await axiosInstance.post(fetch_api_url)
            if (response && response.status === 201) {
                console.log("create success");
                return response.data
            }
        } catch (err) {
            console.log(err);
            return null
        }
    }
}

  
  
const addMembershipCredentials = async (communityId: string, username: string, cred: CredentialSchema) => {
    if (communityId && username && cred){
        let fetch_api_url = `/community/${communityId}/documents/${username}/verify/new`
        // let fetch_api_url = `/community/${communityId}/documents/${username}/upload`
        var credForm  = objectToFormData(cred)
        try {
            let response = await axiosInstance.post(fetch_api_url, credForm)
            if (response && response.status === 201) {
                console.log("create success");
                return response.data
            }
        } catch (err) {
        console.log(err);
            return null
        }
    }
}

export { 
    createNewUser, getAuthUser, verifyUserOTP, signOut, updateUserData, 
    getAllCommunities, getCommunityByID, getKitchenID, getAllKitchens, searchCommunities, generateAIResponse,
    fetchPaymentMethods, addPaymentSetupIntent, addPayIntent,
    filterCommunitiesByPreferences, getAllMessages, sendMessageToUser,
    addMembershipCredentials, fetchMemberCredentials, getMemberReviews,
    createMembership, fetchMemberships, getMembership, updateCommunityMembership, addCommunityMember,
    getMembershipPlans, createBooking, updateBookingData, fetchMemberBookings, fetchBookingByID, getCommunityBookings
}
