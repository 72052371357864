import React, {useEffect} from 'react'
import DropdownGrid from './DropdownGrid'
import { useCommunity } from '../../contexts/communitycontext'
import { useSearchCommunities } from '../../hooks/communityhooks'
import { CommunitySchema, CommunityGridProps } from "../../data/types";
import CommunityCard from "../../components/CommunityCard";
import LoadingScreen from "../../pages/shared/LoadingScreen"

import { useLoadMemberships} from "../../hooks/memberhooks"

const CommunitiesGrid: React.FC<CommunityGridProps>  = ({sliced, searchTerm, changeSearchTerm}) => {

    var { allCommunities } = useCommunity()
    // var { allCommunities, isLoadingCommunities, handleSearch } = useSearchCommunities(searchTerm ? searchTerm : "")
    
    // allCommunities = useSearchCommunities(searchQuery ? searchQuery : "").allCommunities
    // const { handleSearch } = useCommunity()
    var { isLoadingCommunities, handleSearch } = useSearchCommunities()
    // const [allCommunities, sd] = useState((searchTerm && handleSearch) ? handleSearch(searchTerm) : comms)
    
  
    // console.log("allCommunities", allCommunities)

    var { memberships, isLoadingMemberships } = useLoadMemberships()

    useEffect(() => {
      // if (loadAllCommunities !== undefined) {
      //   loadAllCommunities()
      // }
      if (searchTerm) {handleSearch(searchTerm)}
    // eslint-disable-next-line 
    }, [searchTerm?.search, searchTerm?.filter])

    var nSlicedCols = '4'
    // var sch_list =  allCommunities ? (sliced ? allCommunities.slice(0,parseInt(nSlicedCols)) : allCommunities) : null
    var nSchs = allCommunities ? allCommunities.length : 0

    if (isLoadingMemberships && isLoadingCommunities){
      return (<LoadingScreen height='h-auto' bgColor='inherit' /> )
    } else{
    return (
      <div className='flex flex-col font-open-sans w-full'>
          {
            (sliced) ? null :(
            <div className='px-4 flex-row justify-between w-full hidden sm:flex'>
              <div className='text-[#696969] text-[16px] dark:text-[#FFF]/40'>Showing <span className='font-bold text-[#202124] dark:text-[#FFF]/60'>{(nSchs > 0) ? '1' : '0'}</span> - <span className='font-bold text-[#202124] dark:text-[#FFF]/60'>{nSchs ? nSchs.toString() : '0'}</span> of <span className='font-bold text-[#202124] dark:text-[#FFF]/60'>{nSchs ? nSchs.toString() : '0'}</span> communities</div>
              <div><DropdownGrid searchTerm={searchTerm} changeSearchTerm={changeSearchTerm} /></div>
            </div>)
          }
          <div className={`p-4 grid md:grid-cols-${sliced ? nSlicedCols : '3'} gap-3 w-full`}> 
              {/* Card */}
              {
                  allCommunities?.map((community: CommunitySchema, i: number) => {
                      return <CommunityCard key={i} community={community} allMemberships={memberships} />
                  } )
              }
          </div>
      </div>
    )}
}

export default CommunitiesGrid