import {useState} from "react";
import { MdOutlineExpandMore} from 'react-icons/md'
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import us50States from "../../data/usStates";


// type FilterStateType = {[key: string]: boolean}

type DropdownGridProps = {
    searchTerm?: {search: string, filter: string}
    changeSearchTerm: React.Dispatch<React.SetStateAction<{search: string, filter: string}>>
}
export default function DropdownGrid({searchTerm, changeSearchTerm}: DropdownGridProps) {

    // const [filterStates, updateFilterState] = useState<FilterStateType>({program: false, location: false, degree: false})

    const [filterState, updateFilterState] = useState<boolean>(false)
    const [filterMembership, updateFilterMembership] = useState<boolean>(false)

    var searchFilter = searchTerm?.filter ? searchTerm.filter : ""



    var dropDownOptions = [
        {
            name: 'Location',
            options: us50States,
            state: filterState
        },
        {
            name: 'Member Status',
            options: [{name: 'none'}, {name: 'pending'}, {name: 'active'}],
            state: filterMembership
        },
    ]

    
    
    return (
        <div className="flex flex-row relative justify-start items-start p-4">
            <DropDownOption filterTerm={searchFilter} dropDownOption={dropDownOptions[0]} 
                            updateFilter={() => {
                                updateFilterState(prev => !prev)
                                updateFilterMembership(false)
                            }} 
                            changeSearchTerm={changeSearchTerm} />
            <DropDownOption filterTerm={searchFilter} 
                            dropDownOption={dropDownOptions[1]}
                            updateFilter={() => {
                                updateFilterMembership(prev => !prev)
                                updateFilterState(false)
                            }}  
                            changeSearchTerm={changeSearchTerm}/>
        </div>
    );
}


type DropDownOptionProps = {
    dropDownOption: {
        name: string
        state: boolean
        options: {[key: string]: string}[]
    },
    filterTerm: string
    updateFilter: React.Dispatch<React.SetStateAction<boolean>>
    changeSearchTerm: React.Dispatch<React.SetStateAction<{search: string, filter: string}>>
}


const DropDownOption: React.FC<DropDownOptionProps> = ({dropDownOption, filterTerm, updateFilter, changeSearchTerm}) => {

    const handleFilterChange = (value: string) => {
        changeSearchTerm(prev => ({...prev, filter: value}))
        // console.log("filterTerm value: ", filterTerm)
        // console.log("changeSearchTerm value: ", value)
    }
    return (
    <>
    <div className="w-max mx-3">
        <div className="flex flex-row justify-start items-start">
            <button className='flex flex-row border-[#eef7fa] bg-[#eef7fa] text-[#4a91a4] cursor-pointer relative font-open-sans text-[16px] items-center justify-between' 
                    onClick={() => {
                        updateFilter(prev => !prev)
                    }}
            > 
                {dropDownOption.name} 
                <MdOutlineExpandMore className="mx-1" /> 
            </button>
        </div>
        
        {
            (dropDownOption.state) ? (
            <div className="absolute bg-white text-base z-50 list-none divide-y divide-gray-100 rounded-md shadow" id="dropdown">
                
                <div className="py-1  bg-[#fff]  max-h-[290px] overflow-y-scroll" aria-labelledby="dropdown">
                {/* <select> */}
                    {
                        dropDownOption?.options?.map((option, i) => {
                            return (
                                // <option  >
                                <div key={i} className="flex cursor-pointer w-full"  onClick={() => (filterTerm !== option?.name) ? handleFilterChange(option.name) : null}>
                                        <div className="flex items-center w-full mt-2 font-open-sans text-sm hover:bg-gray-100 text-gray-700 px-4 py-2">    
                                            {
                                                (filterTerm === option?.name) ? (<>
                                                <CheckBoxIcon className={`mx-2 text-primary-400`} />
                                                </>) : (<>
                                                <CheckBoxOutlineBlankIcon className={`mx-2 text-primary-400`} />
                                                </>)
                                            }
                                            {option?.name}
                                        </div>
                                </div>
                                
                            )
                        })
                    }
                {/* </select> */}
                </div>
                
            </div>
            ) : null
        }
    </div>
    </>
    )
}