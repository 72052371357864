import React, { ReactNode } from 'react';
import { AuthProvider, useAuthState } from './authcontext'
import { MemberStateProvider } from './membercontext'
import { CommunityProvider } from './communitycontext'

type PropsChildren = {children?: ReactNode}
const ApplicationProvider: React.FC<PropsChildren> = props => {
    return (
        <AuthProvider>
            <MemberProvider>
                {props.children}
            </MemberProvider>
        </AuthProvider>
    )
}



const MemberProvider = (props: PropsChildren) => {
    var { authUser } = useAuthState()
    // var communityId = (primaryCommunity && primaryCommunity.id) ? primaryCommunity.id : ""
    return (
            
            <CommunityProvider authUser={authUser ? authUser : {}}>
                <MemberStateProvider authUser={authUser ? authUser : {}} > 
                    {props.children}
                </MemberStateProvider>
            </CommunityProvider>
            
    )
}


// const SelectionProvider = props => {

//     let { userneed } = useAuthState()
//     let is_kitchen_owner = userneed === "chef"
//     console.log("SelectionProvider is_kitchen_owner", is_kitchen_owner)
//     if (is_kitchen_owner){
//         return ( <KitchenProvider> {props.children} </KitchenProvider>)
//     } else {
//         return (<MemberProvider> {props.children} </MemberProvider>)
//     }
// }

export default ApplicationProvider
