import { useEffect } from "react";

import { useMemberState } from '../../contexts/membercontext'

import ChatWindow from '../../molecules/views/chatWindow'
import UserListWindow from '../../molecules/views/chatUsers'

const Messages = () => {
//   const [currentUser, changeUser] = useState<string | null>('Little Chef')

  useEffect(()=> {
      document.title = 'HomeCook | Messages'
   }, [])

   var { currentChatCommunity } = useMemberState()

//    let messages_by_recipient = {
//        "Little Chef": [{read: false, content: "", type: 'sent'}, {read: true, content: "", type: 'received'}],
//        "Yinka": [{read: false, message_text: "hey yinka", type: 'sent'}, {read: true, message_text: "hey hassan", type: 'received'}]
//    }

      return (
         <div className="mx-auto w-full flex font-open-sans h-[900px] sm:h-screen overflow-y-scroll bg-[#D9D9D9] dark:bg-[#013847]">
            <div className="flex flex-col sm:flex-row w-10/12 justify-center m-auto ">
                {/* <div className="w-auto sm:w-[400px]  bg-[#fff] rounded-md"> */}
                <div className={`${currentChatCommunity ? 'hidden sm:block': 'block'} w-auto sm:w-[400px] bg-[#fff] rounded-md`}>
                    <UserListWindow />
                </div>

                {/* <div className="hidden sm:flex m-2 w-auto  bg-[#fff] rounded-md"> */}
                <div className={`${currentChatCommunity ? 'flex flex-col': 'hidden sm:block'} m-2 w-auto  bg-[#fff] rounded-md`}>
                    <ChatWindow  />
                </div>
            </div>
             

        </div>
      )
}







export default Messages
