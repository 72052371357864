import React from 'react'
import Policy from '../../components/Policy'

const Terms = () => {
  return (
    <div className='flex flex-col h-[900px] sm:h-screen justify-evenly bg-[#D9D9D9] dark:bg-[#013847]'>
      <p className='text-md mx-auto mt-4 text-primary-200 font-raleway'>Terms of Service</p>
      <Policy policyKey={process.env.REACT_APP_TERMS_OF_SERVICE_KEY} />
    </div>
  )
}

export default Terms