// import { QuestionaireType } from "../molecules/Questionaire";

const kitchenStatuses = () => {
  return {
    0: { value: "created", label: "Created" },
    10: { value: "pending", label: "Pending" },
    20: { value: "confirmed", label: "Confirmed" },
    30: { value: "canceled", label: "Canceled" },
    40: { value: "completed", label: "Completed" },
  };
};

const membershipStatuses = () => {
  return {
    0: { value: "created", label: "Created" },
    10: { value: "pending", label: "Pending" },
    20: { value: "confirmed", label: "Confirmed" },
    30: { value: "canceled", label: "Canceled" },
    40: { value: "completed", label: "Completed" },
  };
};

const statusColors = () => {
  return {
    Pending: "bg-gray-500",
    Created: "bg-green-500",
    Confirmed: "bg-yellow-400",
    Canceled: "bg-red-600",
    Completed: "bg-primary-400",
  };
};

type PreferenceSettingsType = {
  [key: string]: {
    category: 'Experience' | 'Kitchen' | 'Location' | 'Booking' | 'Miscellaneous'
    questions: {
      [key: number]: {
        title: string
      }
    }
  }
}

const preferenceSettings: PreferenceSettingsType = {
    step1: {
      category: "Experience",
      questions: {
        1: {
          title: "Ownership of Kitchen",
        },
        2: {
          title: "How long have you owned you kitchen?",
        },
      },
    },
    step2: {
      category: "Kitchen",
      questions: {
        1: {
          title:
            "Primary equipment you need to cook for you business.",
        },
        2: {
          title:
            "Community Type",
        },
      },
    },
    step3: {
      category: "Location",
      questions: {
        1: {
          title:
            "Address / City / State / Zip Code of your kitchen",
        },
        2: {
          title:
            "Size of Kitchen Space",
        },
        
      },
    },
    step4: {
      category: "Booking",
      questions: {
        1: {
          title:
            "Urgency for Booking",
        },
        2: {
          title:
            "Kitchen Availability",
        },
      },
    }
  };

type QuestionaireType = {
  [key: string]: {
    question: string;
    preferenceCategory: 'Experience' | 'Kitchen' | 'Location' | 'Booking' | 'Miscellaneous'
    options: {
      [key: number]: string;
    };
  };
}

const questionaire : QuestionaireType = {
  step1 : {
    question: "Are you the sole owner of the kitchen?",
    preferenceCategory: "Experience",
    options: {
      1: "Yes, I'm a sole owner",
      2: "No, I have partners",
      3: "other",
    }
  },
  step2 : {
    question: "How long have you owned your kitchen?",
    preferenceCategory: "Experience",
    options: {
      1: "Less than 1 year",
      2: "1-3 years",
      3: "5+ years",
      4: "I do not own the kitchen, I lease it",
      5: "Prospective owner"
    }
  },
  step3 : {
    question: "Address / City / State / Zip Code of your kitchen",
    preferenceCategory: "Location",
    options:{
      
    }
  },
  step4 : {
    question: "How many square feet is your kitchen space?",
    preferenceCategory: "Location",
    options: {
      1: "200 sq. ft - 400 sq. ft",
      2: "500 sq. ft - 700 sq. ft",
      3: "800 sq. ft - 1000 sq. ft",
      4: "1001 sq. ft +"
    }
  },
  step5 : {
    question: "What type of kitchen do you have?",
    preferenceCategory: "Kitchen",
    options: {
      1: "Restaurant",
      2: "Commerical Kitchen",
      3: "Venue Kitchen",
      4: "Ghost Kitchen",
      5: "Cloud Kitchen",
      6: "Other"
    }
  },
  step6: {
    question: "Please select the equipment you have in your kitchen for chefs to use.",
    preferenceCategory: "Kitchen",
    options: {
      1: "Range",
      2: "Commercial Oven",
      3: "Fryer",
      4: "Griddle",
      5: "Food Slicer",
      6: "Prep Tables",
      7: "Convection Oven",
      8: "Deck Oven",
      9: "Mixer",
      10: "Food Dehydrator",
      11: "Rack Oven",
      12: "Commercial Grinder",
      13: "Industrial Coffee Maker",
      14: "Induction Cooktop",
      15: "Members Must Provide Own Equipment",
      16: "Steam Kettle",
      17: "Tilt Skillet",
      18: "Vertical Cutter/Mix",
      19: "Food Processor",
      20: "Food Mixer",
      21: "Charbroiler",
      22: "Ice cream maker",
      23: "Bottling Line",
      24: "Blast Freezer",
      25: "Canning line",
      26: "Water Chiller",
      27: "Proofer",
      28: "Vacuum Sealer",
      29: "Dough Sheeter",
      30: "Packaging Heat Seal",
      31: "Other",
    },
  },
  step7 : {
    question: "What hours can a chef book a kitchen?",
    preferenceCategory: "Booking",
    options: {
      1: "text",
    }
  },
  step8 : {
    question: "How soon do you want to start booking you kitchen space?",
    preferenceCategory: "Booking",
    options: {
      1: "ASAP",
      2: "In the next 2-3 weeks",
      3: "In the next month",
      4: "Looking to book my space in the future (6+ months)"
    }
  },
  step9 : {
    question: "How many chefs can you have cooking at once in your kitchen?",
    preferenceCategory: "Miscellaneous",
    options: {
      1: "Only one at a time",
      2: "2-3 chefs",
      3: "5-10 chefs",
      4: "10+ chefs"
    }
  },
  step10 : {
    question: "How frequently would you like to receive payments?",
    preferenceCategory: "Miscellaneous",
    options: {
      1: "After every booking",
      2: "At the end of each week",
      3: "Bi-Weekly",
      4: "Monthly",
      5: "Quarterly",
      6: "Other"
    }
  },
  step11 : {
    question: "How much do you minimally have to charge chefs to cook in your kitchen?",
    preferenceCategory: "Miscellaneous",
    options: {
      1: "$50-$100 per hour",
      2: "$101-$200 per hour",
      3: "$201-$500 per hour",
      4: "Other"
    }
  },
}
const states = [
  "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DC", "DE", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA",
  "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR",
  "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"
];

export {
  kitchenStatuses,
  membershipStatuses,
  statusColors,
  preferenceSettings,
  questionaire,
  states
};
