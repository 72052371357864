import React from 'react'
import { AiOutlineSearch } from 'react-icons/ai'


type NewSearchProps = {
  searchType?: string
  searchTerm: {search:string, filter: string}
  changeSearchTerm: React.Dispatch<React.SetStateAction<{search: string, filter: string}>>
}

const NewSearch: React.FC<NewSearchProps> = ({searchType, searchTerm, changeSearchTerm}) => {

  // var { allCommunities, isLoadingCommunities } = useSearchCommunities(searchTerm)
  // console.log("allCommunities", allCommunities)

  

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    var { value } = e.target
    if ((value === "" || value) && searchFor === "communities"){
      changeSearchTerm(prevVal => ({...prevVal, search: value}))
      // console.log("search value", value)
    }
    
  }

  let searchFor = "communities"
  if (searchType === "kitchens"){
    searchFor = "kitchens"
  } else if (searchType === "reservations"){
    searchFor = "reservations"
  }

  return (
    <div className='flex flex-col w-full p-4 items-center h-[200px] justify-evenly'>
      <div className='bg-white rounded-full flex items-center px-2 w-[300px] sm:w-[500px]'>
          <AiOutlineSearch size={20} />
          <input className='bg-transparent p-2 focus: outline-none w-full border-0' type='text' placeholder={`Search ${searchFor}`} value={searchTerm.search} onChange={e => handleOnChange(e)} />
          {/* {isLoadingCommunities ? "...searching" : ""} */}
      </div>
    </div>
  )
}

export default NewSearch